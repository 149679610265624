import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(private http:HttpClient) { }

  uploadBannerImage(image){
    return this.http.post(environment.apiURL+'file/upload',image)
  }

  addBanner(bannerData){
    return this.http.post(environment.apiURL+'banner',bannerData)
  }

  getBanner(){
    return this.http.get(environment.apiURL+'banner/list')
  }

  getBannerDetail(id){
    return this.http.get(environment.apiURL+`banner/${id}`)
  }

  deleteBanner(id){
    return this.http.delete(environment.apiURL+`banner/${id}`)
  }

  updateBanner(bannerData){
    return this.http.put(environment.apiURL+`banner`,bannerData)
  }

  inActivateBanner(bannerStatus){
    return this.http.put(environment.apiURL+'banner/action',bannerStatus)
  }

  addOfferUspBanner(data){
    return this.http.post(environment.apiURL+`offerBanner`,data)
  }

  deleteOfferUspBanner(id){
    return this.http.delete(environment.apiURL+`offerBanner/${id}`)
  }

  getOfferUspBanner(){
    return this.http.get(environment.apiURL+`offerBanner`)
  }

  updateOfferUspBanner(id,data){
    return this.http.put(environment.apiURL+`offerBanner/${id}`,data)
  }

  activateOfferBanner(data){
    return this.http.put(environment.apiURL+'offerBanner',data)
  }

  deleteOfferBanner(id){
    return this.http.delete(environment.apiURL+`offerBanner/${id}`)
  }
  
  getUspBanner(){
    return this.http.get(environment.apiURL+'uspBanner')
  }
}
