import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  constructor(private http:HttpClient) { }

  addDeliveryCharge(chargeData){
    return this.http.post(environment.apiURL+'setting',chargeData)
  }

  getDeliverycharge(){
    return this.http.get(environment.apiURL+'setting')
  }

  updateDeliveryCharge(chargeData){
    return this.http.put(environment.apiURL+'setting',chargeData)
  }

  updateCod(data){
    return this.http.put(environment.apiURL+`update/cod`,data)
  }

  getChargeDetail(areaId){
    return this.http.get(environment.apiURL+`setting/${areaId}`)
  }

  getCityDeliveryCharge(){
    return this.http.get(environment.apiURL+`city/setting`)
  }

  updateCityDeliveryCharge(data){
    return this.http.put(environment.apiURL+`city/setting`,data)
  }
}
