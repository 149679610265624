import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendor-agreement',
  templateUrl: './vendor-agreement.component.html',
  styleUrls: ['./vendor-agreement.component.less']
})
export class VendorAgreementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
