import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  getOrders(orderData, start, end) {
    return this.http.post(environment.apiURL + `orders?start=${start}&end=${end}`, orderData);
  }
  getBulkOrders() {
    return this.http.get(environment.apiURL + `get-bulk-order-list`);
  }
  getOrdersWithoutLimit(orderData) {
    return this.http.post(environment.apiURL + `orders`, orderData);
  }
  getallOrders(orderData, start, end) {
    return this.http.post(environment.apiURL + `order/allorderseller?start=${start}&end=${end}`, orderData);
  }

  getallOrdersWithoutLimit(orderData) {
    return this.http.post(environment.apiURL + `order/allorderseller`, orderData);
  }

  getCancelledOrders(start, end) {
    return this.http.get(environment.apiURL + `cancel/orders?start=${start}&end=${end}`);
  }

  confirmPendingOrder(pendingOrderDetail) {
    return this.http.post(environment.apiURL + 'order/confirmed', pendingOrderDetail);
  }
  canceledOrder(pendingOrderDetail) {
    return this.http.post(environment.apiURL + 'order/canceledorderbyid', pendingOrderDetail);
  }

  restoreOrder(restoreOrderDetail) {
    return this.http.post(environment.apiURL + 'order/restoreOrder', restoreOrderDetail);
  }

  confirmDispatchedOrder(dispatchDetail) {
    return this.http.post(environment.apiURL + 'order/dispatched', dispatchDetail);
  }
  assignDeliveryBoyOrder(dispatchDetail) {
    return this.http.post(environment.apiURL + 'order/assigndeliveryboy', dispatchDetail);
  }

  confirmProcessedOrder(processDetail) {
    return this.http.post(environment.apiURL + 'order/processed', processDetail);
  }

  confirmDeliveredOrder(deliverDetail) {
    return this.http.post(environment.apiURL + 'order/dilevered', deliverDetail);
  }

  orderDetail(id) {
    return this.http.get(environment.apiURL + `order/details?id=${id}`);
  }

  cancelOrderDetail(id) {
    return this.http.get(environment.apiURL + `cancel/order?id=${id}`);
  }

  reSendOtp(orderId: any) {
    return this.http.get(environment.apiURL + `resend/otp?orderId=${orderId}`);
  }

  accepCancelOrderd(orderId: any) {
    return this.http.post(environment.apiURL + `order/cancel/verify`, { orderId: orderId });
  }

  outOfStocks(model: any) {
    return this.http.post(environment.apiURL + `product/outofstock`, model)
  }

  getReturnOrders(start, end) {
    return this.http.get(environment.apiURL + `order/product/outofstock?start=${start}&end=${end}`);
  }

  getOrderStatus(params) {
    return this.http.get(environment.apiURL + "dashboard/graph", { params: params });
  }

  getInTransitOrders(page,limit,filter){
    return this.http.post(environment.apiURL+`intransit?start=${page}&end=${limit}`,filter)
  }

  returnIntransitProduct(productData){
    return this.http.post(environment.apiURL+'order/product/return',productData)
  }

  getReturnInTransitOrders(start,end){
    return this.http.get(environment.apiURL+`order/product/return?start=${start}&end=${end}`)
  }

  calculatePrice(data){
    return this.http.post(environment.apiURL+'order/calculateprice',data)
  }

  createPosOrder(data){
    return this.http.post(environment.apiURL+'order/create',data)
  }

  manageOrder(data){
    return this.http.post(environment.apiURL+'manageOrder',data)
  }
}
