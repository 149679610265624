import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertService, ProductService } from 'src/app/_services';

@Component({
  selector: 'app-sub-brand',
  templateUrl: './sub-brand.component.html',
  styleUrls: ['./sub-brand.component.less']
})
export class SubBrandComponent implements OnInit {

  subBrands: any = []
  margin: FormGroup
  brandId:any;
  constructor(private route: ActivatedRoute,
    private _PS: ProductService,
    private _ALS: AlertService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.brandId = param.id
      this.getSubBrand()
    })
    this.margin = this.fb.group({
      sellPriceMargin: [''],
      purchaseMargin: [''],
    })
  }

  getSubBrand() {
    this._PS.getSubBrands(this.brandId).subscribe((res: any) => {
      this.subBrands = res.subbrand ? res.subbrand : []
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  updateProductMargin(subBrandId) {
    this._PS.updateProductMargin(subBrandId, this.margin.value).subscribe((res: any) => {
      if (res.success) {
        this._ALS.success(res.message)
        this.getSubBrand()
        this.margin.reset()
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }
}
