import { Component, Input} from '@angular/core';
import { OrderService } from 'src/app/_services/order.service';

@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.less']
})
export class GraphsComponent {
 @Input() multi: any = [];
  view: any[] = [900, 500];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Date';
  yAxisLabel: string = 'Orders';
  timeline: boolean = true;

  colorScheme = {
    domain: ['#0A2C47']
  };

 onSelect(event) {
    console.log(event);
  }

}
