import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient }    from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: HttpClient) { }

  getTransaction() {
    return this.http.get(environment.apiURL + 'seller/transaction');
  }

  getPayOuts(){
    return this.http.get(environment.apiURL+'payouts')
  }

  settlePayment(payOutId,payOutData){
    return this.http.put(environment.apiURL+`payouts/${payOutId}`,payOutData)
  }
}
