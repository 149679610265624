import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/_services';
import { CategoryService } from 'src/app/_services/category.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.less']
})
export class CategoryComponent implements OnInit {

  catTreeList: any = []
  subCats: any = []
  leafCats: any = [];
  isRoot: boolean = false
  rootNode: any = {}

  constructor(private _CS: CategoryService,
    private _ALS: AlertService) { }

  ngOnInit() {
    this.getCatTree()
  }

  getCatTree() {
    this._CS.getCategoryTree().subscribe((res: any) => {
      this.catTreeList = res.category ? res.category : []
    })
  }

  getSubCategory(node) {
    this.isRoot = node.isRoot
    if (node.isRoot) {
      this.rootNode = node
      this.catTreeList.filter(_ => {
        if (_._id == node.data._id) {
          this.subCats = _.children;
        }
      })
      this.leafCats = []
    }
    else {
      this.subCats.filter(_ => {
        if (_._id == node.data._id) {
          this.leafCats = _.children;
        }
      })
    }
  }

  inActivateCat(category) {
    if (category.isRoot) {
      category = category.data
      category.isRoot=true
    }
    if (category._id) {
      if (category.isActive) {
        this.deleteCat(category)
      }
      else {
        this._CS.inActivateCat(category._id).subscribe((res: any) => {
          if (res.success) {
            this._ALS.success(res.message)
            category.isActive = true
          }
          else {
            this._ALS.warn(res.message)
          }
        }, (error) => {
          this._ALS.error(error.message)
        })
      }
    }
  }

  deleteCat(category) {
    this._CS.deleteCat(category._id).subscribe((res: any) => {
      if (res.success) {
        this._ALS.success(res.message)
        if(category.isRoot){
          this.isRoot=false
        }
        this.getCatTree()
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

}
