import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RouteService } from 'src/app/_services/route.service';
import { AlertService } from 'src/app/_services';
import { Router, ActivatedRoute } from '@angular/router';


declare var google;
@Component({
  selector: 'app-add-new-route',
  templateUrl: './add-new-route.component.html',
  styleUrls: ['./add-new-route.component.less']
})
export class AddNewRouteComponent implements OnInit, AfterViewInit {

  @ViewChild('map') mapElement: ElementRef;
  map: any;
  polygonPath: any = [];
  routeForm: FormGroup
  routeId: any;
  polygon: any;
  isPolygonUpdated:boolean=false;

  constructor(private fb: FormBuilder,
    private _RS: RouteService,
    private _ALS: AlertService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.routeForm = this.fb.group({
      name: [],
      distance: [],
      priority: [],
      group: []
    })
  }

  ngAfterViewInit(): void {
    this.mapInit()
    this.route.queryParams.subscribe(_ => {
      this.routeId = _.routeId;
      if (this.routeId) {
        this.getRouteDetail(this.routeId)
      }
    })
  }

  mapInit() {
    let option = {
      center:{lng: 77.427175,lat: 28.604375},
      zoom: 15,
    }
    this.map = new google.maps.Map(this.mapElement.nativeElement, option);
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.MARKER,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON,
        ]
      },
      markerOptions: {
        editable: true,
        icon:"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
      },
    })

    let polygoncompleteFunc = (polygon: any) => {
      if(this.polygon!=null){
        this.polygon.setMap(null)
      }
      this.polygonPath = polygon.getPath().getArray().map((_, i) => {
        return [_.lng(), _.lat()]
      });
      this.polygonPath.push(this.polygonPath[0])
      this.isPolygonUpdated=true
    }
    google.maps.event.addListener(drawingManager, 'polygoncomplete', polygoncompleteFunc.bind(this));
    drawingManager.setMap(this.map);
  }

  createRoute() {
    let routeData :any = {
      name: this.routeForm.value["name"],
      priority: this.routeForm.value["priority"],
      group: this.routeForm.value["group"],
      distance: this.routeForm.value["distance"],
    }
    if(this.routeId && !this.isPolygonUpdated){
      routeData.coordinates = this.polygonPath
    }
    else{
      routeData.coordinates = [this.polygonPath]
    }
    if (this.routeId) {
      this._RS.updateRoute(this.routeId, routeData).subscribe((res: any) => {
        if (res.success) {
          this.router.navigate(['/route'])
          this._ALS.success(res.message)
        }
        else {
          this._ALS.warn(res.message)
        }
      }, (error) => {
        this._ALS.error(error.message)
      })
    }
    else {
      this._RS.addRoute(routeData).subscribe((res: any) => {
        if (res.success) {
          this.router.navigate(['/route'])
          this._ALS.success(res.message)
        }
        else {
          this._ALS.warn(res.message)
        }
      }, (error) => {
        this._ALS.error(error.message)
      })
    }
  }


  getRouteDetail(routeId: any) {
    this._RS.getRouteDetail(routeId).subscribe((res: any) => {
      if (res.success) {
        let polygonCords = []
        this.routeForm.patchValue(res.sellerRoutes)
        this.polygonPath = res.sellerRoutes.area.coordinates;
        let coordinates=res.sellerRoutes.area.coordinates[0]
        coordinates.forEach(coords=> {
          polygonCords.push({
            lng: coords[0],
            lat: coords[1]
          }) 
        });
        this.drawPolygon(polygonCords)
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  drawPolygon(polygonCords) {
    this.polygon = new google.maps.Polygon({
      paths: polygonCords,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 3,
      fillColor: "black",
      fillOpacity: 0.35
    })
    this.polygon.setMap(this.map)
  }

}
