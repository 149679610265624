import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deliveryBoyName'
})
export class DeliveryBoyNamePipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (!args) return '';
    var item = args.find(x => x.id === Number(value) );
    return item.name;
  }

}
