import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { OrderService, AlertService, DeliveryBoyService, Invoice2Service } from 'src/app/_services';
import { TabsetComponent } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import * as QRCode from 'qrcodejs2';
import * as JsBarcode from 'jsbarcode';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { saveAs } from 'file-saver';
import * as moment from 'moment';

@Component({
  selector: 'app-order-v2',
  templateUrl: './order-v2.component.html',
  styleUrls: ['./order-v2.component.less']
})
export class OrderV2Component implements OnInit {
  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  modalRef: BsModalRef;
  page: any = 0;
  currentTab: any = 0;
  limit: any = 10;
  orders: any = [];
  ordersAll: any = [];
  scrollDistance = 1;
  scrollUpDistance = 2;
  throttle = 300;
  otp: any;
  filter: any = {
    id: -1
  };
  orderType: any;
  orderIds: any = {};
  deliverBoyList: any = [];
  today: any = new Date();
  isGenerateRequest: boolean = false;
  filterNumber: any;
  beforeDate: FormControl;
  EndDate: FormControl;

  constructor(
    private _OS: OrderService,
    private _DS: DeliveryBoyService,
    private route: ActivatedRoute,
    private _ALS: AlertService,
    private _newIS2: Invoice2Service,
    private router: Router,
    private modalService: BsModalService,

  ) {
    this.otp = new FormControl('');
  }

  ngOnInit() {
    this.beforeDate = new FormControl();
    this.EndDate = new FormControl();
    this.getAllDeliveryBoys();

    this.route.queryParams.subscribe((data: any) => {
      this.currentTab = data.id ? data.id : 0;
      // if (this.currentTab == 0) {
      //   this.AllOrders(0, 0);
      // } else if (this.currentTab == 1) {
      //   this.pendingOrders(1, 0);
      // } else if (this.currentTab == 2) {
      //   this.confirmedOrders(2, 0);
      // } else if (this.currentTab == 3) {
      //   this.processedOrders(3, 0)
      // } else if (this.currentTab == 4) {
      //   this.dispatchedOrders(4, 0)
      // } else if (this.currentTab == 5) {
      //   this.deliveredOrders(5, 0)
      // } else if (this.currentTab == 6) {
      //   this.cancelledOrders(6, 0)
      // } else if (this.currentTab == 7) {
      //   this.pendingInitiateOrders(7, 0)
      // }
      this.selectTab(this.currentTab);
    })
  }

  deliveryBoys: any;
  getAllDeliveryBoys() {
    this._DS.getDeliveryBoys().subscribe((res: any) => {
      this.deliveryBoys = res.data ? res.data : []
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  selectTab(tabId: number) {
    this.staticTabs.tabs[tabId].active = true;
  }
  totalOrderCountAllOrders: any
  AllOrders(tabNo, page) {
    setTimeout(() => {
      this.currentTab = tabNo;
      this.page = page;
      this.orders = page == 0 ? [] : this.orders;
      let data = {
        status: 'all',
        state: 'new',
        sort: this.filter,
        number: this.filterNumber ? this.filterNumber : '',
        startDate: this.beforeDate.value ? moment(this.beforeDate.value).startOf('day').format("YYYY-MM-DD HH:mm:ss") : '',
        EndDate: this.EndDate.value ? moment(this.EndDate.value).startOf('day').format("YYYY-MM-DD HH:mm:ss") : ''
      };
      this.orderType = data;
      this._OS.getallOrders(data, this.page, this.limit).subscribe((orderResponse: any) => {
        if (orderResponse.success) {
          this.orders = [...this.orders, ...orderResponse.orders]
          this.totalOrderCountAllOrders = orderResponse.totalOrderCount;
        } else {
          this._ALS.warn(orderResponse.message);
        }
      })
    }, 500)
  }

  AllOrdersWithoutLimits() {
    if (this.orderType.status == 'all') {
      this._OS.getallOrdersWithoutLimit(this.orderType).subscribe((orderResponse: any) => {
        if (orderResponse.success) {
          this.ordersAll = orderResponse.orders
          this.ordersAll.forEach(element => {
              element.name = element.address.name,
              // element.email = element.address.email,
              element.line1 = element.address.line1,
              element.line2 = element.address.line2,
              element.fullAddress = element.address.fullAddress,
              element.mobileNo = element.address.mobileNo,
              element.slot = element.deliveryTime.slot,
              element.Total_Item = element.totalProduct,
              element.Total_Price = element.amount,
              element.status = element.status
          });
          let data = this.ordersAll.map((_) => {
            return {
              id: _.id,
              name: _.address.name,
              // email: _.address.email,
              line1: _.address.line1,
              line2: _.address.line2,
              fullAddress: _.address.fullAddress,
              mobileNo: _.address.mobileNo,
              date: _.date,
              deliveryDate: _.deliveryDate,
              slot: _.deliveryTime.slot,
              Total_Item: _.Total_Item,
              Total_Price: _.Total_Price,
              status: _.status,
            }
          })
          //console.log(Object.keys(data[0]));
          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(data[0]);
          let csv = this.ordersAll.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          let csvArray = csv.join('\r\n');

          var blob = new Blob([csvArray], { type: 'text/csv' })
          saveAs(blob, `SellerOrders.csv`);
        } else {
          this._ALS.warn(orderResponse.message);
        }
      })
    } else {
      this._OS.getOrdersWithoutLimit(this.orderType).subscribe((orderResponse: any) => {
        //console.log(orderResponse);
        if (orderResponse.success) {
          this.ordersAll = orderResponse.orders
          this.ordersAll.forEach(element => {
              element.name = element.address.name,
              // element.email = element.address.email,
              element.line1 = element.address.line1,
              element.line2 = element.address.line2,
              element.fullAddress = element.address.fullAddress,
              element.mobileNo = element.address.mobileNo,
              element.slot = element.deliveryTime.slot,
              element.Total_Item = element.totalProduct,
              element.Total_Price = element.amount
              element.status = element.status
          });
          let data = this.ordersAll.map((_) => {
            return {
              id: _.id,
              name: _.address.name,
              // email: _.address.email,
              line1: _.address.line1,
              line2: _.address.line2,
              fullAddress: _.address.fullAddress,
              mobileNo: _.address.mobileNo,
              date: _.date,
              deliveryDate: _.deliveryDate,
              slot: _.deliveryTime.slot,
              Total_Item: _.Total_Item,
              Total_Price: _.Total_Price,
              status: _.status,
            }
          })
          // console.log(Object.keys(data[0]));
          const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
          const header = Object.keys(data[0]);
          let csv = this.ordersAll.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
          csv.unshift(header.join(','));
          let csvArray = csv.join('\r\n');

          var blob = new Blob([csvArray], { type: 'text/csv' })
          saveAs(blob, `SellerOrders.csv`);
        } else {
          this._ALS.warn(orderResponse.message);
        }
      })
    }
  }
  totalOrderCountpendingOrders: any
  pendingOrders(tabNo, page) {
    setTimeout(() => {
      this.currentTab = tabNo;
      this.page = page;
      this.orders = page == 0 ? [] : this.orders;
      let data = {
        status: 'pending',
        state: 'new',
        sort: this.filter,
        number: this.filterNumber ? this.filterNumber : '',
        startDate: this.beforeDate.value ? moment(this.beforeDate.value).startOf('day').format("YYYY-MM-DD HH:mm:ss") : '',
        EndDate: this.EndDate.value ? moment(this.EndDate.value).startOf('day').format("YYYY-MM-DD HH:mm:ss") : ''
      };
      this.orderType = data;
      this._OS.getOrders(data, this.page, this.limit).subscribe((orderResponse: any) => {
        if (orderResponse.success) {
          this.orders = [...this.orders, ...orderResponse.orders]
          this.totalOrderCountpendingOrders = orderResponse.totalOrderCount;
        } else {
          this._ALS.warn(orderResponse.message);
        }
      })
    }, 500)
  }
  totalOrderCountconfirmedOrders: any
  confirmedOrders(tabNo, page = 0) {
    setTimeout(() => {
      this.currentTab = tabNo;
      this.page = page;
      this.orders = page == 0 ? [] : this.orders;
      let data = {
        status: 'confirmed',
        state: 'active',
        sort: this.filter,
        number: this.filterNumber ? this.filterNumber : ''
      };
      this.orderType = data;
      this._OS.getOrders(data, this.page, this.limit).subscribe((orderResponse: any) => {
        if (orderResponse.success) {
          this.orders = [...this.orders, ...orderResponse.orders]
          this.totalOrderCountconfirmedOrders = orderResponse.totalOrderCount;
        } else {
          console.log(orderResponse.message);
        }
      })
    }, 500)
  }
  totalOrderCountprocessedOrders: any
  processedOrders(tabNo, page = 0) {
    setTimeout(() => {
      this.currentTab = tabNo;
      this.page = page;
      this.orders = page == 0 ? [] : this.orders;
      let data = {
        status: 'processed',
        state: 'active',
        sort: this.filter,
        number: this.filterNumber ? this.filterNumber : ''
      };
      this.orderType = data;
      this._OS.getOrders(data, this.page, this.limit).subscribe((orderResponse: any) => {
        if (orderResponse.success) {
          this.orders = [...this.orders, ...orderResponse.orders]
          this.totalOrderCountprocessedOrders = orderResponse.totalOrderCount;
        } else {
          console.log(orderResponse.message);
        }
      })
    }, 500)
  }
  totalOrderCountdispatchedOrders: any
  dispatchedOrders(tabNo, page = 0) {
    setTimeout(() => {
      this.currentTab = tabNo;
      this.page = page;
      this.orders = page == 0 ? [] : this.orders;
      let data = {
        status: 'dispatched',
        state: 'active',
        sort: this.filter,
        number: this.filterNumber ? this.filterNumber : ''
      };
      this.orderType = data;
      this._OS.getOrders(data, this.page, this.limit).subscribe((orderResponse: any) => {
        if (orderResponse.success) {
          this.orders = [...this.orders, ...orderResponse.orders]
          this.totalOrderCountdispatchedOrders = orderResponse.totalOrderCount;
        } else {
          console.log(orderResponse.message);
        }
      })
    }, 500)
  }
  totalOrderCountdeliveredOrders: any
  deliveredOrders(tabNo, page = 0) {
    setTimeout(() => {
      this.currentTab = tabNo;
      this.page = page;
      this.orders = page == 0 ? [] : this.orders;
      let data = {
        status: 'delivered',
        state: 'active',
        sort: this.filter,
        number: this.filterNumber ? this.filterNumber : ''
      };
      this.orderType = data;
      this._OS.getOrders(data, this.page, this.limit).subscribe((orderResponse: any) => {
        if (orderResponse.success) {
          this.orders = [...this.orders, ...orderResponse.orders]
          this.totalOrderCountdeliveredOrders = orderResponse.totalOrderCount;
        } else {
          this._ALS.warn(orderResponse.message);
        }
      })
    }, 500)
  }
  totalOrderCountcancelledOrders: any
  cancelledOrders(tabNo, page = 0) {
    setTimeout(() => {
      this.currentTab = tabNo;
      this.page = page;
      this.orders = page == 0 ? [] : this.orders;
      let data = {
        status: 'cancelled',
        state: 'active',
        sort: this.filter,
        number: this.filterNumber ? this.filterNumber : ''
      };
      this.orderType = data;
      this._OS.getOrders(data, this.page, this.limit).subscribe((orderResponse: any) => {
        if (orderResponse.success) {
          this.orders = [...this.orders, ...orderResponse.orders]
          this.totalOrderCountcancelledOrders = orderResponse.totalOrderCount;
        } else {
          console.log(orderResponse.message);
        }
      })
    }, 500)
  }

  totalOrderCountPendingInitiateOrders: any
  pendingInitiateOrders(tabNo, page = 0) {
    setTimeout(() => {
      this.currentTab = tabNo;
      this.page = page;
      this.orders = page == 0 ? [] : this.orders;
      let data = {
        status: 'pendinginitiate',
        sort: this.filter,
        number: this.filterNumber ? this.filterNumber : ''
      };
      this.orderType = data;
      this._OS.getOrders(data, this.page, this.limit).subscribe((orderResponse: any) => {
        if (orderResponse.success) {
          this.orders = [...this.orders, ...orderResponse.orders]
          this.totalOrderCountPendingInitiateOrders = orderResponse.totalOrderCount;
        } else {
          console.log(orderResponse.message);
          this.totalOrderCountPendingInitiateOrders = 0;
        }
      })
    }, 500)
  }


  returnedOrders(tabNo, page = 0) {
    this.currentTab = tabNo;
    this.page = page;
    this.orders = page == 0 ? [] : this.orders;
    this._OS.getReturnOrders(this.page, this.limit).subscribe((orderResponse: any) => {
      if (orderResponse.success) {
        this.orders = [...this.orders, ...orderResponse.orders ? orderResponse.orders : []]
      } else {
        console.log(orderResponse.message);
      }
    })
  }

  inTransitOrders(tabNo, page = 0) {
    this.currentTab = tabNo;
    this.page = page;
    this.orders = page == 0 ? [] : this.orders;
    this._OS.getInTransitOrders(this.page, this.limit, { sort: this.filter }).subscribe((res: any) => {
      this.orders = [...this.orders, ...res.orders ? res.orders : []]
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  returnedInTransitOrders(tabNo, page = 0) {
    this.currentTab = tabNo;
    this.page = page;
    this.orders = page == 0 ? [] : this.orders;
    this._OS.getReturnInTransitOrders(this.page, this.limit).subscribe((orderResponse: any) => {
      this.orders = [...this.orders, ...orderResponse.orders ? orderResponse.orders : []]
    }, (err) => {
      console.log(err)
    })
  }

  sortByTime(value) {
    this.filter.id = Number(value);
    if (this.currentTab == 0) {
      this.AllOrders(0, 0);
    } else if (this.currentTab == 1) {
      this.pendingOrders(1, 0);
    } else if (this.currentTab == 2) {
      this.confirmedOrders(2, 0);
    } else if (this.currentTab == 3) {
      this.processedOrders(3, 0)
    } else if (this.currentTab == 4) {
      this.dispatchedOrders(4, 0)
    } else if (this.currentTab == 5) {
      this.deliveredOrders(5, 0)
    } else if (this.currentTab == 6) {
      this.cancelledOrders(6, 0)
    } else if (this.currentTab == 7) {
      this.pendingInitiateOrders(7, 0)
    }
  }

  onScrollDown() {
    this.page += 1;
    if (this.currentTab == 0) {
      this.AllOrders(0, this.page);
    } else if (this.currentTab == 1) {
      this.pendingOrders(1, this.page);
    } else if (this.currentTab == 2) {
      this.confirmedOrders(2, this.page);
    } else if (this.currentTab == 3) {
      this.processedOrders(3, this.page)
    } else if (this.currentTab == 4) {
      this.dispatchedOrders(4, this.page)
    } else if (this.currentTab == 5) {
      this.deliveredOrders(5, this.page)
    } else if (this.currentTab == 6) {
      this.cancelledOrders(6, this.page)
    } else if (this.currentTab == 7) {
      this.pendingInitiateOrders(7, this.page)
    }
  }

  confirmOrder(order) {
    if (confirm("Are you sure to Confirm Order # " + order.id)) {
      const pendingOrderDetail: any = {
        orderId: order._id,
        state: 'active',
        status: 'confirmed'
      };
      this._OS.confirmPendingOrder(pendingOrderDetail).subscribe((orderResponse: any) => {
        if (orderResponse.success) {
          this.pendingOrders(0, 0);
          this._ALS.success(orderResponse.message);
        } else {
          this._ALS.warn(orderResponse.message);
        }
      });
    }
  }

  cancelledOrder(order) {
    if (confirm("Are you sure to Cancle Order # " + order.id)) {
      const pendingOrderDetail: any = {
        orderId: order._id,
        state: 'refund',
        status: 'cancelled'
      };
      this._OS.canceledOrder(pendingOrderDetail).subscribe((orderResponse: any) => {
        if (orderResponse.success) {
          this.pendingOrders(0, 0);
          this._ALS.success(orderResponse.message);
        } else {
          this._ALS.warn(orderResponse.message);
        }
      });
    }
  }
  pendingOrdersUpa(order) {
    if (confirm("Are you sure to ReStore Order # " + order.id)) {
      const pendingOrderDetail: any = {
        orderId: order._id,
        state: 'new',
        status: 'pending'
      };
      this._OS.restoreOrder(pendingOrderDetail).subscribe((orderResponse: any) => {
        if (orderResponse.success) {
          this.pendingOrders(0, 0);
          this._ALS.success(orderResponse.message);
        } else {
          this._ALS.warn(orderResponse.message);
        }
      });
    }
  }

  resendOTP(orderId) {
    this._OS.reSendOtp(orderId).subscribe((resendOtpData: any) => {
      if (resendOtpData.success) {
        this._ALS.success(resendOtpData.messsage);
      }
    });
  }

  deliveredOrderConfirm(orderId, indexValue) {
    const deliverDetail: any = {
      orderId: orderId,
      state: 'active',
      status: 'delivered',
      otp: this.otp.value + ''
    };
    this._OS.confirmDeliveredOrder(deliverDetail).subscribe((orderResponse: any) => {
      if (orderResponse.success) {
        this.otp.value = '';
        this.dispatchedOrders(2, 0);
        this._ALS.success(orderResponse.message);
      } else {
        this._ALS.warn(orderResponse.message);
      }
    });
  }

  orderNewInvoice2(_id) {
    this._newIS2.getInvoice(_id).subscribe((invoiceResponse: any) => {
      if (invoiceResponse.success) {
        if (invoiceResponse && invoiceResponse.invoice && invoiceResponse.invoice.seller &&
          invoiceResponse.invoice.seller.sellerInformation && invoiceResponse.invoice.seller.sellerInformation.name) {
          this.generateQrCode(invoiceResponse.invoice.id);
          this.generateBarCode(invoiceResponse.invoice.id, invoiceResponse);
        } else {
          this._ALS.warn('Seller Address not found, please fill your invoice address to download invoice');
        }
      }
    });
  }

  generateQrCode(qrString) {
    const qrcode = new QRCode(document.getElementById('qrcode'), {
      text: qrString,
      width: 128,
      height: 128,
      colorDark: '#000000',
      colorLight: '#ffffff',
      correctLevel: QRCode.CorrectLevel.H
    });
    const canvas = document.getElementById('qrcode').querySelector('canvas');
    if (!this._newIS2.code.qrCode) {
      this._newIS2.code = {
        qrCode: '',
        barCode: ''
      };
    }
    this._newIS2.code.qrCode = canvas.toDataURL();
  }

  generateBarCode(codeString, invoiceData) {
    const newCanvas = document.createElement('canvas');
    let barCode = new JsBarcode(newCanvas, codeString, {
      format: 'CODE128'
    });
    this._newIS2.code.barCode = newCanvas.toDataURL('image/png');
    invoiceData.invoice.products.sort((product1, product2) => {
      return product1.mbSku - product2.mbSku;
    })
    if (invoiceData.invoice.outOfStockProducts.length > 0) {
      invoiceData.invoice.products.map(_ => {
        invoiceData.invoice.outOfStockProducts.map(outOfStock => {
          if (_.productId == outOfStock.productId) {
            _.quantity -= outOfStock.quantity;
          }
        })
      })
    }
    if (invoiceData.invoice.returnProducts.length > 0) {
      invoiceData.invoice.products.map(_ => {
        invoiceData.invoice.returnProducts.map(returnProduct => {
          if (_.productId == returnProduct.productId) {
            _.quantity -= returnProduct.quantity;
          }
        })
      })
    }
    this._newIS2.printInvoice(invoiceData);
  }

  searchByBarcode(orderId: any) {
    this.filterNumber = '';
    this.beforeDate.reset();
    this.EndDate.reset();
    if (orderId) {
      this.router.navigate(['/orders/detail'], { queryParams: { id: orderId } });
    } else {
      this._ALS.warn('Please enter order id');
    }
  }

  selectOrder(order) {
    if (this.orderIds[order._id]) {
      delete this.orderIds[order._id]
    } else {
      this.orderIds[order._id] = 1
    }
    console.log(order)
    console.log(Object.keys(this.orderIds));
  }

  openModal(deliveryBoy: TemplateRef<any>) {
    if (Object.keys(this.orderIds).length > 0) {
      this.modalRef = this.modalService.show(deliveryBoy,
        Object.assign({}, { class: 'modal-lg' })
      );
      this.getVerifiedDeliveryBoys();
    } else {
      this._ALS.warn("Please select order")
    }
  }

  getVerifiedDeliveryBoys() {
    this._DS.getDeliveryBoys().subscribe((response: any) => {
      if (response.success) {
        this.deliverBoyList = response.deliveryBoyList;
      } else {
        console.log("error occured");
      }
    })
  }
  assignDeliveryBoyOrder(order, deliveryboyid) {
    let dispatchDetail = {
      orderId: order._id,
      deliveryboyid: deliveryboyid,
      state: 'active',
      status: 'dispatched'
    }

    this._OS.assignDeliveryBoyOrder(dispatchDetail).subscribe((orderResponse: any) => {
      if (orderResponse.success) {
        // this.router.navigate(['/orders-v2'], { queryParams: { id: 1 } })
        // this._ALS.success(orderResponse.message)
        this._ALS.success(orderResponse.message)
        // let orderIndex=this.orders.indexOf(order)
        // this.orders.splice(orderIndex,2)
      } else {
        this._ALS.warn(orderResponse.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  generateRequest(deliveryBoy) {
    let data = {
      deliveryBoyId: deliveryBoy._id,
      orderIds: Object.keys(this.orderIds)
    }
    this.isGenerateRequest = true;
    this._DS.generateRequest(data).subscribe((response: any) => {
      this.isGenerateRequest = false;
      if (response.success && response.jobs.length == 0) {
        deliveryBoy.isRequested = true;
      } else if (response.success && response.jobs.length > 0) {
        let orderIds = response.jobs.map(_ => { return _.payload.id }) + '';
        this._ALS.warn("Order Id:- " + orderIds + " are already requested for this user. Please diselect them and try again");
      } else {
        this._ALS.warn(response.message);
      }
    })
  }

  processOrder(order: any) {
    let orderData = {
      orderId: order._id,
      state: 'active',
      status: 'processed'
    }
    this._OS.confirmProcessedOrder(orderData).subscribe((res: any) => {
      if (res.success) {
        this._ALS.success(res.message)
        let orderIndex = this.orders.indexOf(order)
        this.orders.splice(orderIndex, 1)
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }
  dispatchedOrderconfirm(order) {
    let dispatchDetail = {
      orderId: order._id,
      state: 'active',
      status: 'dispatched'
    }
    this._OS.confirmDispatchedOrder(dispatchDetail).subscribe((orderResponse: any) => {
      if (orderResponse.success) {
        // this.router.navigate(['/orders-v2'], { queryParams: { id: 1 } })
        // this._ALS.success(orderResponse.message)
        this._ALS.success(orderResponse.message)
        let orderIndex = this.orders.indexOf(order)
        this.orders.splice(orderIndex, 2)
      } else {
        this._ALS.warn(orderResponse.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  searchByNumber(number) {
    this.beforeDate.reset();
    this.EndDate.reset();
    this.filterNumber = number;
    if (this.currentTab == 0) {
      this.AllOrders(0, 0);
    } else if (this.currentTab == 1) {
      this.pendingOrders(1, 0);
    } else if (this.currentTab == 2) {
      this.confirmedOrders(2, 0);
    } else if (this.currentTab == 3) {
      this.processedOrders(3, 0)
    } else if (this.currentTab == 4) {
      this.dispatchedOrders(4, 0)
    } else if (this.currentTab == 5) {
      this.deliveredOrders(5, 0)
    } else if (this.currentTab == 6) {
      this.cancelledOrders(6, 0)
    } else if (this.currentTab == 7) {
      this.pendingInitiateOrders(7, 0)
    }
  }

  searchByDate() {
    if (!this.beforeDate.value || !this.EndDate.value || this.beforeDate.value == '' || this.EndDate.value == '') {
      this._ALS.warn('Please select valid date range.');
      return;
    }
    if (this.currentTab == 0) {
      this.AllOrders(0, 0);
    } else if (this.currentTab == 1) {
      this.pendingOrders(1, 0);
    } else if (this.currentTab == 2) {
      this.confirmedOrders(2, 0);
    } else if (this.currentTab == 3) {
      this.processedOrders(3, 0)
    } else if (this.currentTab == 4) {
      this.dispatchedOrders(4, 0)
    } else if (this.currentTab == 5) {
      this.deliveredOrders(5, 0)
    } else if (this.currentTab == 6) {
      this.cancelledOrders(6, 0)
    } else if (this.currentTab == 7) {
      this.pendingInitiateOrders(7, 0)
    }
  }

  RefreshOrder(order) {
    this._OS.manageOrder(order).subscribe((res: any) => {
      if (res.success) {
        if (this.currentTab == 0) {
          this.AllOrders(0, 0);
        } else if (this.currentTab == 1) {
          this.pendingOrders(1, 0);
        } else if (this.currentTab == 2) {
          this.confirmedOrders(2, 0);
        } else if (this.currentTab == 3) {
          this.processedOrders(3, 0)
        } else if (this.currentTab == 4) {
          this.dispatchedOrders(4, 0)
        } else if (this.currentTab == 5) {
          this.deliveredOrders(5, 0)
        } else if (this.currentTab == 6) {
          this.cancelledOrders(6, 0)
        } else if (this.currentTab == 7) {
          this.pendingInitiateOrders(7, 0)
        }
        this._ALS.success(res.message);
      }
      console.log(res.success);
      this._ALS.warn(res.message);
    })
  }
}
