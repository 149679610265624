import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subject, Subscription } from 'rxjs';
import { AlertService, ProductService } from 'src/app/_services';
import { CategoryService } from 'src/app/_services/category.service';
import { environment } from 'src/environments/environment';
import { debounceTime, delay, distinctUntilChanged, flatMap } from 'rxjs/operators';
import { ProductViewService } from 'src/app/_services/product-view.service';

@Component({
  selector: 'app-new-product-view',
  templateUrl: './new-product-view.component.html',
  styleUrls: ['./new-product-view.component.less']
})
export class NewProductViewComponent implements OnInit {

  rootCatList: any = []
  filter: any = {
    isActive: true
  }
  subCatList: any = []
  leafCatList: any = []
  subBrandList: any = []
  brandList: any = []
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  page = 0;
  limit = environment.pagainationLimit;
  sellerProducts: any = []
  productIds: any = []
  addProductViewForm: FormGroup
  public keyUp = new Subject<string>();
  subscription: Subscription;
  searchValue: any = ''
  viewId: any = ''
  addedProduct: any = {}
  params: any = {}

  constructor(public _ALS: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private _CS: CategoryService,
    public _PS: ProductService,
    private fb: FormBuilder,
    private _PVS: ProductViewService,) {
    this.subscription = this.keyUp.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      flatMap(search => of(search).pipe(delay(500)))).subscribe(data => {
        this.filter.keyword = data;
        this.page = 0
        this.sellerProducts = []
        if (data.length > 2) {
          this.getSellerProducts()
        }
      });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(param => {
      this.viewId = param.id
    })
    if (this.viewId) {
      this.getViewDetail()
    }
    this.getCategoryTree()
    this.getBrands()
    this.getSellerProducts()
    this.formControl()
  }

  formControl() {
    this.addProductViewForm = this.fb.group({
      name: [],
      priority: [],
      seo: this.fb.group({
        title: [],
        keyword: [],
        description: [],
        url: []
      })
    })
  }

  getCategoryTree() {
    this._CS.getSellerActiveCatTree().subscribe((cat: any) => {
      this.rootCatList = cat.tree ? cat.tree : [];
    }, (error) => {
      this._ALS.error(error.message)
    })
  }


  getSubCategory(catId, value) {
    this.page = 0;
    if (value === 'subCat') {
      this.filter.rootCategoryId = catId;
      this.rootCatList.filter(_ => {
        if (_._id == catId) {
          this.subCatList = _.children;
          console.log(this.subCatList)
        }
      })
    }
    else {
      this.filter.subCategoryId = catId;
      this.subCatList.filter(_ => {
        if (_._id == catId) {
          this.leafCatList = _.children;
        }
      })
    }
  }

  setLeafCatId(leafCatId: any) {
    this.page = 0
    this.filter.leafCategoryId = leafCatId;
  }


  getSubBrands(brandId: any) {
    this.page = 0
    this.filter.brandId = brandId
    this._PS.getSubBrands(brandId).subscribe((subBrand: any) => {
      this.subBrandList = subBrand.subbrand ? subBrand.subbrand : []
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  getBrands() {
    this.page = 0
    this._PS.getBrands().subscribe((brand: any) => {
      this.brandList = brand.brands ? brand.brands : []
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  setSubBrandId(subBrandId: any) {
    this.page = 0
    this.filter.subBrandId = subBrandId
  }

  getSellerProducts() {
    this._PS.getSellerProducts(this.filter, this.page, this.limit).subscribe((res: any) => {
      this.sellerProducts = [...this.sellerProducts, ...res.products ? res.products : []]
    }, (err) => {
      this._ALS.error(err.message)
    })
  }

  onScrollDown() {
    if (this.params.value != 'true') {
      this.page += 1;
      this.getSellerProducts();
    }
  }

  addProductInView(product) {
    this.addedProduct[product.sellerProductId] = true
  }

  removeProduct(product) {
    delete this.addedProduct[product.sellerProductId]
  }

  addView() {
    let payload: any = this.addProductViewForm.value
    payload['sellerProducts'] = Object.keys(this.addedProduct)
    if (this.viewId) {
      this.updateView(payload)
    }
    else {
      this._PVS.addNewView(payload).subscribe((res: any) => {
        if (res.success) {
          this.router.navigate(['/view-product'])
          this._ALS.success(res.message)
        }
        else {
          this._ALS.warn(res.message)
        }
      }, (err) => {
        this._ALS.error(err.message)
      })
    }
  }

  applyFilter() {
    this.sellerProducts = []
    this.page = 0
    this.getSellerProducts()
  }

  removeFilter() {
    this.rootCatList = []
    this.subCatList = []
    this.subBrandList = []
    this.leafCatList = []
    this.brandList = []
    this.subBrandList = []
    this.searchValue = ''
    this.filter = {}
    this.page = 0;
    this.sellerProducts = []
    this.getCategoryTree()
    this.getBrands()
    this.getSellerProducts()
  }

  updateView(data) {
    this._PVS.updateView(this.viewId,data).subscribe((res:any)=>{
      if(res.success){
        this.router.navigate(['/view-product'])
        this._ALS.success(res.message)
      }
      else{
        this._ALS.warn(res.message)
      }
    },(err)=>{
      this._ALS.error(err.message)
    })
  }

  getViewDetail() {
    this._PVS.getViewDetail(this.viewId, this.params).subscribe((res: any) => {
      if (res.success) {
        this.addProductViewForm.patchValue(res.data)
        res.data.products.map(_ => {
          this.addedProduct[_._id] = true
        })
        if (this.params.isAdded) {
          this.sellerProducts = res.data.products
        }
      }
    }, (err) => {
      this._ALS.error(err.message)
    })
  }

  setParam(event) {
    this.sellerProducts = []
    this.page = 0
    if (event.target.value === "true") {
      this.params.isAdded = true
    } else if (event.target.value == "All") {
      this.params = {}
      this.getSellerProducts()
    }
    this.getViewDetail()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
