import { Component, OnInit, ViewChild } from '@angular/core';
import { GoodsService, AlertService } from 'src/app/_services';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap';

@Component({
  selector: 'app-goods-return-list',
  templateUrl: './goods-return-list.component.html',
  styleUrls: ['./goods-return-list.component.less']
})
export class GoodsReturnListComponent implements OnInit {

  @ViewChild('staticTabs')staticTabs:TabsetComponent
  goodReturnForm:FormGroup;
  goodReturnList:any=[];
  goodRecieveDetail:any; 
  goodRecieveId:FormControl;
  goodRecieveProduct:any={}
  totalReturnQty:any=0;
  grandReturnTotal:any=0;

  constructor(private _GS: GoodsService,
    private _ALS: AlertService,
    private fb:FormBuilder,
    private router:Router,
    private route:ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(param=>{
      let tabId=param.id;
      this.selectTab(tabId)
      if(tabId==1){
        this.getGoodReturnList();
      }
    })
    this.goodRecieveId=new FormControl('',Validators.required)
    this.goodReturnForm=this.fb.group({
      barCode:['',Validators.required],
      returnReason:['Select Reason',Validators.required],
      returnQty:['',Validators.required]
    })
  }

  getGoodReturnList(){
    this._GS.getGoodReturnList().subscribe((res:any)=>{
      this.goodReturnList=res.grlList ? res.grlList : []
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  getGoodRecieveDetail(){
    if(this.goodRecieveId.valid){
      this._GS.getgoodsRecieveDetail(this.goodRecieveId.value).subscribe((res:any)=>{
        if(res.success){
          this._ALS.success(res.message)
          this.goodRecieveDetail=res.grnDetails;
          this.goodRecieveId.reset();
          res.grnDetails.products.map(_=>{
            this.goodRecieveProduct[_.barcode]=_;
          })
        }
        else{
          this._ALS.warn(res.message)
        }
      },(error)=>{
        this._ALS.error(error.message)
      })
    }
    else{
      this.goodRecieveId.markAsTouched();
    }
  }

  addReturnProduct(){
      let barCode=this.goodReturnForm.value['barCode'].trim();
    if(this.goodRecieveProduct[barCode]!=null){
      if((this.goodRecieveProduct[barCode].GRNQuantity-this.goodRecieveProduct[barCode].GRLQuantityReturned) < this.goodReturnForm.value['returnQty']){
        this._ALS.warn("Return product can't be greater than Recieved product")
        return ;
      }
      if (this.goodRecieveProduct[barCode].returnQty > this.goodReturnForm.value['returnQty'] || this.goodRecieveProduct[barCode].returnQty < this.goodReturnForm.value['returnQty'] ) {
        this.grandReturnTotal -= this.goodRecieveProduct[barCode].returnTotal
        this.totalReturnQty -= this.goodRecieveProduct[barCode].returnQty
      }
      this.goodRecieveProduct[barCode].returnQty=this.goodReturnForm.value['returnQty'];
      this.goodRecieveProduct[barCode].returnTotal=this.goodRecieveProduct[barCode].subTotal * this.goodReturnForm.value['returnQty']
      this.totalReturnQty+= this.goodRecieveProduct[barCode].returnQty;
      this.grandReturnTotal+= this.goodRecieveProduct[barCode].returnTotal;
      this.goodRecieveProduct[barCode].reason=this.goodReturnForm.value['returnReason']
      this.goodReturnForm.reset()
    }
  }

  submitGoodReturn(){
  let returnProducts:any=[]
  this.goodRecieveDetail.products.map(_=>{
     if(_.returnQty){
      let product = {
         GRLQuantity:_.returnQty,
         productId:_.productId,
         reason:_.reason
       }
       returnProducts.push(product)
     }
   })
   this._GS.submitGrl({grnId:this.goodRecieveDetail._id,products:returnProducts}).subscribe((res:any)=>{
     if(res.success){
       this._ALS.success(res.message)
       this.grandReturnTotal=0;
       this.totalReturnQty=0;
       this.goodRecieveDetail=''
       this.setTab(1)
      }
     else{
       this._ALS.warn(res.message)
     }
   },(error)=>{
     this._ALS.error(error.message)
   })
  }

  downloadGrnDetail(grId){
    this._GS.getGrlDetails(grId).subscribe((res:any)=>{
      if(res.success){
        this._GS.printGoodReturnNote(res.grl)
      }
    },(error)=>{
    this._ALS.error(error.message)
  })
  }

  setTab(id){
    this.router.navigate(['/goods/return/list'],{queryParams:{id:id}})
  }

  selectTab(tabId){
    this.staticTabs.tabs[tabId].active=true;
  }
}
