import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, AlertService } from '../../../_services';

@Component({
  selector: 'app-emailverification',
  templateUrl: './emailverification.component.html',
  styleUrls: ['./emailverification.component.less']
})
export class EmailverificationComponent implements OnInit {
  verificationToken: any;
  emailVerifiedData: any;

  constructor(private router: ActivatedRoute,
    private _US: UserService,
    private _AS: AlertService,
    private route: Router) { }

  ngOnInit() {
    this.router.params.subscribe(params => {
      this.verificationToken = params.token;
    });
    this.VerifyEmail();
  }

  VerifyEmail() {
    this._US.verifyEmail(this.verificationToken).subscribe(data => {
      this.emailVerifiedData = data;
      if (this.emailVerifiedData.success) {
        this._AS.success(this.emailVerifiedData.message);
        setTimeout(() => {
          this.route.navigate(['/login']);
        }, 5000);
      } else {
        this._AS.warn(this.emailVerifiedData.message);
        setTimeout(() => {
          this.route.navigate(['/login']);
        }, 5000);
      }
    });
  }

}
