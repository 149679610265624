import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leaf-category',
  templateUrl: './leaf-category.component.html',
  styleUrls: ['./leaf-category.component.less']
})
export class LeafCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
