import { Component, OnInit } from '@angular/core';
import { BuyerService,AlertService } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-buyer-purchaserequest-detail',
  templateUrl: './buyer-purchaserequest-detail.component.html',
  styleUrls: ['./buyer-purchaserequest-detail.component.less']
})
export class BuyerPurchaserequestDetailComponent implements OnInit {
  tableHeader = ["S.No.", "Bar Code", "Image", "Product Name", "Brand Name", "Attribute"]
  products:any=[];
  id:any;
  quotationSelected:any={};
  isReqSubmitted:boolean=false;
  purchaseDetail: any={};
  grandTotal:any=0;
  totalItem:any=0;

  constructor(
    private _BS:BuyerService,
    private _ALS:AlertService,
    private route:ActivatedRoute,
    private router:Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params:any)=>{
      if(params.id){
        this.id = params.id;
        this.getPurchaseDetail();
      }
    })
  }

  getPurchaseDetail(){
    this._BS.getPurchaseDetail(this.id).subscribe((response:any)=>{
      this.purchaseDetail=response.data;
      this.products = response.data.products ? response.data.products:[];
    },(error)=>{
      this._ALS.error(error.message);
    })
  }

  selectQuotation(item:any){
    if(this.quotationSelected[item.productId]){
      this.grandTotal-=this.quotationSelected[item.productId].total;
    }
    else{
      this.totalItem+=item.purchaseQuantity;
    }
    this.quotationSelected[item.productId]={};
    this.quotationSelected[item.productId].total=item.totalPrice
    this.quotationSelected[item.productId].quotationId= item.quotationId;
    this.grandTotal+=item.totalPrice
  }

  generatePurchaseRequest(){
    let productIds = Object.keys(this.quotationSelected);
    let products = productIds.map(_=>{
      return {
        productId:_,
        quotationId:this.quotationSelected[_].quotationId
      }  
    })
    let data = {
      productsQuotation:products,
      purchaseRequestId:this.id
    }
    this.isReqSubmitted=true;
    this._BS.generatePurchaseRequest(data).subscribe((response:any)=>{
      this.isReqSubmitted=false;
      if(response.success){
        this.router.navigate(['/buyer/list'],{queryParams:{id:2}})
        this._ALS.success(response.message);
      }else{
        this._ALS.warn(response.message);
      }
    },(error)=>{
      this._ALS.error(error.message);
      this.isReqSubmitted=false;
    })
  }

}
