import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/_services';
import { SlotService } from 'src/app/_services/slot.service';

@Component({
  selector: 'app-slot-new',
  templateUrl: './slot-new.component.html',
  styleUrls: ['./slot-new.component.less']
})
export class SlotNewComponent implements OnInit {

  areaList:any=[]

  constructor(private _SS:SlotService,
    private _ALS:AlertService) { }

  ngOnInit() {
    this.getServeAreas()
  }

  getServeAreas(){
    this._SS.getServeArea().subscribe((res:any)=>{
      this.areaList= res.areas ? res.areas :[]
    },(err)=>{
      this._ALS.error(err.message)
    })
  }
}
