import { Injectable } from '@angular/core';
import { HttpClient }    from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryBoyService {

  constructor(private http: HttpClient) { }
  
  verify(data) {
    return this.http.post(environment.apiURL + 'deliveryboy/verify ', data);
  }

  getDeliveryBoysByOrderId(orderId) {
    return this.http.get(environment.apiURL + '/deliveryboy/request/list?orderId='+orderId);
  }

  generateRequest(data) {
    return this.http.post(environment.apiURL + 'deliveryboy/select',data);
  }

  addDeliveryBoy(addData){
    return this.http.post(environment.apiURL+'deliveryboy',addData)
  }

  updateDeliveryBoy(id,updateData){
    return this.http.put(environment.apiURL+`deliveryboy/${id}`,updateData)
  }

  getDeliveryBoys(){
    return this.http.get(environment.apiURL+'deliveryboy')
  }
  getDeliveryBoysData(data:any)
  {
    return this.http.get(environment.apiURL+ `report/deliveryboy?deliveryBoyId=${data.deliveryBoyId}&from=${data.from}&to=${data.to}`)
  }
}
