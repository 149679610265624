import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args == 'cat') {
      return `${environment.apiURL}public/cat/${value.replace(/ /g, '-')}`;
    } else if (args === 'city') {
      return `${environment.apiURL}public/city/${value.replace(/ /g, '-')}`;
    } else if (args === 'signature') {
      return value && value.length !== 0 ? `${environment.apiURL}public/signature/${value.replace(/ /g, '-')}` : '';
    } 
    else if (args === 'grnInvoices') {
      return value && value.length !== 0 ? `${environment.apiURL}public/seller/grnInvoices/${value}` : '';
    } 
    else if (args === "banner") {
      return value && value.length !== 0 ?  `${environment.apiURL}public/banner/${value}` : '';
    }
    else if(args == "tempBanner"){
      return value && value.length !== 0 ?  `${environment.apiURL}public/temp/${value}` : '';
    }
    else if(args == "offerBanner"){
      return value && value.length !== 0 ?  `${environment.apiURL}public/offerBanner/${value}` : '';
    }
    else {
      return value && value.length !== 0 ?
       `${environment.apiURL}public/product/${value}` : '';
    }
  } 
}

