import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PurchaseService, AlertService, ProductService } from 'src/app/_services';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, flatMap, delay } from 'rxjs/operators';
import { of, Subject, Subscription } from 'rxjs';
import {TabsetComponent } from 'ngx-bootstrap';
import { CategoryService } from 'src/app/_services/category.service';

@Component({
  selector: 'app-purchase-request',
  templateUrl: './purchase-request.component.html',
  styleUrls: ['./purchase-request.component.less']
})
export class PurchaseRequestComponent implements OnInit {
  @ViewChild('staticTabs')staticTabs :TabsetComponent
  purchaseListData: any;
  daysForm: FormGroup;
  formSubmitAttempt = false;
  maxDate: any;
  valdationMessages = {
    'startDate': { 'required': 'start date is required.' },
    'endDate': { 'required': 'end date is required.' }
  };
  formErrors = {
    'startDate': '',
    'endDate': ''
  };
  showPurchaseTable = false;
  modalRef: BsModalRef;
  purchaseListProducts: any = [];
  rootCatList: any = [];
  subCatList: any = [];
  leafCatList: any = [];
  cartProductList: any = [];
  isAddedInCart: any;
  purchaseQtyForm: FormGroup;
  purchaseReqList: any = [];
  cart: any = {};
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  page = 0;
  limit = 18;
  isReqGenerated: boolean = false;
  cartTotalItem: any = 0;
  filter: any = {};
  subBrandList: any = [];
  brandList: any = [];
  searchKey: FormControl
  isAllProductEnd: boolean = false;
  public keyUp = new Subject<string>();
  debounceSubscription: Subscription;
  isShowLoader:boolean=false;

  constructor(private _PS: PurchaseService,
    private _ALS: AlertService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private _PDS: ProductService,
    private route:ActivatedRoute,
    private router:Router,
    private _CS:CategoryService
  ) { 
    this.maxDate = new Date();
    this.searchKey=new FormControl()
    this.daysForm = this.formBuilder.group({
      startDate: ['', Validators.required],
      endDate: [this.maxDate, Validators.required],
    });
    this.daysForm.valueChanges.subscribe(_ => {
      this.validationError(this.daysForm);
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params=>{
      let tabId=params.id;
      this.selectTab(tabId)
      if(tabId==1){
        this.getCartProducts();
      }
      else if(tabId==2){
        this.getPurchaseRequests();
      }
      else{
        this.getPurchaseRequestList()
        this.getCartProducts() 
      }
    })
    this.getCategoryTree();
    this.getBrands();
    this.debounceSubscription = this.keyUp.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      flatMap(search => of(search).pipe(delay(500)))).subscribe(value => {
        if(value.length>0){
          this.filter.keyword = value;
        }else{
          delete this.filter.keyword;
        }
        this.page = 0
        this.purchaseListProducts = [];
        this.getPurchaseRequestList()
      });
  }

  validationError(group: FormGroup): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validationError(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty) || (abstractControl.untouched && this.formSubmitAttempt)) {
          const message = this.valdationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += message[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  submitDate(){
    this.filter.endDate = this.daysForm.value['endDate'];
    this.filter.startDate = this.daysForm.value['startDate'];
    this.page=0;
    this.purchaseListProducts=[];
    this.getPurchaseRequestList();
  }

  getCategoryTree() {
    this._CS.getSellerActiveCatTree().subscribe((category: any) => {
      if (category.success) {
        this.rootCatList = category.tree;
      }
    })
  }

  getSubCategory(catId, value) {
    if (value === 'rootCat') {
      this.filter.rootCatId = catId;
      this.rootCatList.filter(_ => {
        if (_._id == catId) {
          this.subCatList = _.children;
        }
      })
    }
    else if (value === 'subCat') {
      this.filter.subCatId = catId;
      this.subCatList.filter(_ => {
        if (_._id == catId) {
          this.leafCatList = _.children;
        }
      })
    }
    else {
      this.filter.leafCatId = catId;
    }
  }

  changeShowDayCard() {
    this.showPurchaseTable = this.showPurchaseTable ? false : true;
    this.daysForm.reset();
  }

  getPurchaseRequestList() {
    this.isShowLoader=true
    this._PS.getPurchaseOrder(this.page, this.limit, this.filter).subscribe((response: any) => {
      this.isShowLoader=false
      if (response.success) {
        this.purchaseListProducts = [...this.purchaseListProducts, ...response.products];
      }
    },(error)=>{
      this.isShowLoader=false
      this._ALS.error(error.message)
    })
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}));
  }

  addToCart(event, productId) {
    if (event.target.quantity.value > 0) {
      this._PS.addToCart({ productId: productId, quantity: event.target.quantity.value }).subscribe((res: any) => {
        if (res.success) {
          this._ALS.success(res.message)
          this.getCartProducts()
        }
        else {
          this._ALS.warn(res.message);
        }
      }, (error) => {
        this._ALS.error(error.message);
      })
    } else {
      this._ALS.warn("quantity should be greater then zero");
    }
  }

  getCartProducts() {
    this._PS.getCartProduct().subscribe((response: any) => {
      this.cartProductList = response.cart ? response.cart : [];
      this.cart = {};
      let products = this.cartProductList.product ? this.cartProductList.product : []
      products.forEach(element => {
        this.cart[element._id] = element.purchaseQuantity;
      });
    })
  }

  deleteCartProduct(productId: any) {
    this._PS.deleteCartProduct(productId).subscribe((res: any) => {
      if (res.success) {
        this._ALS.success(res.message);
        this.getCartProducts();
      }
      else {
        this._ALS.warn(res.message);
      }
    }, (error) => {
      this._ALS.error(error.message);
    })
  }

  updateProductQty(productQuantity: any, productId: any, value: any) {
    let quantity = productQuantity;
    if (value === 'decrease') {
      quantity = productQuantity - 1;
    }
    else if (value === 'increase') {
      quantity = productQuantity + 1;
    }
    else {
      quantity = productQuantity;
    }
    if (quantity >= 1) {
      this._PS.updateProductQty({ productId: productId, quantity: quantity }).subscribe((res: any) => {
        if (res.success) {
          this._ALS.success(res.message);
          this.getCartProducts();
        }
        else {
          this._ALS.warn(res.message);
        }
      }, (error) => {
        this._ALS.error(error.message);
      })
    } else {
      this.deleteCartProduct(productId);
    }
  }

  generatePurchaseReq() {
    let products = this.cartProductList.product.map(_ => {
      return {
        productId: _._id,
        name: _.name,
        barcode: _.barCode,
        purchaseQuantity: _.quantity,
      }
    })
    let data = { products: products }
    this.isReqGenerated = true;
    this._PS.generatePurchaseReq(data).subscribe((res: any) => {
      this.isReqGenerated = false;
      if (res.success) {
        this._ALS.success(res.message);
        this.getCartProducts()
        this.hideModal();
      }
      else {
        this._ALS.warn(res.message);
      }
    }, (error) => {
      this._ALS.error(error.message);
      this.isReqGenerated = false;
    })
  }

  getPurchaseRequests() {
    this._PS.getPurchseRequests().subscribe((request: any) => {
      if (request.success) {
        this.purchaseReqList = request.purchaseRequestList;
      }
    })
  }

  hideModal() {
    this.modalRef.hide();
  }

  onScrollDown() {
    this.page += 1;
    this.getPurchaseRequestList();
  }

  setPage() {
    this.page = 0;
    this.purchaseListProducts = []
  }

  getBrands() {
    this._PDS.getBrands().subscribe((brand: any) => {
      this.brandList = brand.brands ? brand.brands : [];
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  getSubBrands(brandId: any) {
    if (brandId != "") {
      this.filter.brandId = brandId
      this._PDS.getSubBrands(brandId).subscribe((subBrand: any) => {
        this.subBrandList = subBrand.subbrand ? subBrand.subbrand : []
      }, (error) => {
        this._ALS.error(error.message)
      })
    } else {
      delete this.filter.brandId;
    }
  }

  setLeafCatId(leafCatId: any) {
    this.filter.leafCatId = leafCatId;
  }

  setSubBrandId(subBrandId: any) {
    if (subBrandId != "") {
      this.filter.subBrandId = subBrandId
    } else {
      delete this.filter.subBrandId;
    }
  }

  removeFilter() {
    this.filter = {};
    this.searchKey.reset();
    this.daysForm.reset();
    this.subBrandList = [];
    this.subCatList = [];
    this.leafCatList = [];
    this.rootCatList=[]
    this.brandList=[]
    this.getBrands()
    this.getCategoryTree()
    this.getPurchaseRequestList();
  }

  applyFilter() {
    this.page = 0;
    this.purchaseListProducts = [];
    this.getPurchaseRequestList();
  }

  setSelectedTab(id:any){
    this.router.navigate(['/purchase/request'],{queryParams:{id:id}})
  }

  selectTab(tabId){
    this.staticTabs.tabs[tabId].active=true
  }
}
