import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService, UserService, ProductService } from 'src/app/_services';
import { DeliveryService } from 'src/app/_services/delivery.service';
import { SlotService } from 'src/app/_services/slot.service';

@Component({
  selector: 'app-order-size',
  templateUrl: './order-size.component.html',
  styleUrls: ['./order-size.component.less']
})
export class OrderSizeComponent implements OnInit {
  deliveryChargeForm: FormGroup
  isEdit: boolean = false
  // isCodOrder:FormControl

  cityDeliverySettingForm: FormGroup
  serveArea: any = []
  charges: any = []
  isAdd: boolean = false
  cityChargeId: any = ''
  settingId: any = ''
  isShowLoader: boolean = false;
  sellerProductList: [];
  selectedId:any;

  constructor(private fb: FormBuilder,
    private _DS: DeliveryService,
    private _ALS: AlertService,
    private _SS: SlotService,
    private _US: UserService,
    public _PS: ProductService) { }

  ngOnInit() {
    // this.isCodOrder = new FormControl('')
    this.deliveryChargeForm = this.fb.group({
      deliveryCharges: ['', Validators.required],
      minimumOrderValue: ['', Validators.required],
//       offerOrderAmount: ['', Validators.required],
//       offerOrderProduct: ['', Validators.required],
      offerOrderAmount: [''],
      offerOrderProduct: [''],
      areaId: [""],
      isCodOrder: [''],
      deliveryChargeType: ["area"]
    })
    this.cityDeliverySettingForm = this.fb.group({
      deliveryCharges: ['', Validators.required],
      minimumOrderValue: ['', Validators.required],
      areaId: [""],
      deliveryChargeType: ["city"]
    })
    this.getDeliveryCharge()
    this.getCityDeliverySetting()
    //this.getSellerPofile()
    this.getServeAreas()
    this.getSellerProducts()
  }


  // addDeliveryCharge(){
  //   if(this.deliveryChargeForm.valid){
  //     if(this.isEdit){
  //       this.updateDeliveryCharge()
  //     }
  //     else{
  //       this._DS.addDeliveryCharge(this.deliveryChargeForm.value).subscribe((res:any)=>{
  //         if(res.success){
  //           this._ALS.success(res.message)
  //         }
  //         else{
  //           this._ALS.warn(res.message)
  //         }
  //       },(error)=>{
  //         this._ALS.error(error.message)
  //       })
  //     }
  //   }
  //   else{
  //     this.deliveryChargeForm.controls['deliveryCharges'].markAsTouched()
  //     this.deliveryChargeForm.controls['minimumOrderValue'].markAsTouched()
  //   }
  // }

  // getDeliveryCharge(){
  //   this._DS.getDeliverycharge().subscribe((res:any)=>{
  //     if(res.success){
  //       this.deliveryChargeForm.patchValue(res.setting)
  //       this.isEdit=true
  //     }
  //   },(error)=>{
  //     this._ALS.error(error.message)
  //   })
  // }

  // updateDeliveryCharge(){
  //   this._DS.updateDeliveryCharge(this.deliveryChargeForm.value).subscribe((res:any)=>{
  //     if(res.success){
  //       this._ALS.success(res.message)
  //       this.getDeliveryCharge()
  //     }
  //     else{
  //       this._ALS.warn(res.message)
  //     }
  //   },(error)=>{
  //     this._ALS.error(error.message)
  //   })
  // }

  // updateCod(){
  //   this._DS.updateCod({isCodOrder:this.isCodOrder.value}).subscribe((res:any)=>{
  //     if(res.success){
  //       this._ALS.success(res.message)
  //       this.getSellerPofile()
  //     }
  //     else{
  //       this._ALS.warn(res.message)
  //     }
  //   },(err)=>{
  //     this._ALS.error(err.message)
  //   })
  // }

  // getSellerPofile(){
  //   this._US.getSellerInfo().subscribe((res:any)=>{
  //     if(res.success){
  //       this.isCodOrder.setValue(res.seller[0].isCodOrder)
  //     }
  //   },(err)=>{
  //     this._ALS.error(err.message)
  //   })
  // }



  addDeliveryCharge(type) {
    if (this.deliveryChargeForm.valid || this.cityDeliverySettingForm.valid) {
      let payload = {}
      if (type === "area") {
        payload = this.deliveryChargeForm.value
      }
      else {
        payload = this.cityDeliverySettingForm.value
      }
      this._DS.addDeliveryCharge(payload).subscribe((res: any) => {
        if (res.success) {
          this._ALS.success(res.message)
          this.isAdd = false
          this.isEdit = false
          this.deliveryChargeForm.reset()
          this.getDeliveryCharge()
        }
        else {
          this._ALS.warn(res.message)
        }
      }, (error) => {
        this._ALS.error(error.message)
      })

    }
    else {
      this.deliveryChargeForm.controls['deliveryCharges'].markAsTouched()
      this.deliveryChargeForm.controls['minimumOrderValue'].markAsTouched()
    }
  }

  addNewCharge() {
    this.deliveryChargeForm.reset()
    this.isAdd = true
    this.isEdit = false
  }

  getDeliveryChargeDetail(id) {
    console.log(id)
    this.isEdit = true
    this.isAdd = false
    this.deliveryChargeForm.get('areaId').setValue(id)
    this._DS.getChargeDetail(this.deliveryChargeForm.get('areaId').value).subscribe((res: any) => {
      if (res.success) {
        this.deliveryChargeForm.patchValue(res.data)
        this.selectedId = res.data.offerOrderProduct ? res.data.offerOrderProduct.id : '';
      }
      else {

        this.deliveryChargeForm.reset()
      }
    }, (err) => {
      this._ALS.error(err.message)
    })
  }

  getDeliveryCharge() {
    this._DS.getDeliverycharge().subscribe((res: any) => {
      this.charges = res.setting ? res.setting : []
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  updateDeliveryCharge() {
    console.log(this.deliveryChargeForm);
    if (this.deliveryChargeForm.valid) {
      console.log(this.deliveryChargeForm.value);
      this._DS.updateDeliveryCharge(this.deliveryChargeForm.value).subscribe((res: any) => {
        if (res.success) {
          this._ALS.success(res.message)
          this.isAdd = false
          this.isEdit = false
          this.deliveryChargeForm.reset()
          this.getDeliveryCharge()
        }
        else {
          this._ALS.warn(res.message)
        }
      }, (error) => {
        this._ALS.error(error.message)
      })
    }
    else {
      this.deliveryChargeForm.controls['deliveryCharges'].markAsTouched()
      this.deliveryChargeForm.controls['minimumOrderValue'].markAsTouched()
    }
  }

  getServeAreas() {
    this._SS.getServeArea().subscribe((res: any) => {
      this.serveArea = res.areas ? res.areas : []
    })
  }

  // updateCod() {
  //   this._DS.updateCod({ isCodOrder: this.isCodOrder.value }).subscribe((res: any) => {
  //     if (res.success) {
  //       this._ALS.success(res.message)
  //       this.getSellerPofile()
  //     }
  //     else {
  //       this._ALS.warn(res.message)
  //     }
  //   }, (err) => {
  //     this._ALS.error(err.message)
  //   })
  // }

  // getSellerPofile() {
  //   this._US.getSellerInfo().subscribe((res: any) => {
  //     if (res.success) {
  //       this.isCodOrder.setValue(res.seller[0].isCodOrder)
  //     }
  //   }, (err) => {
  //     this._ALS.error(err.message)
  //   })
  // }

  getCityDeliverySetting() {
    this._DS.getCityDeliveryCharge().subscribe((res: any) => {
      if (res.success) {
        this.cityDeliverySettingForm.patchValue(res.data)
        this.settingId = res.data._id
      }
      console.log(this.cityDeliverySettingForm.value)
    }, (err) => {
      this._ALS.error(err.message)
    })
  }

  updateCitySetting() {
    if (this.cityDeliverySettingForm.valid) {
      this._DS.updateCityDeliveryCharge(this.cityDeliverySettingForm.value).subscribe((res: any) => {
        if (res.success) {
          this._ALS.success(res.message)
          this.getCityDeliverySetting()
        }
        else {
          this._ALS.warn(res.message)
        }
      }, (err) => {
        this._ALS.error(err.message)
      })
    }
  }

  getSellerProducts() {
    this.isShowLoader = true
    this._PS.getSellerProductss().subscribe((product: any) => {
      this.isShowLoader = false;
      if (product.success) {
        product.products.map(_ => {
          _.maxDiscount = `${((_.price - _.minSellPrice) * 100 / _.price).toFixed(2)}`
          _.margin = `${((_.price - _.purchasePrice) * 100 / _.price).toFixed(2)}`
          _.discount = `${((_.price - _.sellPrice) * 100 / _.price).toFixed(2)}`
        })
        this.sellerProductList = product.products;
      } else {
        this._ALS.warn(product.message);
        this.sellerProductList = []
      }
    }, (error) => {
      this.isShowLoader = false
      this._ALS.error(error.message)
    });
  }

  selectedProduct(product: any) {
    for (let x of this.sellerProductList) {
      if (x['id'] == product.target.value) {
        this.deliveryChargeForm.value.offerOrderProduct = x
      }
    }
    console.log(this.deliveryChargeForm.value.offerOrderProduct)
  }

  getproductSelected(id){
    if(id == this.selectedId){
      return true
    }else{
      return false
    }
  }
}
