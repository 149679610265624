import { Component, OnInit, TemplateRef } from '@angular/core';
import { SlotService } from 'src/app/_services/slot.service';
import { AlertService } from 'src/app/_services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.less']
})
export class SlotComponent implements OnInit {

  timeSlots:any=[]
  modalRef:BsModalRef
  slotId: any;
  constructor(private _SS:SlotService,
    private _ALS:AlertService,
    private modalService:BsModalService) { }

  ngOnInit() {
    this.getSlot()
  }

  getSlot(){
    this._SS.getTimeSlot().subscribe((res:any)=>{
      this.timeSlots=res.timeSlot ? res.timeSlot : []
      this.timeSlots.map(_=>{
        let startH=_.value.hr%12
        if(startH == 0){
          startH=12
        }
      let startHour=this.changeDigit(startH);
       let slot=_.slot.split('-')
        let fisrtSlotMin=slot[0].split(':')[1]
        let endH=(slot[1].split(':')[0]) % 12
        if(endH==0){
          endH=12
        }
        let endSlotMin=slot[1].split(':')[1];
        let endSlotHr=this.changeDigit(endH)
        _.slot=`${startHour}:${fisrtSlotMin} - ${endSlotHr}:${endSlotMin}`
        let closingHr=(_.value.minutes / 60);
        let closingMin=_.value.minutes % 60
        let closingAmPm=closingHr >= 12 ? 'PM' : 'AM'
        closingHr= (closingHr) % 12
        if(closingHr>0 && closingHr<1){
          closingHr=12
        }
        _.value.minutes=this.changeDigit(closingHr.toString().split('.')[0])+':'+this.changeDigit(closingMin)+closingAmPm
      })
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  deleteSlot(){
    this._SS.deleteSlot(this.slotId).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
        this.getSlot()
      }
    else{
      this._ALS.warn(res.message)
    }
    },(error)=>{
      this._ALS.error(error.message)
    })
    this.modalRef.hide()
  }

  changeDigit(time){
    return (time < 10 ? '0' : '')+time
  }

  openModal(template:TemplateRef<any>,slotId:any){
    this.slotId=slotId
    this.modalRef = this.modalService.show(template, Object.assign({}))
  }

  hideModal(){
    this.modalRef.hide();
  }
}
