import { Component, OnInit } from '@angular/core';
import { LocationService, AlertService } from '../../../_services/index';
import { TypeaheadMatch } from 'ngx-bootstrap';

@Component({
  selector: 'app-add-area',
  templateUrl: './add-area.component.html',
  styleUrls: ['./add-area.component.less']
})
export class AddAreaComponent implements OnInit {

  responseCityData: any;
  productSearchValue: any;
  responseAddCity: any;
  responseAddedCity: any = [];
  responseSocietyData: any = [];
  societySearchData: any;
  cityModel: any = {
    cityRadioGroup: 1
  };
  selectedSociety: any = [];
  allSelectModel = false;
  getResponseAddedSociety: any;
  societyData: any = [];
  addedSocietyData: any = [];
  responseAddedSociety: any;
  selectCityModel: any = {};
  selectedCityId: any;
  cityAddedData: any;
  cityNotAddedData: any;

  constructor(private _LS: LocationService,
    private _AS: AlertService) {
  }

  ngOnInit() {
    this.getAllcity();
  }

  getAllcity() {
    this._LS.getAllCity().subscribe(
      data => {
        this.responseCityData = data;
        this.cityNotAddedData = this.responseCityData.cities;
        this.selectCity(this.cityNotAddedData[0]._id);
      }
    );
  }

  addedCity() {
    this._LS.getAddedCity().subscribe(
      data => {
        this.responseAddedCity = data;
        if (this.responseAddedCity.success) {
          this.cityAddedData = this.responseAddedCity.city;
          this.getAddedSociety(this.cityAddedData[this.cityModel.cityRadioGroup - 1]._id);
        } else {
          this.getAllcity();
        }
      }
    );
  }

  selectCity(cityId) {
    this.selectedCityId = cityId;
    this._LS.getSociety(cityId).subscribe(
      data => {
        this.responseSocietyData = data;
        this.societyData = this.responseSocietyData.society;
        this.allSelectModel = false;
      }
    );
  }

  toggleSelect(event) {
    let selectedSociety: any = [];
    this.societyData.forEach(function (item, i) {
      item.selected = event.target.checked;
      if (event.target.checked) {
        selectedSociety = [item, ...selectedSociety];
      } else {
        if (selectedSociety.length > 0) {
          selectedSociety.splice(i, 1);
        }
      }
    });
    this.selectedSociety = selectedSociety;
  }

  getSociety(event, society) {

    let selectedSociety: any = [];
    selectedSociety = this.selectedSociety;
    if (event.target.checked) {
      this.selectedSociety = [society, ...this.selectedSociety];
    } else {
      this.allSelectModel = false;
      if (selectedSociety.length > 0) {
        selectedSociety.map((_, i) => {
          if (_._id == society._id) {
            selectedSociety.splice(i, 1);
            this.selectedSociety = selectedSociety;
          }
        });
      }
    }
  }

  addSocietyWithCity() {

    this._LS.addSocietyWithCity(this.selectedSociety, this.selectedCityId).subscribe(
      data => {
        this.responseAddedSociety = data;
        if (this.responseAddedSociety.success) {
          this.responseCityData.success = !this.responseCityData.success;
          this.responseSocietyData.success = !this.responseSocietyData.success;
          this._AS.success(this.responseAddedSociety.message);
          this.addedCity();
        } else {
          this._AS.warn(this.responseAddedSociety.message);
        }
      },
      error => {
        this._AS.error(error.message);
      }
    );
  }

  getAddedSociety(cityId) {
    this._LS.getAddedSociety(cityId).subscribe(
      data => {
        this.getResponseAddedSociety = data;
        if (this.getResponseAddedSociety.success) {
          this.addedSocietyData = this.getResponseAddedSociety.society;
        } else {
          if (this.cityModel.cityRadioGroup == 1) {
            this.selectCity(this.cityNotAddedData[0]._id);
          }
        }
      }
    );
  }

  addNewDeliveryArea() {
    this.getAllcity();
    this.getResponseAddedSociety.success = !this.getResponseAddedSociety.success;
    this.responseAddedCity.success = !this.responseAddedCity.success;
  }

}
