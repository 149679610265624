import { Component, OnInit } from '@angular/core';
import { GoodsService, AlertService } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-goods-return-details',
  templateUrl: './goods-return-details.component.html',
  styleUrls: ['./goods-return-details.component.less']
})
export class GoodsReturnDetailsComponent implements OnInit {

  goodsReturnDetail: any={};

  constructor(private _GS: GoodsService,
    private _ALS: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param=>{
      let grId=param.id;
     this.getGoodReturnDetails(grId)
    })
  }

  getGoodReturnDetails(grId){
    this._GS.getGrlDetails(grId).subscribe((res:any)=>{
      if(res.success){
        this.goodsReturnDetail=res.grl;
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

}
