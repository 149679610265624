import { Component, OnInit } from '@angular/core';
import { AlertService, StockService } from 'src/app/_services';
import { StockAuditService } from 'src/app/_services/stock-audit.service';

@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.less']
})
export class AuditReportComponent implements OnInit {

  params: any = {
    page: 0,
    limit: 10
  }
  auditList:any=[]
  constructor(private _SA:StockAuditService,
    private _ALS:AlertService) { }

  ngOnInit() {
    this.getStockAuditList()
  }

  getStockAuditList(){
    this._SA.getStockAuditList(this.params).subscribe((res:any)=>{
      this.auditList=[...this.auditList,...res.audit ? res.audit : []]
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  onScroll(){
    this.params.page+=1;
    this.getStockAuditList()
  }
}
