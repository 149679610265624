import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DeliveryBoyService } from 'src/app/_services';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.less']
})
export class TableComponent implements OnInit {
  @Input("deliverBoyList") deliverBoyList: any;
  @Input("newDeliveryBoy") newDeliveryBoy: boolean=false;
  @Input("active") active: boolean=false;
  @Input("reject") reject: boolean=false;
  @Output("onClickSelect") onClickSelect: EventEmitter<any> = new EventEmitter();

  constructor(
    private _DS: DeliveryBoyService
  ) { }

  ngOnInit() {
    console.log(this.deliverBoyList);
  }
  
  rejectBoy(deliverBoy){
    let data = {
      deliveryBoyId: deliverBoy._id
    }

  }
   
  acceptBoy(deliverBoy){
    let data = {
      deliveryBoyId: deliverBoy._id
    }
    this._DS.verify(data).subscribe((data:any)=>{
      if(data.success){
        deliverBoy.isVerify=true;
      }else{
        console.log(data.message);
      }
    })
  }
  
}
