import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductViewService {

  constructor(private http:HttpClient) { }

  addNewView(data){
    return this.http.post(environment.apiURL+`view`,data)
  }

  getViews(){
    return this.http.get(environment.apiURL+'view')
  }

  deleteView(id){
    return this.http.delete(environment.apiURL+`view/${id}`)
  }

  activateView(data){
    return this.http.put(environment.apiURL+`view/activate`,data)
  }

  getViewDetail(id,param){
    return this.http.get(environment.apiURL+`view/${id}`,{params:param})
  }

  updateView(id,data){
    return this.http.put(environment.apiURL+`view/${id}`,data)
  }
}
