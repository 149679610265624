import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }
  // verify seller api
  isSellerVerify() {
    return this.http.get(environment.apiURL + 'check/profile');
  }
  // guard Api
  checkAddress() {
    return this.http.get(environment.apiURL + 'check/account/info');
  }
  getState() {
    return this.http.get(environment.apiURL + 'state');
  }
  register(body: any) {
    return this.http.post(environment.apiURL + 'register', body);
  }
  getVerifyotp(body: any) {
    return this.http.post(environment.apiURL + 'otp/verify ', body);
  }

  updateSellerInfo(sellerInfoData: any) {
    return this.http.post(environment.apiURL + 'profile', sellerInfoData);
  }

  getSellerInfo() {
    return this.http.get(environment.apiURL + 'profile');
  }

  forgotPassword(body: any) {
    return this.http.post(environment.apiURL + 'forgot', body);
  }
  setPassword(body: any, token) {
    return this.http.put(environment.apiURL + `password/${token}`, body);
  }
  otpVerify(body: any) {
    return this.http.post(environment.apiURL + 'otp/verify', body);
  }
  submitResetPasswordWithToken(body: any) {
    return this.http.post(environment.apiURL + 'password/change/token', body);
  }
  forgotOtpVerify(body: any) {
    return this.http.post(environment.apiURL + 'forgot/verify', body);
  }
  sendEmailForVerification() {
    return this.http.get(environment.apiURL + 'email/verify');
  }
  verifyEmail(token: any) {
    return this.http.post(environment.apiURL + 'email/verify', { verifyToken: token });
  }
  saveSignature(img: any) {
    return this.http.post(environment.apiURL + `image/save`, img);
  }
  forgotTokenVerify(body: any) {
    return this.http.post(environment.apiURL + 'token/verify', body);
  }

  userNotification() {
    return this.http.get(environment.apiURL + 'notification');
  }

  reqOtp(reqData) {
    return this.http.post(environment.apiURL + `sendotp`, reqData)
  }

  verifyOtp(otpData) {
    return this.http.post(environment.apiURL + `otpverify`, otpData)
  }
  set user(user) {
    localStorage.setItem('currentUser', JSON.stringify(user))
  }

  get user() {
    return JSON.parse(localStorage.getItem('currentUser'))
  }

  getUser(user) {
    return this.http.get(environment.apiURL + `user/search`, { params: { value: user } })
  }

  createUser(user) {
    return this.http.post(environment.apiURL + `user/create`, user)
  }
}
