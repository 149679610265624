import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockAuditService {

  constructor(private http:HttpClient) { }

  getStockAuditList(param:any){
    return this.http.get(environment.apiURL+'audit',{params:param})
  }

  getStockAuditDetail(auditId:any,param:any){
    return this.http.get(environment.apiURL+`audit/products/${auditId}`,{params:param})
  }

  updateExpiryBatch(id,batchData){
    return this.http.put(environment.apiURL+`audit/products/${id}`,batchData)
  }

  closeAudit(auditId:any){
    return this.http.put(environment.apiURL+`audit/close/${auditId}`,{})
  }
}
