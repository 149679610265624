import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageSize'
})
export class ImageSizePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value){
      return value;
      // let images=value.split('.');
      // return `${images[0]}-${args}x${args}.${images[1]}`;
    }
   
  }

}
