import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SlotService {

  constructor(private http:HttpClient) { }

  addSlot(slotData){
    return this.http.post(environment.apiURL+'timeslot',slotData)
  }

  getTimeSlot(){
    return this.http.get(environment.apiURL+'timeslot')
  }

  deleteSlot(slotId){
    return this.http.delete(environment.apiURL+`timeslot/${slotId}`)
  }

  getSlotDetail(slotId){
    return this.http.get(environment.apiURL+`timeslot/${slotId}`)
  }

  updateSlot(slotId,slotData){
    return this.http.put(environment.apiURL+`timeslot/${slotId}`,slotData)
  }

  getServeArea(){
    return this.http.get(environment.apiURL+`serveArea`)
  }

  getServeAreaDetail(id){
    return this.http.get(environment.apiURL+`serveArea/${id}`)
  }
  
  addNewSlot(data){
    return this.http.post(environment.apiURL+`slot`,data)
  }

  deleteTimeSlot(areaId,id){
    return this.http.delete(environment.apiURL+`slot/${areaId}/${id}`)
  }

  getTimeSlotDetail(id){
    return this.http.get(environment.apiURL+`slot/${id}`)
  }

  disableSlot(data){
    return this.http.post(environment.apiURL+`disable/slot`,data)
  }

  updateTimeSlot(data){
    return this.http.put(environment.apiURL+`slot`,data)
  }

  getDisableSlot(areaId){
    return this.http.get(environment.apiURL+`disable/slot/${areaId}`)
  }

  cancelDisableSlot(id){
    return this.http.put(environment.apiURL+`disable/slot/${id}`,{})
  }
}
