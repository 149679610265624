import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], field: any[], value: string): any[] {
    if (!items) {
      return [];
    }
    if (!field || !value) {
      return items;
    }

    const selectedItem = [];
    let itemName = 'itemName';
    field.map((_, i) => {
      const splitSelectedItem = _.split('.');
      if (splitSelectedItem.length > 1) {
        splitSelectedItem.map((__, j) => {
          itemName = itemName + `['` + __ + `']`;
        });
      } else {
        itemName = itemName + `['` + splitSelectedItem[0] + `']`;
      }
      selectedItem.push(itemName);
      itemName = 'itemName';
    });


    let filterString = '';
    selectedItem.map((_, i) => {
      filterString += (i > 0 ? '||' : '') + `${_}.toLowerCase().includes(value.toLowerCase())`;
    });

    return items.filter(itemName => {
      if (itemName.name) {
        return eval(filterString) ? true : false;
      }
    });
  }

}
