import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, UserService } from 'src/app/_services';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.less']
})
export class SetPasswordComponent implements OnInit {

  setPasswordForm:FormGroup
  token:any=''

  constructor(private fb:FormBuilder,
    private route:ActivatedRoute,
    private router:Router,
    private _US:UserService,
    private _ALS:AlertService) { }

  ngOnInit() {
    this.setPasswordForm=this.fb.group({
      password:['',Validators.required],
      confirmPassword:[]
    })
    this.route.params.subscribe(param=>{
      if(param.token){
        this.token=param.token
      }
    })
  }

  setPassowrd(){
   if(this.setPasswordForm.valid && this.setPasswordForm.get('password').value == this.setPasswordForm.get('confirmPassword').value){
    this._US.setPassword(this.setPasswordForm.value,this.token).subscribe((res:any)=>{
      if(res.success){
        this.router.navigate(['/login'])
        this._ALS.success(res.message)
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
   }
   else{
     this.setPasswordForm.get('password').markAsTouched()
     this.setPasswordForm.get('confirmPassword').markAsTouched()
   }
  }

}
