import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/_services';
import { BannerService } from 'src/app/_services/banner.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-banner',
  templateUrl: './add-banner.component.html',
  styleUrls: ['./add-banner.component.less']
})
export class AddBannerComponent implements OnInit {
  addBannerForm: any;
  bannerId: any;
  bannerDetail:any=''
  banner:any;
  
  constructor(private formBuilder: FormBuilder,
    private _BS: BannerService,
    private _ALS: AlertService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.formControl()
    this.route.queryParams.subscribe(param => {
      this.bannerId = param.id
    })
    if (this.bannerId) {
      this.getBannerDetail()
    }
    else{
      this.addBannersToForm()
    }
  }

  formControl() {
    this.addBannerForm = this.formBuilder.group({
      title: ['', Validators.required],
      priority: ['', Validators.required],
      banners: this.formBuilder.array([]),
    });
  }

  addBannersToForm(name = null, position = null, searchKeywordWeb = null, searchKeywordApp = null, image = ['', ''],isWebImg='',isAppImg=''): void {
    (<FormArray>this.addBannerForm.get('banners')).push(
      this.formBuilder.group({
        name: [name, Validators.required],
        position: [position, Validators.required],
        webImage: [image[0], Validators.required],
        appImage: [image[1], Validators.required],
        searchKeywordWeb: [searchKeywordWeb],
        searchKeywordApp: [searchKeywordApp],
        isShowWebImg:[isWebImg],
        isShowAppImg:[isAppImg]
      }));
  }

  uploadBannerImage(event, i, type) {
    let imageFile = event.target.files[0];
    let formData = new FormData()
    formData.append('image', imageFile)
    this._BS.uploadBannerImage(formData).subscribe((res: any) => {
      if (res.success) {
        this._ALS.success(res.message)
        if (type == "web") {
          this.addBannerForm.get('banners').get(i.toString()).get('isShowWebImg').setValue(true)
          this.addBannerForm.get('banners').get(i.toString()).get('webImage').setValue(res.file.filename)
        }
        else {
          this.addBannerForm.get('banners').get(i.toString()).get('isShowAppImg').setValue(true)
          this.addBannerForm.get('banners').get(i.toString()).get('appImage').setValue(res.file.filename)
        }
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  addBanner() {
    this._BS.addBanner(this.addBannerForm.value).subscribe((res: any) => {
      if (res.success) {
        this.router.navigate(['/banner'])
        this._ALS.success(res.message)
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  getBannerDetail() {
    this._BS.getBannerDetail(this.bannerId).subscribe((res: any) => {
      if (res.success) {
        this.bannerDetail=res.banners
        // this.banner=res.banners
        this.addBannerForm.get('title').setValue(res.banners.title);
        this.addBannerForm.get('priority').setValue(res.banners.priority);
        res.banners.banners.map((_) => {
          this.addBannersToForm(_.name, _.position, _.searchKeywordWeb, _.searchKeywordApp, _.image);
        })
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  submitBanner(){
    this.addBannerForm.value.banners.map((_, i) => {
      let image = [];
      image[0] = _.webImage;
      image[1] = _.appImage;
      _.image = image;
    })
    if(this.bannerId){
      this.updateBanner()
    }
    else{
      this.addBanner()
    }
  }

  updateBanner(){
    this._BS.updateBanner({ ...this.addBannerForm.value, bannerId: this.bannerId }).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
        this.router.navigate(['/banner'])
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }
}
