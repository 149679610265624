import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AlertService } from 'src/app/_services';
import { SlotService } from 'src/app/_services/slot.service';
declare var google;

@Component({
  selector: 'app-add-slot',
  templateUrl: './add-slot.component.html',
  styleUrls: ['./add-slot.component.less']
})
export class AddSlotComponent implements OnInit {
  modalRef: BsModalRef
  disableModalRef: BsModalRef
  addSlotForm: FormGroup
  disableSlotForm: FormGroup
  areaId: any = ''
  map: any;
  area: any = {}
  slotId: any = ''
  disableSlots:any=[]
  constructor(private route: ActivatedRoute,
    private router: Router,
    private _SS: SlotService,
    private fb: FormBuilder,
    private _ALS: AlertService,
    private modalService: BsModalService) { }

  ngOnInit() {
    this.addSlotForm = this.fb.group({
      start: [],
      end: [],
      clossing: [],
      priority: [],
      orderCount: [],
      day: []
    })
    this.disableSlotForm = this.fb.group({
      startHr: [],
      endHr: [],
      startdate: [],
      enddate: [],
      message: []
    })
    this.route.queryParams.subscribe(param => {
      this.getDisableSlota(param.id);
      this.areaId = param.id
    })
    
    this.getAreaDetail()
    this.initMap()
  }
  getDisableSlota(areaId:any){
    this._SS.getDisableSlot(areaId).subscribe((res:any)=>{
      this.disableSlots = res.slots ? res.slots : []
    },(err)=>{
      this._ALS.error(err.message)
    })
  }


  initMap() {
    this.map = new google.maps.Map(document.getElementById("map"), {
      center: { lng: 77.427175, lat: 28.604375 },
      zoom: 13,
      mapTypeId: "terrain",
    });
  }

  getAreaDetail() {
    let polygons: any = []
    this._SS.getServeAreaDetail(this.areaId).subscribe((res: any) => {
      if (res.success) {
        this.area = res.data
        // this.polygonPath = res.data.servingAreas.area.coordinates
        this.area.area.coordinates.map(__ => {
          __.map(___ => {
            ___.map(____ => {
              polygons.push({ lng: ____[0], lat: ____[1] })
            })
            let polygon = new google.maps.Polygon({
              paths: polygons,
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
            });
            polygon.setMap(this.map);
            console.log(polygons)
            polygons = []
          })

        })
        this.calculateClosingTime(this.area.timeSlot)
      }
    })
  }

  createSlotPayload() {
    let sHours = this.addSlotForm.value['start'].getHours()
    let eHours = this.addSlotForm.value['end'].getHours()
    let startHours = this.changeDigit((this.addSlotForm.value['start'].getHours()) % 12 || 12)
    let startMinutes = this.changeDigit(this.addSlotForm.value['start'].getMinutes())
    let endMinutes = this.changeDigit(this.addSlotForm.value['end'].getMinutes())
    let endHours = this.changeDigit((this.addSlotForm.value['end'].getHours()) % 12 || 12)
    let startSlot = sHours >= 12 ? `${startHours}:${startMinutes}PM` : `${startHours}:${startMinutes}AM`
    let endSlot = eHours >= 12 ? `${endHours}:${endMinutes}PM` : `${endHours}:${endMinutes}AM`
    let finalSlot = `${startSlot} - ${endSlot}`;
    let slotData = {
      slot: finalSlot,
      value: {
        hr: sHours,
        min: startMinutes,
        minutes: (this.addSlotForm.value['clossing'].getHours() * 60) + (this.addSlotForm.value['clossing'].getMinutes())
      },
      priority: this.addSlotForm.value['priority'],
      day: this.addSlotForm.value['day'],
      orderCount: this.addSlotForm.value['orderCount'],
      areaId: this.areaId,
      rootAreaId: this.area._id,
      slotId:this.slotId ? this.slotId  : ''
    }
    if (this.slotId) {
      this.updateSlot(slotData)
    }
    else {
      this.addSlot(slotData)
    }
  }

  addSlot(slotData) {
    this._SS.addNewSlot(slotData).subscribe((res: any) => {
      if (res.success) {
        this._ALS.success(res.message)
        this.addSlotForm.reset()
        this.getAreaDetail()
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  changeDigit(time) {
    return (time < 10 ? '0' : '') + time
  }

  calculateClosingTime(timeSlots) {
    timeSlots.map(_ => {
      let startH = _.value.hr % 12
      if (startH == 0) {
        startH = 12
      }
      let startHour = this.changeDigit(startH);
      let slot = _.slot.split('-')
      let fisrtSlotMin = slot[0].split(':')[1]
      let endH = (slot[1].split(':')[0]) % 12
      if (endH == 0) {
        endH = 12
      }
      let endSlotMin = slot[1].split(':')[1];
      let endSlotHr = this.changeDigit(endH)
      _.slot = `${startHour}:${fisrtSlotMin} - ${endSlotHr}:${endSlotMin}`
      let closingHr = (_.value.minutes / 60);
      let closingMin = _.value.minutes % 60
      let closingAmPm = closingHr >= 12 ? 'PM' : 'AM'
      closingHr = (closingHr) % 12
      if (closingHr > 0 && closingHr < 1) {
        closingHr = 12
      }
      _.value.minutes = this.changeDigit(closingHr.toString().split('.')[0]) + ':' + this.changeDigit(closingMin) + closingAmPm
    })
  }

  openModal(template: TemplateRef<any>, slotId: any) {
    this.slotId = slotId
    this.modalRef = this.modalService.show(template, Object.assign({}))
  }

  hideModal() {
    this.modalRef.hide();
  }

  deleteSlot() {
    this._SS.deleteTimeSlot(this.areaId, this.slotId).subscribe((res: any) => {
      if (res.success) {
        this._ALS.success(res.message)
        this.slotId = ''
        this.getAreaDetail()
        this.hideModal()
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (err) => {
      this._ALS.error(err.message)
    })
  }

  getSlotDetail(id) {
    this.slotId = id
    this._SS.getTimeSlotDetail(id).subscribe((res: any) => {
      if (res.success) {
        let endDate = new Date()
        if (res.data.timeSlot.slot.split('-')[1].split(':')[1].substr(2, 3) == 'PM') {
          if (parseInt(res.data.timeSlot.slot.split('-')[1].split(':')[0]) == 12) {
            endDate.setHours(12)
          }
          else {
            endDate.setHours(parseInt(res.data.timeSlot.slot.split('-')[1].split(':')[0]) + 12)
          }
        }
        else {
          if (res.data.timeSlot.slot.split('-')[1].split(':')[0] == 12) {
            endDate.setHours(parseInt(res.data.timeSlot.slot.split('-')[1].split(':')[0]) + 12)
          }
          else {
            endDate.setHours(res.data.timeSlot.slot.split('-')[1].split(':')[0])
          }
        }
        endDate.setMinutes(res.data.timeSlot.slot.split('-')[1].split(':')[1].substring(0, 2))
        let startdate = new Date()
        startdate.setHours(res.data.timeSlot.value.hr)
        startdate.setMinutes(res.data.timeSlot.value.min)
        let closingDate = new Date()
        closingDate.setHours(res.data.timeSlot.value.minutes / 60)
        closingDate.setMinutes(res.data.timeSlot.value.minutes % 60)
        this.addSlotForm.patchValue({
          start: startdate,
          end: endDate,
          priority: res.data.timeSlot.priority,
          clossing: closingDate,
          orderCount: res.data.timeSlot.orderCount,
          day: res.data.timeSlot.day
        })
      }
    }, (err) => {
      this._ALS.error(err.message)
    })
  }

  openDisableSlot(template: TemplateRef<any>) {
    this.disableModalRef = this.modalService.show(template)
  }

  hideDisableModal() {
    this.disableModalRef.hide()
  }

  disableSlot() {
    let sHours = this.disableSlotForm.value['startHr'].getHours()
    let eHours = this.disableSlotForm.value['endHr'].getHours()
    let startHours = this.changeDigit((this.disableSlotForm.value['startHr'].getHours()) % 12 || 12)
    let startMinutes = this.changeDigit(this.disableSlotForm.value['startHr'].getMinutes())
    let endMinutes = this.changeDigit(this.disableSlotForm.value['endHr'].getMinutes())
    let endHours = this.changeDigit((this.disableSlotForm.value['endHr'].getHours()) % 12 || 12)
    let startSlot = sHours >= 12 ? `${startHours}:${startMinutes}PM` : `${startHours}:${startMinutes}AM`
    let endSlot = eHours >= 12 ? `${endHours}:${endMinutes}PM` : `${endHours}:${endMinutes}AM`
    let payload = {
      startSlot:startSlot,
      endSlot:endSlot,
      startHr:parseInt(this.disableSlotForm.value['startHr'].getHours()),
      endHr:parseInt(this.disableSlotForm.value['endHr'].getHours()),
      startdate: this.disableSlotForm.value['startdate'],
      enddate: this.disableSlotForm.value['enddate'],
      message: this.disableSlotForm.value['message'],
      areaId:this.areaId
    }
    this._SS.disableSlot(payload).subscribe((res: any) => {
      if (res.success) {
        this._ALS.success(res.message)
        this.getDisableSlot()
        this.hideDisableModal()
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (err) => {
      this._ALS.error(err.message)
    })
  }

  updateSlot(payload){
    this._SS.updateTimeSlot(payload).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
        this.addSlotForm.reset()
        this.slotId=''
      }
      else{
        this._ALS.warn(res.message)
      }
    },(err)=>{
      this._ALS.error(err.message)
    })
  }

  getDisableSlot(){
    this._SS.getDisableSlot(this.areaId).subscribe((res:any)=>{
      this.disableSlots = res.slots ? res.slots : []
    },(err)=>{
      this._ALS.error(err.message)
    })
  }

  cancelSlot(id){
    this._SS.cancelDisableSlot(id).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
        this.getDisableSlot()
      }
      else{
        this._ALS.warn(res.message)
      }
    },(err)=>{
      this._ALS.error(err.message)
    })
  }
}
