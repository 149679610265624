import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  images: any = [];
  imageResponse: any = [];

  model: any = {
    category: {
      categoryId: '',
      categoryName: ''
    },
    seo: {canonical: '', metaTitle: '', metaDescription: '', metaKeywords: []},
    productDetails: {
      brand: '',
      productName: '',
      sku: '',
      mbSku: '',
      skuDescription: '',
      mrp: '',
      price: '',
      sizeUnit: '',
      hsnCode: '',
      gstDesc: '',
      gst: '',
      shortDesc: '',
      description: '',
      date: Date,
      size: {
        length: '',
        width: '',
        height: '',
      },
      isSubscription: false,
      isOrder: true,
      isLastMinuteBuy: false
    },
    shipping: {
      dimensionsUnit: '',
      dimensions: {
        length: '',
        width: '',
        height: '',
      },
    },
    productAttributes: {},
    images: [],
  };
  localImages: any = [];
  isSelectCat = true;
  isAddDetail = false;
  isImageUpload = false;
  isSubmit = false;

  constructor(private http: HttpClient) { }
   getDashBoardData(params) {
    return this.http.get(environment.apiURL + 'dashboard', { params: params });
   }

  // remove any image
  removedImage(path: any) {
    return this.http.post(environment.apiURL + `remove/image`, path);
  }

  getRootCategory() {
    return this.http.get(environment.apiURL + 'category/tree');
  }
  getMostBuy() {
    return this.http.get(environment.apiURL + 'mostbuy/prod?cityId=619f219d26d9ad0f34102dd2');
  }
  getleafNode(categoryId: any) {
    return this.http.get(environment.apiURL + `check/leaf?categoryId=${categoryId}`);
  }
  getSubCategory(id: any) {
    return this.http.get(environment.apiURL + `subcategory?categoryId=${id}`);
  }
  getBrands() {
    return this.http.get(environment.apiURL + 'brand');
  }

  getSubBrands(brandId:any){
    return this.http.get(environment.apiURL+`subbrand/${brandId}`)
  }

  getAllProducts(page,limit,filter){
    return this.http.get(environment.apiURL+`catalogue/products?page=${page}&limit=${limit}`,{params:filter})
  }

  getFoodPreferences() {
    return this.http.get(environment.apiURL + 'food-preferences');
  }
  saveNewProduct(body: any) {
    return this.http.post(environment.apiURL + 'product/save', body);
  }
  draftNewProduct(body: any) {
    return this.http.post(environment.apiURL + 'product/draft', body);
  }
  getAttributes(categoryId: any) {
    return this.http.get(environment.apiURL + `attribute?categoryId=${categoryId}`);
  }
  getSearchProduct(product: any) {
    return this.http.get(environment.apiURL + `product/search?value=${product}`);
  }
  addProductToList(productId: any, categoryId: any) {
    return this.http.post(environment.apiURL + 'add/existing/product', { productId: productId, categoryId: categoryId });
  }
  addCategoryImage(imageBody: any) {
    return this.http.post(environment.apiURL + 'image/save', imageBody);
  }
  submitProductDetails(body: any) {
    return this.http.post(environment.apiURL + 'product/request', body);
  }
  productStockUpdate(productsUpdateDetail: any) {
    return this.http.post(environment.apiURL + 'product/update', productsUpdateDetail);
  }
  productStock(sellerUpdatedProducts: any) {
    return this.http.post(environment.apiURL + 'products/update', sellerUpdatedProducts);
  }
  deactiveProduct(updateData: any) {
    return this.http.post(environment.apiURL + 'product/active/deactive', updateData);
  }
  activeProduct(updateData: any) {
    return this.http.post(environment.apiURL + 'product/active/deactive', updateData);
  }

  getSellerProducts(filter: any,page, limit) {
    return this.http.get(environment.apiURL+`product/stock/list?page=${page}&limit=${limit}`,{params:filter})
  }
  getSellerProductss() {
    return this.http.get(environment.apiURL+`product/stock/list?sort=name&order=inc`)
  }

  getHsn() {
  return this.http.get(environment.apiURL + 'hsncode');
  }

  getProductDetail(productId:any){
    return this.http.get(environment.apiURL+`product/${productId}`)
  }

  updateProductMargin(subbrandId,marginData){
    return this.http.put(environment.apiURL+`updatemargin/${subbrandId}`,marginData)
  }

  updateProductByExcel(productData){
    return this.http.post(environment.apiURL+`product/update/excel`,productData)
  }
}
