import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthenticationService, UserService } from '../../_services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})

export class HeaderComponent implements OnInit {
  currentuserInfo: any;
  modalRef: BsModalRef;
  message: string;
  userNotifications: any = [];
  constructor(
    public _AS: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    this.userNotification();
    this.currentuserInfo = JSON.parse(localStorage.getItem('currentUser'));
  }

  logOutUser(logoutUser: TemplateRef<any>) {
    this.modalRef = this.modalService.show(logoutUser, { class: 'modal-sm' });
  }

  userNotification() {
    let notification;
    this.userService.userNotification().subscribe(data => {
      notification = data;
      if (notification.success) {
        this.userNotifications = notification.list;
      }
    });
  }

  logOut() {
    this.message = 'Confirmed!';
    this.modalRef.hide();
    this._AS.logOut();
  }
  openModal(temp) {
    console.log('Modal');
  }
}
