import { Component, OnInit } from '@angular/core';
import { PurchaseService, AlertService } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-purchase-order-detail',
  templateUrl: './purchase-order-detail.component.html',
  styleUrls: ['./purchase-order-detail.component.less']
})
export class PurchaseOrderDetailComponent implements OnInit {

  purchaseOrderDetail: any;
  params: any;
  isPurchaseOrderAccepted: any;
  purchaseRequestDetails:any=[];

  constructor(private _PS: PurchaseService,
    private _ALS: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      let orderId = params.orderId;
      this.getPurchaseOrderdetail(orderId);
    });
  }

  // getPurchaseOrderdetail(id) {
  //   let tempPurchaseOrderDetail;
  //   this._PS.getPurchaseOrderDetails(id).subscribe(data => {
  //     tempPurchaseOrderDetail = data;
  //     if (tempPurchaseOrderDetail.list.status === 'accepted') {
  //       this.isPurchaseOrderAccepted = true;
  //     } else {
  //       this.isPurchaseOrderAccepted = false;
  //     }
  //     if (tempPurchaseOrderDetail.success) {
  //       tempPurchaseOrderDetail.list.products.forEach(_ => {
  //         _.isbarcodeMatched = false;
  //       });
  //       this.purchaseOrderDetail = tempPurchaseOrderDetail;
  //     } else {
  //       this._ALS.warn(tempPurchaseOrderDetail.message);
  //     }
  //   }),
  //   error => {
  //     this._ALS.error(error.error && error.error.message ? error.error.message : error.message);
  //   };
  // }

  acceptPurchaseOrder(id) {
    let tempAcceptPurchaseOrderData;
    this._PS.acceptPurchaseOrder({ purchaseOrderId: id, supplierId: this.purchaseOrderDetail.list.supplier._id,  products: this.purchaseOrderDetail.list.products }).subscribe(data => {
      tempAcceptPurchaseOrderData = data;
      if (tempAcceptPurchaseOrderData.success) {
        this.router.navigate(['/purchase/order/list']);
        this._ALS.success(tempAcceptPurchaseOrderData.message);
      } else {
        this._ALS.warn(tempAcceptPurchaseOrderData.message);
      }
    }),
    error => {
      this._ALS.error(error.error && error.error.message ? error.error.message : error.message);
    };
  }

  matchBarcode(value, id) {
    this.purchaseOrderDetail.list.products.forEach(_ => {
      if (_._id === id && _.barCode === value) {
        _.isbarcodeMatched = true;
      }
    });
  }

  acceptedQuantity(value, id) {
    this.purchaseOrderDetail.list.products.forEach(_ => {
      if (_._id === id) {
        _.acceptedQuantity = value;
      }
    });
  }

  acceptedProductExpiry(value, id) {
    const mfdDate = moment(value, 'DD/MM/YYYY').format();
    if (mfdDate == 'Invalid date') {
      this._ALS.warn('Invalid date, please enter the product manufactured date again');
    } else {
      this.purchaseOrderDetail.list.products.forEach(_ => {
        if (_._id === id) {
          _.acceptedProductExpiry = mfdDate;
        }
      });
    }
  }

  getPurchaseOrderdetail(orderId:any){
    this._PS.getPurchaseOrderDetail(orderId).subscribe((purchaseOrder:any)=>{
      this.purchaseRequestDetails = purchaseOrder.purchaseRequestDetails ? purchaseOrder.purchaseRequestDetails:[];
    })
  }
}
