import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../../../_services';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.less']
})
export class TransactionComponent implements OnInit {
  transactionData: any = {};
  searchedValue: any;

  constructor(public _TS: TransactionService) { }

  ngOnInit() {
    this.getTransaction();
  }

  getTransaction() {
    this._TS.getTransaction().subscribe(data => {
      this.transactionData = data;
    });
  }

  searchBy(value) {
    this.searchedValue = value;
  }

}
