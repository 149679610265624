import { Component, OnInit, ViewChild } from '@angular/core';
import { GoodsService, AlertService } from 'src/app/_services';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { TabsetComponent } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-goods-receive-list',
  templateUrl: './goods-receive-list.component.html',
  styleUrls: ['./goods-receive-list.component.less']
})
export class GoodsReceiveListComponent implements OnInit {
  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  grnList: any = [];
  poDetail: any;
  products: any = [];
  recievedProduct: any = {};
  recieveGrandTotal: any = 0;
  totalRecievedItem: any = 0;
  invoice:any;
  isShowRecieveQty: boolean = false;
  isGrnSubmitted: boolean = false;
  purchaseOrderId: FormControl;
  grnRecieveQtyForm: FormGroup;
  grnSubmitForm: FormGroup;
  grnInvoiceForm: FormGroup;
  invoiceUrl: any;
  minExpDate:Date;

  constructor(private fb: FormBuilder,
    private _GS: GoodsService,
    private _ALS: AlertService,
    private route:ActivatedRoute,
    private router:Router
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(param=>{
      let tabId=param.id;
      this.selectTab(tabId)
      if(tabId==1){
        this.getGrnList();
      }
    })
    this.purchaseOrderId = new FormControl('', Validators.required);
    this.grnRecieveQtyForm = this.fb.group({
      barCode: ['', Validators.required],
      recieveQty: ['', Validators.required],
      expDate: ['', Validators.required],
      mfgDate: ['', Validators.required]
    })
    this.grnInvoiceForm = this.fb.group({
      invoiceNumber: ['', Validators.required],
      invoiceDate: ['', Validators.required],
      invoiceImage:['',Validators.required]
    })
    this.recievedProduct = {}
  }

  getGrnList() {
    this._GS.getGoodsRecieveList().subscribe((grn: any) => {
      if (grn.success) {
        this.grnList = grn.grnList;
      }
      else {
        this._ALS.warn(grn.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  getGrnByPoId() {
    if (this.purchaseOrderId.valid) {
      this._GS.getGrnByPoId(this.purchaseOrderId.value).subscribe((res: any) => {
        if (res.success) {
          this.purchaseOrderId.reset();
          this.poDetail = res.poDetails;
          this.products = res.poDetails.products;
          this.products.map(_ => {
            this.recievedProduct[_.barcode] = _;
          })
          this._ALS.success(res.message);
          this.grnInvoiceForm.reset();
          this.grnRecieveQtyForm.reset();
          this.purchaseOrderId.reset();
          this.invoiceUrl=''
        }
        else {
          this._ALS.warn(res.message)
        }
      }, (error) => {
        this._ALS.error(error.message);
      })
    }
    else {
      this.purchaseOrderId.markAsTouched();
    }
  }

  addRecieveOty() {
    let barCode = this.grnRecieveQtyForm.value['barCode'].trim();
    if (this.recievedProduct[barCode] != null) {
      if(this.recievedProduct[barCode].purchaseQuantity == this.recievedProduct[barCode].GRNQuantityReceived){
        this._ALS.warn("Grn already completed of the given product barcode")
        return ;
      }
      else if((this.recievedProduct[barCode].purchaseQuantity-this.recievedProduct[barCode].GRNQuantityReceived) < this.grnRecieveQtyForm.value['recieveQty']){
        this._ALS.warn("Received Quantity can't Exceed the Purchase Quantity")
        return ;
      }
      this.isShowRecieveQty = true;
      if (this.recievedProduct[barCode].recieveQty > this.grnRecieveQtyForm.value['recieveQty'] || this.recievedProduct[barCode].recieveQty < this.grnRecieveQtyForm.value['recieveQty'] ) {
        this.recieveGrandTotal -= this.recievedProduct[barCode].recieveTotal
        this.totalRecievedItem -= this.recievedProduct[barCode].recieveQty
      }
      this.recievedProduct[barCode].recieveQuantity = this.grnRecieveQtyForm.value['recieveQty']
      this.recievedProduct[barCode].expDate = this.grnRecieveQtyForm.value['expDate']
      this.recievedProduct[barCode].mfgDate = this.grnRecieveQtyForm.value['mfgDate']
      this.recievedProduct[barCode].recieveQty = this.grnRecieveQtyForm.value['recieveQty']
      this.recievedProduct[barCode].recieveTotal = this.grnRecieveQtyForm.value['recieveQty'] * (this.recievedProduct[barCode].unitPrice * ((1 + 2 * (this.recievedProduct[barCode].CGST) * 0.01) + ((this.recievedProduct[barCode].IGST) * 0.01)-(this.recievedProduct[barCode].CESS * 0.01)))
      this.recieveGrandTotal += this.recievedProduct[barCode].recieveTotal;
      this.totalRecievedItem += this.recievedProduct[barCode].recieveQty;
      this.grnRecieveQtyForm.reset();
    }
  }

  uploadInvoice(event){
    let imageFile=event.target.files[0];
    let formData=new FormData();
    formData.append('image',imageFile)
    this._GS.uploadInvoice(formData).subscribe((res:any)=>{
      if(res.success){
        this.invoice=res.file.filename;
        this._ALS.success(res.message);
        this.invoiceUrl=`${environment.apiURL}public/temp/${res.file.filename}`
      }
      else{
        this._ALS.warn(res.message);
        this.invoice='';
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  submitGrn() { 
    if(this.grnInvoiceForm.valid){
      let recieveProduct:any=[]
      this.products.map(_=>{
        if(_.recieveQty){
          let product= {
            productId:_.productId,
           productName:_.productName, 
           GRNQuantity:_.recieveQty,
            exp:_.expDate,
            mfg:_.mfgDate
          }
          recieveProduct.push(product);
        }
      })
      let grnData={
        products:recieveProduct,
        purchaseOrderId:this.poDetail._id,
        supplierQutotionId:this.poDetail.supplierQuotationId,
        invoiceNumber:this.grnInvoiceForm.value['invoiceNumber'],
        invoiceDate:this.grnInvoiceForm.value['invoiceDate'],
        invoiceImages:[this.invoice]
      }
      this.isGrnSubmitted=true;
      this._GS.submitGrn(grnData).subscribe((res:any)=>{
        this.isGrnSubmitted=false
        if(res.success){
          this.poDetail=''
          this._ALS.success(res.message)
        }
        else{
          this._ALS.warn(res.message)
        }
      },(error)=>{
        this.isGrnSubmitted=false;
        this._ALS.error(error.message)
      })
    }
    else{
      this._ALS.warn("Please Enter All Invoice Details")
    }
  }

  downloadGrnDetail(grnId:any){
    this._GS.getgoodsRecieveDetail(grnId).subscribe((res:any)=>{
      if(res.success){
        this._GS.printGrnDetail(res.grnDetails)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  } 

  setExpMinDate(){
    this.minExpDate=new Date();
    this.minExpDate.setDate((this.grnRecieveQtyForm.value['mfgDate']).getDate()+1)
    console.log(this.grnRecieveQtyForm.value)
  }

  setSelectedTab(id){
    this.router.navigate(['goods/receive/list'],{queryParams:{id:id}})
  }

  selectTab(id){
    this.staticTabs.tabs[id].active=true
  }
}
