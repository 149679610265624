import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/_services';
import { StockAuditService } from 'src/app/_services/stock-audit.service';

@Component({
  selector: 'app-audit-history-detail',
  templateUrl: './audit-history-detail.component.html',
  styleUrls: ['./audit-history-detail.component.less']
})
export class AuditHistoryDetailComponent implements OnInit {

  auditId: any;
  auditProducts: any = []
  auditCount:any=0
  param:any={}
  constructor(private route: ActivatedRoute,
    private _SA: StockAuditService,
    private _ALS: AlertService,
    private router:Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(param => {
      this.auditId = param.id
    })
    this.getAuditDetail()
  }

  getAuditDetail() {
    this._SA.getStockAuditDetail(this.auditId,this.param).subscribe((res: any) => {
      if (res.success) {
        this.auditProducts = res.data
        this.checkAudit()
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  updateExpiryBatch(product) {
    let products = product.audit.map(_ => {
      return {
        exp: _.exp,
        mfg: _.mfg,
        quantity: _.quantity
      }
    })
    this._SA.updateExpiryBatch(this.auditId,{productId:product._id, expiryBatch:products}).subscribe((res: any) => {
      if(res.success){
        this._ALS.success(res.message)
        product.isAudit=true
        this.checkAudit()
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  closeAudit(){
    this._SA.closeAudit(this.auditId).subscribe((res:any)=>{
      if(res.success){
        this.router.navigate(['/audit-report'])
        this._ALS.success(res.message)
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  setParam(value){
    if(value == 'expire'){
      this.param.isExpired=true
    }
    else{
      this.param={}
    }
    this.getAuditDetail()
  }

  checkAudit(){
    this.auditCount=0
    this.auditProducts.map(_=>{
      if(_.isAudit){
        this.auditCount++;
      }
    })
  }
}
