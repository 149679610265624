import { Component, OnInit } from '@angular/core';
import { AlertService, OrderService } from 'src/app/_services';

@Component({
  selector: 'app-bulk-order',
  templateUrl: './bulk-order.component.html',
  styleUrls: ['./bulk-order.component.less']
})
export class BulkOrderComponent implements OnInit {

  constructor(
    private _OS: OrderService,
    private _ALS: AlertService,
  ) { }

  ngOnInit() {
    this.getAllDeliveryBoys();
  }
  bulkOrderList: any;
  getAllDeliveryBoys() {
    this._OS.getBulkOrders().subscribe((res: any) => {
      this.bulkOrderList = res.data ? res.data : []
      console.log('bulkOrderList',this.bulkOrderList);
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

}
