import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  TemplateRef
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  Router,
  ActivatedRoute,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError
} from '@angular/router'
import { OrderService, AlertService } from '../../../_services'

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.less']
})
export class OrderDetailComponent implements OnInit {
  @ViewChild('focus') public focus: ElementRef<any>
  modalRef: BsModalRef;
  order: any
  orderDetail: any = []
  categoryIds: any = []
  uniqueIds: any
  products: any
  orderDetailModel: any
  orderAddress: any = {}
  isBarcodeVerified = false
  isOrderConfirm = false
  isDisPetch = false
  orderDetailProducts: any = []
  categoryDetail: any = []
  orderId: any
  orderAmount: any
  orderProducts: any = []
  orderProductLength: any = []
  scanQuantity = 0
  pendingOrderConfirmData: any
  dispatchedOrderConfirmData: any = {}
  orderProductTrue: any = []
  quantity = 0
  scanProduct: any = []
  barCodeCheck: any = []
  isProdctCountMax = false
  isOrderFound = true
  queryParams: any

  constructor (
    private _OS: OrderService,
    private _ALS: AlertService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
  ) {
    // this.route.events.subscribe((event: Event) => {
    //   if (event instanceof NavigationStart) {
    //     if (event.url.includes('?') && event.url.split('/').length > 2) {
    //       this.getOrderDetail(this.sortParams(event.url.split('/')[2].split('?')[1]));
    //     }
    //   }
    // });
  }

  ngOnInit () {
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params
      if (this.queryParams.id) {
        this.getOrderDetail(this.queryParams)
      }
    })
  }

  sortParams (link) {
    const params = link.split('&')
    let pair = null
    const data = {}
    params.forEach(d => {
      pair = d.split('=')
      data[`${pair[0]}`] = pair[1]
    })
    return data
  }

  getOrderDetail (data) {
    let orderDetail: any
    if (data.id) {
      this.isOrderFound = true
      this._OS.orderDetail(data.id).subscribe(data => {
        orderDetail = data
        if (orderDetail.success) {
          this.orderDetail = orderDetail
          this.orderAddress = this.orderDetail.order
          if (this.orderAddress.status === 'pending') {
            this.order = {
              ...this.order,
              isShowBarcode: 'true',
              isShowConfirm: 'true'
            }
            setTimeout(() => {
              this.focus.nativeElement.focus()
            }, 100)
          } else if (this.orderAddress.status === 'confirmed') {
            this.order = {
              ...this.order,
              isShowBarcode: 'true',
              isShowDispatch: 'true'
            }
            setTimeout(() => {
              this.focus.nativeElement.focus()
            }, 100)
          }
          this.orderProducts = this.orderAddress.products
          this.orderProductLength = this.orderProducts.length
          this.orderProducts = this.orderProducts.map(_ => {
            _.isProduct = false
            _.isProdctCountMax = false
            _.isProductNotAvailable = false
            _.scanProductQuantity = 0
            return _
          })
        } else {
          this.isOrderFound = false
        }
      })
    } else {
      this.isOrderFound = false
    }
  }

  confirmProduct (ProductBarCode: any) {
    let isFindBarcode = false
    this.orderProducts.every(_ => {
      if (_.barCode === ProductBarCode) {
        isFindBarcode = true
        return false
      } else {
        return true
      }
    })
    if (isFindBarcode && this.scanProduct.length > 0) {
      this.scanProduct.every(_ => {
        if (ProductBarCode === _.barCode) {
          // this.quantity += 1;
          _.quantity++
          // isFindBarcode = true;
          return false
        } else {
          return true
        }
      })
      if (!isFindBarcode) {
        this.scanProduct.push({
          barCode: ProductBarCode,
          quantity: 1
        })
      } else {
        const found = this.scanProduct.find(_ => {
          return ProductBarCode == _.barCode
        })
        if (!found) {
          this.scanProduct.push({
            barCode: ProductBarCode,
            quantity: 1
          })
        }
      }
    } else {
      if (isFindBarcode) {
        this.scanProduct.push({
          barCode: ProductBarCode,
          quantity: 1
        })
      } else {
        this._ALS.error('Product do not match with order list.')
      }
    }
    this.orderProducts.every(_ => {
      this.scanProduct.every(__ => {
        if (_.barCode == __.barCode && _.quantity == __.quantity) {
          _.isProduct = true
          _.scanProductQuantity = __.quantity
          return false
        }
        if (_.barCode === __.barCode && __.quantity < _.quantity) {
          _.scanProductQuantity = __.quantity
        } else {
          if (_.barCode === __.barCode && __.quantity > _.quantity) {
            _.isProdctCountMax = true
            _.scanProductQuantity = __.quantity
            if (_.barCode === ProductBarCode) {
              this._ALS.warn('Product quantity is greater than order quantity')
            } else {
              this._ALS.error('Product do not match with order list.')
            }
          }
          _.isProduct = false
          return true
        }
      })
      return true
    })
    this.orderDetailModel = ''
    this.orderDispatch()
    this.scanQuantity = this.scanProduct.quantity
  }

  orderDispatch () {
    this.orderProductTrue = this.orderProducts.filter(_ => {
      if (_.isProduct === true) {
        return _
      }
    })
    if (this.orderProductLength === this.orderProductTrue.length) {
      this.isBarcodeVerified = true
    } else {
      this.isBarcodeVerified = false
    }
  }

  decreaseProductQuatnity (ProductBarCode) {
    this.orderProducts.map(_ => {
      if (ProductBarCode === _.barCode) {
        this.scanProduct.map(__ => {
          if (__.quantity > _.quantity && ProductBarCode === __.barCode) {
            __.quantity -= 1
            _.scanProductQuantity = __.quantity
            if (__.quantity === _.quantity) {
              _.isProdctCountMax = false
              _.isProduct = true
            }
          }
        })
      }
    })
    this.orderDispatch()
  }

  dispatchedOrderconfirm () {
    const dispatchDetail: any = {
      orderId: this.orderDetail.order._id,
      state: 'active',
      status: 'dispatched'
    }
    this._OS.confirmDispatchedOrder(dispatchDetail).subscribe(data => {
      this.dispatchedOrderConfirmData = data
      if (this.dispatchedOrderConfirmData.success) {
        this.route.navigate(['/orders'], { queryParams: { id: 1 } })
        this._ALS.success(this.dispatchedOrderConfirmData.message)
      } else {
        this._ALS.warn(this.dispatchedOrderConfirmData.message)
      }
    })
  }

  pendingOrderConfirm () {
    const pendingOrderDetail: any = {
      orderId: this.orderDetail.order._id,
      state: 'active',
      status: 'confirmed'
    }
    this._OS.confirmPendingOrder(pendingOrderDetail).subscribe(data => {
      this.pendingOrderConfirmData = data
      if (this.pendingOrderConfirmData.success) {
        this._ALS.success(this.pendingOrderConfirmData.message)
        this.route.navigate(['/orders'], { queryParams: { id: 1 } })
      } else {
        this._ALS.warn(this.pendingOrderConfirmData.message)
      }
    })
  }

  outOfStock (id: any) {
    let response,
      model = { productId: '', orderId: '' }
    model.orderId = this.orderDetail.order._id
    model.productId = id
    this._OS.outOfStocks(model).subscribe(data => {
      response = data
      if (response.success) {
        this.isBarcodeVerified = true
        if (this.orderDetail.order.status == 'pending') {
          this.order = {
            ...this.order,
            isShowBarcode: 'true',
            isShowConfirm: 'true'
          }
        } else {
          this.order = {
            ...this.order,
            isShowBarcode: 'true',
            isShowDispatch: 'true'
          }
        }
      }
    })
  }
  openoutofstockModal(outofstock: TemplateRef<any>) {
    this.modalRef = this.modalService.show(outofstock,
      Object.assign({}, { class: 'modal-lg' })
    );
  }
}