import { Component, OnInit ,TemplateRef} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, DeliveryBoyService } from 'src/app/_services';
@Component({
  selector: 'app-delivery-boy-list',
  templateUrl: './delivery-boy-list.component.html',
  styleUrls: ['./delivery-boy-list.component.less']
})
export class DeliveryBoyListComponent implements OnInit {
  modalRef: BsModalRef;
  addDeliveryBoyForm:FormGroup
  deliveryBoys: any;
  boyId:any
  constructor(
    private modalService: BsModalService,
    private _DS: DeliveryBoyService,
    private fb:FormBuilder,
    private _ALS:AlertService
    ) { }

  ngOnInit() {
   this.addDeliveryBoyForm= this.fb.group({
      email:[],
      password:[],
      phoneNo:[],
      name:[],
      drivingLicNo:[]
    })
    this.getAllDeliveryBoys() 
  }

  openModal(template: TemplateRef<any>,boyData) {
    if(boyData){
      this.boyId=boyData._id
      this.addDeliveryBoyForm.patchValue(boyData)
    }
    else{
      this.addDeliveryBoyForm.reset()
    }
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-lg' })
      );
  }

  getAllDeliveryBoys(){
    this._DS.getDeliveryBoys().subscribe((res:any)=>{
      this.deliveryBoys=res.data ? res.data : []
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  addDeliveryBoy(){
    if(this.boyId){
      this.updateBoyData("")
    }
    else{
      this._DS.addDeliveryBoy(this.addDeliveryBoyForm.value).subscribe((res:any)=>{
        if(res.success){
          this._ALS.success(res.message)
          this.getAllDeliveryBoys()
          this.modalRef.hide()
        }
        else{
          this._ALS.warn(res.message)
        }
      },(error)=>{
        this._ALS.error(error.message)
      })
    }
  }

  changeStatus(event,boyId){
    this.boyId=boyId
    let status:any={};
    if(event.target.checked){
      status.isActive=true
    }
    else{
      status.isActive=false
    }
    this.updateBoyData(status)
  }

  updateBoyData(updateData){
    let updatePayload:any={}
    if(updateData){
      updatePayload=updateData
    }
    else{
      let controls=Object.keys(this.addDeliveryBoyForm.controls);
      controls.map(_=>{
        if(this.addDeliveryBoyForm.controls[_].dirty){
          updatePayload[_]=this.addDeliveryBoyForm.controls[_].value
        }
      })
    }
   this._DS.updateDeliveryBoy(this.boyId,updatePayload).subscribe((res:any)=>{
     if(res.success){
       this._ALS.success(res.message)
       this.boyId=''
       this.addDeliveryBoyForm.reset()
        this.modalRef.hide()
       this.getAllDeliveryBoys()
     }
     else{
       this._ALS.warn(res.message)
     }
   },(error)=>{
     this._ALS.error(error.message)
   })
  }
}
