import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AlertService } from 'src/app/_services';
import { OfferService } from 'src/app/_services/offer.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-add-new-promo-code',
  templateUrl: './add-new-promo-code.component.html',
  styleUrls: ['./add-new-promo-code.component.less']
})
export class AddNewPromoCodeComponent implements OnInit {

  addPromocodeForm:FormGroup
  suggestUsers:any=[]
  minDate: Date;
  maxDate: Date;
  newDate: Date;
  offerId:any=''
  searchSubject=new Subject()
  public offerHeading = ClassicEditor
  public termsCondition=ClassicEditor

  constructor(private fb:FormBuilder,
    private _OS:OfferService,
    private _ALS:AlertService,
    private route:ActivatedRoute,
    private router:Router) {
    this.minDate = new Date();
    this.maxDate = new Date();
   }

  ngOnInit() {
    this.route.queryParams.subscribe(param=>{
      this.offerId=param.id
      if(this.offerId){
        this.getPromoCodeDetail()
      }
    })
    this.addPromocodeForm=this.fb.group({
      name:[],
      maxDiscount:[],
      discount: [],
      promocode:[],
      useCount: [],
      minOrderPrice: [],
      startDate: [],
      expireDate: [],
      isPercent: [true],
      offerType: ['offer'],
      refralSenderAmount: [''],
      refralRecieverAmount: [''],
      refralUser: [''],
      offerUserType: ['newuser'],
      type: ['cashback'],
      description: [''],
      cartDescription: ['']
    })

    this.searchSubject.pipe(
      debounceTime(500)
    ).subscribe(searchTextValue => {
      this.searchUser(searchTextValue)
    });
  } 

  get offerType() { return this.addPromocodeForm.get('offerType').value; }

  addPromoCode(){
    this._OS.addPromoCode(this.addPromocodeForm.value).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
        this.router.navigate(['/promo-code'])
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  selectDate(startDate) {
    if (startDate) {
      this.maxDate = startDate;
    } else {
      this.maxDate = new Date();
    }
    this.addPromocodeForm.controls['expireDate'].setValue(this.newDate);
    this.maxDate.setDate(this.maxDate.getDate() + 1);
  }

  onUserKey(event: any) { 
    event.target.value
    this.searchSubject.next(event.target.value);
  }

  searchUser(userValue) {
    this._OS.searchUser(userValue).subscribe((res:any)=>{
      this.suggestUsers=res.users ? res.users : []
    })
  }

  selectUser(user) {
    this.addPromocodeForm.controls["refralUser"].setValue(user._id);
    this.suggestUsers = [];
  }

  getPromoCodeDetail(){
    this._OS.getPromoCodeDetail(this.offerId).subscribe((res:any)=>{
      if(res.success){
        this.addPromocodeForm.patchValue(res.data)
        if(res.data.offerType == "affiliate"){
          this.addPromocodeForm.get('refralSenderAmount').setValue(res.data.refralAmount.senderAmount)
          this.addPromocodeForm.get('refralRecieverAmount').setValue(res.data.refralAmount.recieverAmount)
        }
       }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  updatePromoCode(){
    this._OS.updatePromoCode(this.offerId,this.addPromocodeForm.value).subscribe((res:any)=>{
      if(res.success){
        this.router.navigate(['/promo-code'])
        this._ALS.success(res.message)
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }
}
