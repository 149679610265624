import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BuyerService } from 'src/app/_services';

@Component({
  selector: 'app-puschase-history-detail',
  templateUrl: './puschase-history-detail.component.html',
  styleUrls: ['./puschase-history-detail.component.less']
})
export class PuschaseHistoryDetailComponent implements OnInit {
  id:any;
  purchaseOrderDetail:any={};

  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private _BS:BuyerService
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params:any)=>{
      if(params.id){
        this.id = params.id;
        this.getPurchaseOrderDetail();
      }
    })
  }

  getPurchaseOrderDetail(){
    this._BS.getPurchaseOrderDetails(this.id).subscribe((response:any)=>{
      this.purchaseOrderDetail = response.poDetails ? response.poDetails:{};
    })
  }
}

