import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient }    from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadImageService {

  constructor(private http: HttpClient) { }

  uploadImage(imageData) {
    return this.http.post(environment.apiURL + '/image/save', imageData);
  }
}
