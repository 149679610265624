import { Component, OnInit } from '@angular/core';
import { StockService, AlertService, ProductService } from '../_services';
import { FormControl, Validators } from '@angular/forms';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { CategoryService } from '../_services/category.service';

@Component({
  selector: 'app-stock-slot-report',
  templateUrl: './stock-slot-report.component.html',
  styleUrls: ['./stock-slot-report.component.less']
})
export class StockSlotReportComponent implements OnInit {

  date: FormControl
  products: any = [];
  isDisableDownload: boolean = false;
  rootCatList: any = [];
  filter: any = {};
  subCatList: any = [];
  brandList: any = [];
  leafCatList: any = [];
  subBrandList: any = [];
  slots: any = [];
  slotForm:any;

  constructor(
    private _SS: StockService,
    private _ALS: AlertService,
    private _PS: ProductService,
    private _CS:CategoryService
  ) { 
    this.slotForm = new FormControl('');
  }

  ngOnInit() {
    this.date = new FormControl('', Validators.required);
    this.getCategoryTree();
    this.getBrands()
    this.getSlots();
  }

  getCategoryTree() {
    this._CS.getSellerActiveCatTree().subscribe((cat: any) => {
      this.rootCatList = cat.tree ? cat.tree : [];
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  getSlots() {
    this._SS.getSlot().subscribe((response: any) => {
      this.slots = response.data ? response.data : [];
    })
  }

  getSubCategory(catId, value) {
    if (value === 'rootCat') {
      this.filter.rootCatId = catId;

      this.rootCatList.filter(_ => {
        if (_._id == catId) {
          this.subCatList = _.children;
        }
      })
    }
    else if (value === 'subCat') {
      this.filter.subCatId = catId;
      this.subCatList.filter(_ => {
        if (_._id == catId) {
          this.leafCatList = _.children;
        }
      })
    }
    else {
      this.filter.leafCatId = catId;
    }
  }

  getBrands() {
    this._PS.getBrands().subscribe((brand: any) => {
      this.brandList = brand.brands ? brand.brands : [];
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  getSubBrands(brandId: any) {
    if (brandId != "") {
      this.filter.brandId = brandId
      this._PS.getSubBrands(brandId).subscribe((subBrand: any) => {
        this.subBrandList = subBrand.subbrand ? subBrand.subbrand : []
      }, (error) => {
        this._ALS.error(error.message)
      })
    }else{
      delete this.filter.brandId;
    }
  }

  setLeafCatId(leafCatId: any) {
    this.filter.leafCatId = leafCatId;
  }

  setSubBrandId(subBrandId: any) {
    if(subBrandId!=""){
      this.filter.subBrandId = subBrandId
    }else{
      delete this.filter.subBrandId;
    }
  }

  setSlot() {
    if (this.slotForm.value.length == 0) {
      delete this.filter["slot"];
    } else {
      this.filter["slot"] = this.slotForm.value;
    }
    this.generateStockReport()
  }

  generateStockReport() {
    if (this.date.valid) {
      this._SS.getSlotStockReport(this.date.value, this.filter).subscribe((report: any) => {
        if (report.success) {
          this.products = report.products;
        }
      }, (error) => {
        this._ALS.error(error.message)
      })
    }
    else {
      this._ALS.warn('Date is Required to Generate Report')
    }
  }

  // downloadReport() {
  //   if (this.products.length > 0) {
  //     this.isDisableDownload = true;
  //     let data = this.products.map(_ => {
  //       return {
  //         name: _.name,
  //         quantity: _.quantity,
  //         unit: _.unit,
  //       }
  //     })
  //     const replacer = (key, value) => value === null ? '' : value;
  //     const header = Object.keys(data[0]);
  //     let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
  //     csv.unshift(header.join(','));
  //     let csvArray = csv.join('\r\n');
  //     var blob = new Blob([csvArray], { type: 'text/csv' })
  //     saveAs(blob, `Stock Report Date-${this.date.value}.csv`);
  //     this.isDisableDownload = false;
  //   }
  //   else {
  //     this._ALS.warn('No Product Found')
  //   }
  // }

  downloadReport(){
    let date=new Date(this.date.value)
    const doc = new jsPDF()
    doc.text(75,15,`Date:- ${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`);
    doc.autoTable({html: '#report-table',
    theme:'grid',
    startY:20,
    headStyles:{ cellWidth:'wrap'},
    margin: {left:10,right:10},
   })
   doc.save('stock-slot-report.pdf')
  }
  
  removeFilter() {
    this.filter = {};
    this.subBrandList = [];
    this.subCatList = [];
    this.leafCatList = [];
    this.slotForm.setValue('');
    this.generateStockReport();
  }
}
