import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/_services';
import { ProductViewService } from 'src/app/_services/product-view.service';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.less']
})
export class ViewProductComponent implements OnInit {

  views:any=[]

  constructor(private _PS:ProductViewService,
    private _ALS:AlertService) { }

  ngOnInit() {
    this.getViews()
  }

  getViews(){
    this._PS.getViews().subscribe((res:any)=>{
      this.views = res.response ? res.response : []
    },(err)=>{
      this._ALS.error(err)
    })
  }

  deleteView(id){
    this._PS.deleteView(id).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
        this.getViews()
      }
      else{
        this._ALS.warn(res.message)
      }
    },(err)=>{
      this._ALS.error(err)
    })
  }

  activateView(view){
    let payload :any ={
      viewId:view._id
    }
    if(view.isActive){
      payload.status=false
    }
    else{
      payload.status=true
    }
    this._PS.activateView(payload).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
        this.getViews()
      }
      else{
        this._ALS.warn(res.message)
      }
    },(err)=>{
      this._ALS.error(err)
    })
  }

}
