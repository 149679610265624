import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seller-payout',
  templateUrl: './seller-payout.component.html',
  styleUrls: ['./seller-payout.component.less']
})
export class SellerPayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
