import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/_services';
import { CreditorsService } from 'src/app/_services/creditors.service';

@Component({
  selector: 'app-creditors',
  templateUrl: './creditors.component.html',
  styleUrls: ['./creditors.component.less']
})
export class CreditorsComponent implements OnInit {

  creditors:any=[]

  constructor(private _CS:CreditorsService,
    private _ALS:AlertService) { }

  ngOnInit() {
    this.getAllCreditors()
  }

  getAllCreditors(){
    this._CS.getAllCreditors().subscribe((res:any)=>{
      this.creditors = res.data ? res.data : []
    },(err)=>{
      this._ALS.error(err.message)
    })
  }
}
