import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../_services';


@Injectable()
export class CheckSellerVerifyGuard implements CanActivate {

    responseData: any;
    bank: any;

    constructor(private router: Router, private userService: UserService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve) => {
            this.userService.isSellerVerify().subscribe(response => {
                this.responseData = response;
                if (this.responseData.success) {
                    resolve(true);
                } else {
                    this.router.navigate(['/sellerVerify'], { queryParams: { returnUrl: state.url } });
                    resolve(false);
                }

            });
        });
    }
}
