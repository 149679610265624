import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService, AlertService } from '../../../_services';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, flatMap, delay } from 'rxjs/operators';
import { of, Subject, Subscription } from 'rxjs';
import { CategoryService } from 'src/app/_services/category.service';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.less']
})
export class SearchProductComponent implements OnInit,OnDestroy{
  isUpdate = false;
  rootCatList: any=[];
  filter: any={};
  subCatList: any=[];
  leafCatList: any=[];
  brandList: any=[];
  subBrandList: any=[];
  limit=environment.pagainationLimit;
  page:any=0
  allProductList:any=[];
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  searchKey:FormControl
  isAllProductEnd: boolean=false;
  public keyUp = new Subject<string>();
  debounceSubscription: Subscription;
  isShowLoader:boolean=false
  productFilter:FormControl

  constructor(private modalService: BsModalService,
    private route: Router,
    public _PS: ProductService,
    public _ALS: AlertService,
    private _CS:CategoryService) { }
 
   ngOnInit() {
    this.searchKey=new FormControl()
    this.productFilter=new FormControl('All');
    this.getCategoryTree();
    this.getBrands()
    this.getAllProducts()
    this.debounceSubscription = this.keyUp.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      flatMap(search => of(search).pipe(delay(500)))).subscribe(value => {
      this.filter.keyword=value;
      this.page=0
     this.getAllProducts()
    });
  }

  getCategoryTree(){
    this._CS.getSellerActiveCatTree().subscribe((cat:any)=>{
      this.rootCatList=cat.tree ? cat.tree : [];
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  getSubCategory(catId,value){
    this.page=0
    if(value==='subCat'){
      this.filter.rootCategoryId=catId;
        this.rootCatList.filter(_=>{
        if(_._id==catId){
          this.subCatList= _.children;
        }
      })
    }
    else{
      this.filter.subCategoryId=catId;
      this.subCatList.filter(_=>{
        if(_._id==catId){
          this.leafCatList=_.children;
        }
      })
    }
  }

  getBrands(){
    this._PS.getBrands().subscribe((brand:any)=>{
      this.brandList=brand.brands ? brand.brands  : [];
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  getSubBrands(brandId:any){
    this.page=0
    this.filter.brandId=brandId
    this._PS.getSubBrands(brandId).subscribe((subBrand:any)=>{
      this.subBrandList=subBrand.subbrand ? subBrand.subbrand : []
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  setLeafCatId(leafCatId:any){
    this.page=0
    this.filter.leafCategoryId=leafCatId;
  }

  setSubBrandId(subBrandId:any){
    this.page=0
    this.filter.subBrandId=subBrandId
  }

  setSearchKeyword(value){
    this.filter.keyword=value;
    this.page=0;
    this.getAllProducts()
  }

  removeFilter(){
    this.subCatList=[]
    this.rootCatList=[]
    this.leafCatList=[]
    this.brandList=[]
    this.subBrandList=[]
    this.searchKey.reset()
    this.filter={}
    this.page=0
    this.productFilter.setValue('All')
    this.getBrands()
    this.getCategoryTree()
    this.getAllProducts()
  }

  onScrollDown(){
    this.page+=1;
    this.getAllProducts()
  }

  getAllProducts(){
    this.isShowLoader=true
    this._PS.getAllProducts(this.page,this.limit,this.filter).subscribe((product:any)=>{
      this.isShowLoader=false
      if(product.success){
        if(this.page==0){
          this.allProductList=product.product
        }
        else{
          this.allProductList=[...this.allProductList,...product.product]
        }
      }
      else{
        if(this.page==0){
          this._ALS.warn(product.message)
          this.allProductList=[]
        }
        else{
          this.isAllProductEnd=true;
        }
      }
    },(error)=>{
      this.isShowLoader=false
      this._ALS.error(error.message)
    })
  }

  setProductFilter(value){
    this.page=0
    if(value=='true'){
      this.filter.added=true;
    }
    else if(value=='false'){
      this.filter.added=false;
    }
    else{
      delete this.filter['added']
    }
  }

  addProductToList(product) {
    let tempAddProductData;
    this._PS.addProductToList(product._id, product.categoryId).subscribe(data => {
      tempAddProductData = data;
      if (tempAddProductData.success) {
        product.isAdded=true;
        this._ALS.success(tempAddProductData.message);
      } else {
        this._ALS.warn(tempAddProductData.message);
      }
    },(error)=>{
      this._ALS.error(error.message)
    });
  }

  ngOnDestroy(): void {
    this.debounceSubscription.unsubscribe()
  }
}
