import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ProductService } from 'src/app/_services';

@Component({
  selector: 'app-add-new-product',
  templateUrl: './add-new-product.component.html',
  styleUrls: ['./add-new-product.component.less']
})
export class AddNewProductComponent implements OnInit {

  updateProductForm:FormGroup;
  product:any={}
  productId: any;

  constructor(private fb:FormBuilder,
    private route:ActivatedRoute,
    private _PS:ProductService,
    private _ALS:AlertService,
    private router:Router) { }

  ngOnInit() {
    this.route.params.subscribe(param=>{
      this.productId=param.id
      if(this.productId){
        this.getProductDetail()
      }
    })
    this.updateProductForm=this.fb.group({
      price:[],
      purchasePrice:[],
      sellPrice:[],
      minSellPrice:[],
      membershipPrice:[],
      isLastBuy:[],
      isMorningBuy:[],
      isOrder:[],
      isSubscription:[],
      sellerProductId:[],
      quantity:[],
      perUserOrderQuantity:[],
      storeMinQuantity:[],
      competitor: this.fb.group({
        grofers: this.fb.group({
          productId: [],
          lastPrice: [],
          merchantId:[]
        }),
        milkbasket: this.fb.group({
          productId: [],
          lastPrice: []
        }),
        bigbasket: this.fb.group({
          productId: [],
          lastPrice: [],
        }),
        grocio: this.fb.group({
          productId: [],
          lastPrice: []
        })
      })
    })
  }

  getProductDetail(){
    this._PS.getProductDetail(this.productId).subscribe((res:any)=>{
      this.product=res.product
      if(res.product.competitor == null){
        this.product.competitor={}
      }
      this.updateProductForm.patchValue(this.product);
      this.product.isUpdate=true
      this.updateProductForm.controls['sellerProductId'].setValue(this.product._id)
      this.product.margin=`${((this.product.price-this.product.purchasePrice)*100/this.product.price).toFixed(2)}`
      this.product.discount=`${((this.product.price-this.product.sellPrice)*100/this.product.price).toFixed(2)}`
      this.product.maxDiscount=`${((this.product.price-this.product.minSellPrice)*100/this.product.price).toFixed(2)}`
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  updateProduct(){
    this._PS.productStockUpdate(this.updateProductForm.value).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  calculateSellPrice(value,type){
    if(type =='discount'){  
      this.product.sellPrice=Math.ceil(this.product.price-(this.product.price * value /100))
      this.updateProductForm.controls['sellPrice'].setValue(this.product.sellPrice)
        if( this.product.sellPrice >=  this.product.minSellPrice){
        this.product.isUpdate=true
     }
      else{
       this.product.isUpdate=false
      }
    }
    else if(type == 'maxDiscount'){
      this.product.minSellPrice=Math.ceil(this.product.price-(this.product.price * value /100))
      this.updateProductForm.controls['minSellPrice'].setValue(this.product.minSellPrice)
      if( this.product.sellPrice >=  this.product.minSellPrice){
         this.product.isUpdate=true
      }
      else{
       this.product.isUpdate=false
      }
   }
 } 

 updatePrice(price){
    this.product.price=price
    if(!this.product.purchasePrice){
      this.product.purchasePrice=this.product.price
      this.updateProductForm.controls['purchasePrice'].setValue(this.product.price)
    }
    this.product.discount=`${((this.product.price - this.product.sellPrice)*100 / this.product.price).toFixed(2)}`
    this.product.maxDiscount=`${((this.product.price - this.product.minSellPrice)*100 / this.product.price).toFixed(2)}`
    this.product.minSellPrice=Math.ceil(this.product.price-(this.product.price * this.product.maxDiscount /100))
    this.product.margin=`${((this.product.price - this.product.purchasePrice)*100 / this.product.price).toFixed(2)}`
    this.updateProductForm.controls['minSellPrice'].setValue(parseInt(this.product.minSellPrice))
    this.updateProductForm.controls['sellPrice'].setValue(parseInt(this.product.sellPrice))
    if(price >= this.product.purchasePrice){
    this.product.isUpdate=true
  }
  else{
    this.product.isUpdate=false
  }
 }

 calculatePurchasePrice(product,margin){
  product.purchasePrice=Math.ceil(product.price-(product.price*margin/100))
  this.updateProductForm.controls['purchasePrice'].setValue(product.purchasePrice)
  product.isUpdate=true
}
}
