import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient }    from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BuyerService {

  constructor(private http: HttpClient) { }

  addBuyer(data) {
    return this.http.post(environment.apiURL + 'upload', data);
  }

  getPurchaseList() {
    return this.http.get(environment.apiURL + 'store/buyer/purchaseRequest');
  }

  getPurchaseDetail(purchaseId) {
    return this.http.get(environment.apiURL + 'store/buyer/purchaseRequest/'+purchaseId);
  }

  generatePurchaseRequest(data) {
    return this.http.post(environment.apiURL + 'store/buyer/purchaseOrder',data);
  }

  getQuotationHistory() {
    return this.http.get(environment.apiURL + 'quotation');
  }

  getQuotationDetail(quotationId:any){
    return this.http.get(environment.apiURL+`quotation/${quotationId}`);
  }

  getPurchaseOrderList(){
    return this.http.get(environment.apiURL + 'store/buyer/purchaseOrder');
  }

  getPurchaseOrderDetails(purchaseOrderId){
    return this.http.get(environment.apiURL + 'store/buyer/purchaseOrder/'+purchaseOrderId);
  }

}
