import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService, AlertService } from '../../../_services/index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})

export class LoginComponent implements OnInit {
  model: any = {}

  constructor(private _AS: AuthenticationService,
    private router: Router,
    private _ALS: AlertService,
    private _US:UserService) { }

  ngOnInit() {}

  login(formData: any) {
    this._AS.login(formData.value).subscribe((res:any)=> {
      if (res.success) {
        this._US.user=res
        if(!res.data.isPhoneVerify){
          this.router.navigate(['/phone-verify'])
        }
        else{
          this.router.navigate(['/'])
          this._ALS.success(res.message)
        }
      }
      else{
        this._ALS.warn(res.message)
      }
    },
      (error) => {
        if (error.error) {
          this._ALS.error(error.error.message);
        } else {
          this._ALS.error(error.error.message);
        }
      });
  }
}
