import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  getAllCity() {
    return this.http.get(environment.apiURL + 'city');
  }

  getAddedCity() {
    return this.http.get(environment.apiURL + 'seller/city');
  }

  getSociety(cityId: any) {
    return this.http.get(environment.apiURL + `society?cityId=${cityId}`);
  }

  addSocietyWithCity(societyId: any, selectedCityId: any) {
    return this.http.post(environment.apiURL + '/delivery/address', { society: societyId, cityId: selectedCityId });
  }

  getAddedSociety(cityId) {
    return this.http.get(environment.apiURL + `seller/society?cityId=${cityId}`);
  }

}
