import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, Invoice2Service } from 'src/app/_services';
import { CreditorsService } from 'src/app/_services/creditors.service';
import * as QRCode from 'qrcodejs2';
import * as JsBarcode from 'jsbarcode';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-creditor-info',
  templateUrl: './creditor-info.component.html',
  styleUrls: ['./creditor-info.component.less']
})
export class CreditorInfoComponent implements OnInit {

  creditorId: any
  data: any = {}
  modalRef: BsModalRef
  paymentForm: FormGroup
  deposit: any = {}

  constructor(private _CS: CreditorsService,
    private route: ActivatedRoute,
    private _newIS2: Invoice2Service,
    private _ALS: AlertService,
    private modalService: BsModalService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.creditorId = param.id
    })
    if (this.creditorId) {
      this.getCreditorInfo()
      this.getAllDeposits()
    }
    this.paymentForm = this.fb.group({
      paymentMode: [],
      amount: [],
      transactionId: [],
      userId: [this.creditorId]
    })
  }

  getCreditorInfo() {
    this._CS.getCreditorInfo(this.creditorId).subscribe((res: any) => {
      this.data = res.data ? res.data : {}
    })
  }

  orderNewInvoice2(_id) {
    this._newIS2.getInvoice(_id).subscribe((invoiceResponse: any) => {
      if (invoiceResponse.success) {
        if (invoiceResponse && invoiceResponse.invoice && invoiceResponse.invoice.seller &&
          invoiceResponse.invoice.seller.sellerInformation && invoiceResponse.invoice.seller.sellerInformation.name) {
          this.generateQrCode(invoiceResponse.invoice.id);
          this.generateBarCode(invoiceResponse.invoice.id, invoiceResponse);
        } else {
          this._ALS.warn('Seller Address not found, please fill your invoice address to download invoice');
        }
      }
    });
  }


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-sm' }));
  }

  generateQrCode(qrString) {
    const qrcode = new QRCode(document.getElementById('qrcode'), {
      text: qrString,
      width: 128,
      height: 128,
      colorDark: '#000000',
      colorLight: '#ffffff',
      correctLevel: QRCode.CorrectLevel.H
    });
    const canvas = document.getElementById('qrcode').querySelector('canvas');
    if (!this._newIS2.code.qrCode) {
      this._newIS2.code = {
        qrCode: '',
        barCode: ''
      };
    }
    this._newIS2.code.qrCode = canvas.toDataURL();
  }

  generateBarCode(codeString, invoiceData) {
    const newCanvas = document.createElement('canvas');
    let barCode = new JsBarcode(newCanvas, codeString, {
      format: 'CODE128'
    });
    this._newIS2.code.barCode = newCanvas.toDataURL('image/png');
    invoiceData.invoice.products.sort((product1, product2) => {
      return product1.mbSku - product2.mbSku;
    })
    if (invoiceData.invoice.outOfStockProducts.length > 0) {
      invoiceData.invoice.products.map(_ => {
        invoiceData.invoice.outOfStockProducts.map(outOfStock => {
          if (_.productId == outOfStock.productId) {
            _.quantity -= outOfStock.quantity;
          }
        })
      })
    }
    if (invoiceData.invoice.returnProducts.length > 0) {
      invoiceData.invoice.products.map(_ => {
        invoiceData.invoice.returnProducts.map(returnProduct => {
          if (_.productId == returnProduct.productId) {
            _.quantity -= returnProduct.quantity;
          }
        })
      })
    }
    this._newIS2.printInvoice(invoiceData);
  }

  submitPay() {
    this.paymentForm.get("userId").setValue(this.creditorId)
    if (this.paymentForm.get("amount").value) {
      if (this.paymentForm.get("paymentMode").value == "Cash") {
        this.paymentForm.get("transactionId").reset()
      }

      if (this.paymentForm.get("paymentMode").value == "UPI" && !this.paymentForm.get("transactionId").value) {
        this._ALS.warn("please enter Transaction Id")
      }
      else {
        this._CS.depositAmount(this.paymentForm.value).subscribe((res: any) => {
          if (res.success) {
            this._ALS.success(res.message)
            this.getAllDeposits()
            this.paymentForm.reset()
            this.modalRef.hide()
          }
          else {
            this._ALS.warn(res.message)
          }
        }, (err) => {
          this._ALS.error(err.message)
        })
      }
    }
    else {
      this._ALS.warn("Please enter Valid Amount")
    }
  }

  reset() {
    this.paymentForm.reset()
  }

  getAllDeposits() {
    this._CS.getAlldeposits(this.creditorId).subscribe((res: any) => {
      this.deposit = res.data ? res.data : []
    }, (err) => {
      this._ALS.error(err.message)
    })
  }
}
