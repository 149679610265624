import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(private http:HttpClient) { }

  addPromoCode(promoCodeData){
    return this.http.post(environment.apiURL+'offer',promoCodeData)
  }

  getPromoCode(params){
    return this.http.get(environment.apiURL+'offer/type',{params:params})
  }

  updatePromoCode(id,promoCodeData){
    return this.http.put(environment.apiURL+`offer/${id}`,promoCodeData)
  }

  inActivatePromoCode(promoCodeData){
    return this.http.post(environment.apiURL+'offer/active/deactive',promoCodeData)
  }

  searchUser(value){
    return this.http.get(environment.apiURL+`user/search?value=${value}`)
  }

  getPromoCodeDetail(id){
    return this.http.get(environment.apiURL+`offer/${id}`)
  }
}
