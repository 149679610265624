import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  LoginComponent, SignupComponent, HomeComponent,
  OrdersComponent, ForgotComponent,OrderV2Component,
  SellerInfoComponent, NotificationComponent,
  EmailverificationComponent, OrderDetailComponent,
  AddAreaComponent, SearchProductComponent,
  AvailablestockComponent, TransactionComponent,
  SellerVerifyComponent, ReturnorderdetailComponent,
  TermsComponent, VendorAgreementComponent, FaqComponent,
  BuyerListComponent, RegisterBuyerComponent,
  PurchaseRequestComponent, PurchaseOrderComponent,
  PurchaseOrderDetailComponent, GoodsReturnListComponent,
  GoodsReturnDetailsComponent, GoodsReceiveDetailsComponent,
  GoodsReceiveListComponent, DeliveryBoyListComponent, BulkOrderComponent,
  OrderDetailV2Component,BagComponent,BuyerPurchaserequestDetailComponent,BuyerQuoteHistoryDetailComponent,PuschaseHistoryDetailComponent,GoodsReceiveHistoryDetailComponent,StockReportComponent,RouteComponent,AddNewRouteComponent,SlotComponent,AddNewSlotComponent,RouteReportComponent,DamageStockComponent,DamageStockDetailComponent,AuditReportComponent,AddNewProductComponent,AuditHistoryDetailComponent,BrandComponent,SubBrandComponent,PayoutsComponent,AddNewPromoCodeComponent,OrderSizeComponent,PromoCodeComponent,CategoryComponent,SubCategoryComponent,LeafCategoryComponent,SellerPayoutComponent,ViewProductComponent,NewProductViewComponent,PosComponent,CustomerComponent
} from './components';
import { AccountInfoComponent } from './starter/index.starter';
import { AuthGuard, CheckSellerdetailGuard, CheckSellerVerifyGuard } from './_guards';
import { HomethemeComponent } from './themes/hometheme/hometheme.component';
import { LoginthemeComponent } from './themes/logintheme/logintheme.component';
import { StockSlotReportComponent } from './stock-slot-report/stock-slot-report.component';
import { BannerComponent } from './components/banner/banner.component';
import { AddBannerComponent } from './components/banner/add-banner/add-banner.component';
import { SetPasswordComponent } from './components/user/set-password/set-password.component';
import { PhoneVerifyComponent } from './components/user/phone-verify/phone-verify.component';
import { CreditorsComponent } from './components/pos/creditors/creditors.component';
import { CreditorInfoComponent } from './components/pos/creditors/creditor-info/creditor-info.component';
import { SlotNewComponent } from './components/slot-new/slot-new.component';
import { AddSlotComponent } from './components/slot-new/add-slot/add-slot.component';
import { DeliveryBoyReportComponent } from './components/delivery-management/delivery-boy-report/delivery-boy-report.component';

const routes: Routes = [
  // secured routes
  {
    path: '', component: HomethemeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivate: [CheckSellerdetailGuard],
        children: [{
          path: '',
          canActivate: [CheckSellerVerifyGuard],
          component: HomeComponent
        },
        { path: 'orders', component: OrdersComponent },
        { path: 'bulk-orders', component: BulkOrderComponent },
        { path: 'orders-v2', component: OrderV2Component },
        { path: 'seller/info', component: SellerInfoComponent },
        { path: 'orders/detail', component: OrderDetailV2Component },
        { path: 'add-area', component: AddAreaComponent },
        { path: 'search-product', component: SearchProductComponent },
        { path: 'availablestock', component: AvailablestockComponent },
        { path: 'transaction', component: TransactionComponent },
        { path: 'terms-and-conditions', component: TermsComponent },
        { path: 'vendor-agreement', component: VendorAgreementComponent },
        { path: 'faq', component: FaqComponent },
        { path: 'cancelOrderDetail', component: ReturnorderdetailComponent },
        { path: 'notification', component: NotificationComponent },
        { path: 'delivery-boy-list', component:DeliveryBoyListComponent},
        { path: 'delivery-boy-report/:id', component:DeliveryBoyReportComponent},
        { path: 'stock-report', component:StockReportComponent},
        {path:'stock-slot-report',component:StockSlotReportComponent},
        {path: 'route', component:RouteComponent},
        {path: 'add-new-route', component:AddNewRouteComponent},
        {path: 'slot', component:SlotComponent},
        {path:'slot-new',component:SlotNewComponent},
        {path:'add-slot',component:AddSlotComponent},
        {path: 'add-new-slot', component:AddNewSlotComponent},
        {path: 'route-report', component:RouteReportComponent},
        {path: 'damage-stock', component:DamageStockComponent},
        {path: 'damage-stock-detail/:id', component:DamageStockDetailComponent},
        {path: 'audit-report', component:AuditReportComponent},
        {path: 'audit-history-detail', component:AuditHistoryDetailComponent},
        {path: 'add-new-product/:id', component:AddNewProductComponent},
        {path: 'brand',component:BrandComponent},
        {path: 'sub-brand/:id',component:SubBrandComponent},
        {path: 'payouts', component:PayoutsComponent},
        {path:'promo-code', component:PromoCodeComponent},
        {path:'add-new-promo-code', component:AddNewPromoCodeComponent},
        {path:'order-size', component:OrderSizeComponent},
        {path:'category', component:CategoryComponent},
        {path:'sub-category', component:SubCategoryComponent},
        {path:'leaf-category', component:LeafCategoryComponent},
        {path:'seller-payout', component:SellerPayoutComponent},
        {path:'view-product', component:ViewProductComponent},
        {path:'new-product-view', component:NewProductViewComponent},
        {path:'pos', component:PosComponent},
        {path:'creditors', component:CreditorsComponent},
        {path:'creditor/:id',component:CreditorInfoComponent},
        {path:'customer', component:CustomerComponent},
        

        // supplier routes
        {
          path: 'supplier',
          children: [
            { path: 'purchase-history-detail', component:PuschaseHistoryDetailComponent}
          ]
        },
        // purchase requestor routes
        {
          path: 'purchase',
          children: [
            { path: 'request', component: PurchaseRequestComponent },
            { path: 'order', component: PurchaseOrderComponent },
            { path: 'order/detail', component: PurchaseOrderDetailComponent },
            { path: 'bag', component:BagComponent}
          ]
        },
        // Buyer Routes
        {
          path: 'buyer',
          children: [
            { path: 'register', component: RegisterBuyerComponent },
            { path: 'list', component: BuyerListComponent },
            { path: 'purchase-request-detail/:id',component :BuyerPurchaserequestDetailComponent},
            {path: 'quote-history-detail/:quoteId', component:BuyerQuoteHistoryDetailComponent},
            {path: 'purchase-history-detail/:id', component:PuschaseHistoryDetailComponent}
          ]
        },
        {
          path: 'goods',
          children: [
            {
              path: 'return',
              children: [
                { path: 'list', component: GoodsReturnListComponent },
                { path: 'details/:id', component: GoodsReturnDetailsComponent },
              ]
            },
            {
              path: 'receive',
              children: [
                { path: 'list', component: GoodsReceiveListComponent },
                { path: 'detail', component: GoodsReceiveDetailsComponent },
              ]
            },
            {path: 'receive-history-detail/:grnId', component:GoodsReceiveHistoryDetailComponent}
          ]
        },
        {
          path:'banner',component:BannerComponent
        },
        {
          path:'add-banner',component:AddBannerComponent
        }
        ]
      }]
  },
  // unsecured routes
  {
    path: '', component: LoginthemeComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: SignupComponent },
      { path: 'forgot', component: ForgotComponent },
      { path: 'verification/email/:token', component: EmailverificationComponent },
      { path: 'accinfo', component: AccountInfoComponent },
      { path: 'sellerVerify', component: SellerVerifyComponent },
      {path:'set-password/:token',component:SetPasswordComponent},
      {path:'phone-verify',component:PhoneVerifyComponent}
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
