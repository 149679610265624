import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreditorsService {

  constructor(private http:HttpClient) { }

  getAllCreditors(){
    return this.http.get(environment.apiURL+`creditors`)
  }

  getCreditorInfo(id){
    return this.http.get(environment.apiURL+`creditor/${id}`)
  }

  depositAmount(data){
    return this.http.put(environment.apiURL+`deposit`,data)
  }

  getAlldeposits(id){
    return this.http.get(environment.apiURL+`deposits/${id}`)
  }
}
