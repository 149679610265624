import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StockService } from 'src/app/_services/stock.service';
import { AlertService } from 'src/app/_services';

@Component({
  selector: 'app-damage-stock-detail',
  templateUrl: './damage-stock-detail.component.html',
  styleUrls: ['./damage-stock-detail.component.less']
})
export class DamageStockDetailComponent implements OnInit {

  damageStock:any={}

  constructor(private route:ActivatedRoute,
    private _SS:StockService,
    private _ALS:AlertService) { }

  ngOnInit() {
    this.route.params.subscribe(param=>{
      let id=param.id;
      this.getDamageStockDetail(id)
    })
  }

  getDamageStockDetail(id){
    this._SS.getDamageStockDetail(id).subscribe((res:any)=>{
      this.damageStock=res.details ? res.details : {}
    },(error)=>{
      this._ALS.error(error.message)
    })
  }
}
