import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../_services/product.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.less']
})
export class StepperComponent implements OnInit {

  constructor(public _PS: ProductService) { }

  ngOnInit() {
  }

}
