import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, flatMap, delay } from 'rxjs/operators';
import { UserService, AlertService, ProductService, OrderService } from 'src/app/_services';

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.less']
})
export class PosComponent implements OnInit, AfterViewInit {
  @ViewChild('searchUserBox') searchUserInput;
  @ViewChild('productSearch') searchProductInput;
  public checkModel: any = { left: true, middle: false, right: false };
  public radioModel: string = 'Left';
  modalRef: BsModalRef;
  debounceSubscription: Subscription;
  debounceProductSubscription: Subscription;
  debounceProductUpdateSubscription: Subscription;
  users: any = [];
  user: any = {};
  keyUp = new Subject<string>();
  keyUpProduct = new Subject<string>();
  keyUpProductUpdate = new Subject<string>();
  searchProductList: any = [];
  products: any = {};
  price: any = {};
  paymentForm: any = {};
  paymentMode: any;
  palceOrder: boolean = false;
  order: any = {};
  barcode: any;
  page: any = 0;
  limit: any = 10;
  userMobile: FormControl;
  userName: FormControl;
  totalItems: any = 0
  productList: any = []

  constructor(private modalService: BsModalService, private _OS: OrderService, private _US: UserService, private _ALS: AlertService, private _PS: ProductService,
  ) { }

  ngAfterViewInit(): void {
    this.searchUserInput.nativeElement.focus();
  }

  ngOnInit() {
    this.userName = new FormControl("")
    this.userMobile = new FormControl("")
    this.barcode = new FormControl('');
    this.paymentForm = new FormGroup({
      "receivedAmount": new FormControl(''),
      "transactionId": new FormControl(''),
      "creditAmount": new FormControl("")
    })
    this.debounceSubscription = this.keyUp.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      flatMap(search => of(search).pipe(delay(500)))).subscribe(value => {
        this.searchUser(value)
      });

    this.debounceProductSubscription = this.keyUpProduct.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      flatMap(search => of(search).pipe(delay(500)))).subscribe(value => {
        this.searchProduct(value)
      });
  }
  openModal(template: TemplateRef<any>, paymentMode) {
    this.paymentForm.reset()
    this.paymentMode = paymentMode;
    this.palceOrder = false;
    this.modalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-sm' }));
  }
  openModalorder(order: TemplateRef<any>) {
    this.modalRef = this.modalService.show(order, Object.assign({}, { class: '' }));
  }

  searchUser(value) {
    this._US.getUser(this.userMobile.value).subscribe((data: any) => {
      this.searchUserInput.nativeElement.blur()
      if (data.users) {
        this.user = data.users[0];
        this.userName.setValue(this.user.name)
        this.searchProductInput.nativeElement.focus();
      } else {
        this.register(value)
      }
    }, (error) => {
      this._ALS.error(error.message);
    })
  }

  selectUser(user) {
    this.user = user;
    this.users = [];
  }

  searchProduct(value) {
    let filter = {
      keyword: value
    }
    this._PS.getSellerProducts(filter, this.page, this.limit).subscribe((data: any) => {
      this.searchProductList = data.products ? data.products : [];
    })
  }

  addProduct(product) {
    let findProduct = this.productList.filter(_ => {
      return _._id === product._id
    })

    if (findProduct.length <= 0) {
      this.products[product._id] = product;
      product.quantity = 1;
      this.productList.push(this.products[product._id])
    }
    else {
      this.products[product._id].quantity += 1
    }
    this.searchProductList = [];
    this.totalItems += 1
    this.calculatePrice();
    this.searchProductInput.nativeElement.focus();
  }

  addProductByBarcode() {
    let filteredProduct = Object.keys(this.products).filter(key => {
      return this.products[key].barCode == this.barcode.value;
    })
    if (filteredProduct.length > 0) {
      this.products[filteredProduct[0]].quantity += 1;
      this.totalItems += 1
      console.log(this.productList)
      this.calculatePrice();
    } else {
      let filter = {
        keyword: this.barcode.value
      }
      this._PS.getSellerProducts(filter, this.page, this.limit).subscribe((data: any) => {
        if (data.success) {
          this.addProduct(data.products[0])
        } else {
          this._ALS.warn("no product found")
        }
      })
    }
    this.barcode.reset();
  }

  updateProduct(productId, quantity) {
    this.totalItems -= this.products[productId].quantity
    this.products[productId].quantity = Number(quantity);
    this.totalItems += Number(quantity);
    this.calculatePrice();
  }

  deleteProduct(product) {
    let index = this.productList.indexOf(product)
    this.totalItems -= this.products[product._id].quantity
    delete this.products[product._id];
    this.productList.splice(index, 1)
    this.calculatePrice();
  }

  closeSuggestion() {
    this.searchProductList = [];
  }

  calculatePrice() {
    let data = {
      products: Object.keys(this.products).map(key => {
        return { productId: this.products[key]._id, quantity: this.products[key].quantity }
      })
    }
    this._OS.calculatePrice(data).subscribe((data: any) => {
      if (data.success) {
        this.price = data.price
        this.price.totalPrice = this.price.couponDiscount ? this.price.totalPrice - this.price.couponDiscount : this.price.totalPrice
      } else {
        this.price = {}
      }
    })
  }

  submit() {
    if (this.paymentMode == "credit" && this.paymentForm.get("creditAmount").value < this.price.totalPrice) {
      this._ALS.warn("Credit Amount Should be Equal to Payable Amount")
    } else {
      this.modalRef.hide();
      this.palceOrder = true;
      this.order = {
        orderProduct: Object.keys(this.products).map(key => {
          return { productId: this.products[key]._id, quantity: this.products[key].quantity }
        }),
        phoneNo: this.user.phoneNo,
        posPaymentInfo: {
          paymentMode: this.paymentMode,
          receivedAmount: this.paymentForm.get("receivedAmount").value,
          transactionId: this.paymentForm.get("transactionId").value,
          creditAmount: this.paymentForm.get("creditAmount").value
        }
      }
    }
  }

  createOrder() {
    this.palceOrder = false
    console.log(this.order)
    this.order.name = this.userName.value
    this._OS.createPosOrder(this.order).subscribe((data: any) => {
      if (data.success) {
        this.palceOrder = false
        this._ALS.success(data.message);
      } else {
        this.palceOrder = true
        this._ALS.warn(data.message);
      }
    })
  }

  register(value) {
    let data = {
      userId: value
    }
    this._US.createUser(data).subscribe((data: any) => {
      if (data.success) {
        this.user = {
          phoneNo: data.phoneNo
        }
        this._ALS.success(data.message);
        this.searchProductInput.nativeElement.focus();
      } else {
        this._ALS.warn(data.message);
      }
    })
  }
}
