import { Injectable }  from '@angular/core';
import { HttpClient }  from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map }         from 'rxjs/operators';
import { Router }      from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  constructor(private http: HttpClient,
             public router: Router) { }

  login(model: any) {
    return this.http.post<any>(environment.apiURL + 'login', model)
      .pipe(map(
        data => {
          if (data && data.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(data));
          }
          return data;
        }
      )
      );
  }

  logOut() {
    localStorage.removeItem('currentUser');
    // this.router.navigate(['/login']);
  }

}
