import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logintheme',
  templateUrl: './logintheme.component.html',
  styleUrls: ['./logintheme.component.less']
})
export class LoginthemeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
