import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductService, AlertService, StockService } from 'src/app/_services';
import { Router, ActivatedRoute } from '@angular/router';
import { TabsetComponent } from 'ngx-bootstrap';

@Component({
  selector: 'app-damage-stock',
  templateUrl: './damage-stock.component.html',
  styleUrls: ['./damage-stock.component.less']
})
export class DamageStockComponent implements OnInit {
  @ViewChild('staticTabs') saticTabs:TabsetComponent;
  damageStockForm: FormGroup;
  damageProduct: any;
  returnProduct: any = {}
  returnGrandTotal: any = 0;
  returnTotalQty: any = 0;
  returnProductList:any=[]
  damageStockList:any=[]

  constructor(private fb: FormBuilder,
    private _PS: ProductService,
    private _ALS: AlertService,
    private _SS:StockService,
    private router:Router,
    private route:ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(param=>{
      let tabId=param.id;
      this.selectTab(tabId)
      if(tabId==1){
        this.getDamageStock()
      }
    })
    this.damageStockForm = this.fb.group({
      barcode: ['', Validators.required],
      reason: ['Select Reason', Validators.required],
      returnQty: ['', Validators.required]
    })
  }

  addDamageProduct() {
    this._PS.getSellerProducts({ keyword: this.damageStockForm.value['barcode'].trim() }, 0, 5).subscribe((res: any) => {
      if (res.success) {
        this._ALS.success(res.message);
        this.damageProduct = res.products[0]
        if (this.returnProduct[res.products[0].barCode] != null) {
          this.returnGrandTotal -= this.returnProduct[res.products[0].barCode].returnTotal;
          this.returnTotalQty -= this.returnProduct[res.products[0].barCode].returnQty
          let index=this.returnProductList.indexOf(this.returnProduct[res.products[0].barCode])
          this.returnProductList.splice(index,1)
        }
        this.returnProduct[res.products[0].barCode] = this.damageProduct;
        this.returnProduct[res.products[0].barCode].returnQty = this.damageStockForm.value['returnQty']
        this.returnProduct[res.products[0].barCode].returnTotal = this.damageProduct.price * this.damageStockForm.value['returnQty'];
        this.returnProduct[res.products[0].barCode].reason = this.damageStockForm.value['reason']
        this.returnTotalQty += this.returnProduct[res.products[0].barCode].returnQty
        this.returnGrandTotal += this.returnProduct[res.products[0].barCode].returnTotal
        this.returnProductList.push(this.returnProduct[res.products[0].barCode])
        this.damageStockForm.reset();
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  submitDamageStock(){
    let returnProducts:any=[]
    this.returnProductList.map(_=>{
      let product={
        productId:_._id,
        reason:_.reason,
        quantity:_.returnQty
      }
      returnProducts.push(product)
    })
    this._SS.submitDamageStock(returnProducts).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
        this. damageProduct='';
        this.returnProductList=[]
        this.setTab(1)
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  getDamageStock(){
    this._SS.getDamageStock().subscribe((res:any)=>{
      this.damageStockList=res.damageList ? res.damageList :[]
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  downloadDagaseStock(id){
    this._SS.getDamageStockDetail(id).subscribe((res:any)=>{
      if(res.success){
        this._SS.downLoadDamageStockDetail(res.details,id);
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  setTab(id){
    this.router.navigate(['/damage-stock'],{queryParams:{id:id}})
  }

  selectTab(id){
    this.saticTabs.tabs[id].active=true
  }
}
