import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transaction'
})
export class TransactionPipe implements PipeTransform {

  transform(value: any[], args?: any): any {

    if (!value || !args) {
      return value;
    }

    return value.filter(filterValue =>
      filterValue.status.toLowerCase().indexOf(args.toLowerCase()) !== -1
    );

  }

}
