import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { StockService, AlertService } from '../../_services';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stock-report',
  templateUrl: './stock-report.component.html',
  styleUrls: ['./stock-report.component.less']
})
export class StockReportComponent implements OnInit {
  date: any;
  products: any = [];
  isDisabledDownload: boolean = false;
  isLoading: boolean = false;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  page = 0;
  limit = environment.pagainationLimit;

  constructor(
    private _SS: StockService,
    private _ALS: AlertService
  ) {
    // let currentDate = new Date();
    let currentDate = '';
    this.date = new FormGroup({
      startDate: new FormControl(currentDate, [Validators.required]),
      endDate: new FormControl(currentDate, [Validators.required])
    })
  }

  ngOnInit() {
    this.page = 0;
  }

  getReports() {
    this.isLoading = true;
    this._SS.getStockReport(this.date.value["startDate"], this.date.value['endDate'], { page: this.page, limit: this.limit }).subscribe((response: any) => {
      if (this.page == 0) {
        this.products = response.report ? response.report : [];
      }
      else {
        response.report.map(_ => {
          this.products.push(_);
        })
      }
      this.isLoading = false;
      this._ALS.info(response.message);
    })
  }

  downloadReports() {
    this.isDisabledDownload = true;
    this._SS.getStockReport(this.date.value["startDate"], this.date.value['endDate'], {}).subscribe((response: any) => {
      let products = response.report ? response.report : [];
      if (products.length > 0) {
        let data = products.map((_) => {
          return {
            sku: _.sku,
            barCode: _.barCode,
            name: _.name,
            rootCategory: _.rootCategory ? _.rootCategory.name : null,
            subCategory: _.subCategory ? _.subCategory.name : null,
            leafCategory: _.leafCategory ? _.leafCategory.name : null,
            brand: _.brand ? _.brand.name : null,
            subBrand: _.subBrand ? _.subBrand.name : null,
            price: _.price,
            purchasePrice: _.purchasePrice,
            sellPrice: _.sellPrice,
            hsnCode: _.hsnCode,
            gst: _.gst,
            storeMinQuantity: _.storeMinQuantity,
            stockQuantity: _.availableQuantity,
            sellQuantity: _.sellQuantity,
            sellAmount: _.sellAmount
          }
        })
        const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
        const header = Object.keys(data[0]);
        let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');
        var blob = new Blob([csvArray], { type: 'text/csv' })
        saveAs(blob, `sale-${this.date.value["startDate"]}-to-${this.date.value["endDate"]}.csv`);
        this.isDisabledDownload = false;
      }
    })
  }

  onScrollDown() {
    this.page += 1;
    this.getReports();
  }

  setPage() {
    this.products = [];
    this.page = 0;
  }
}
