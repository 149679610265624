import { Component, OnInit, TemplateRef } from '@angular/core';
import { RouteService } from 'src/app/_services/route.service';
import { AlertService } from 'src/app/_services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.less']
})
export class RouteComponent implements OnInit {

  routeList:any=[]
  modalRef:BsModalRef
  routeId: any;
  constructor(private _RS:RouteService,
    private _ALS:AlertService,
    private modalService:BsModalService) { }

  ngOnInit() {
    this.getRoutes()
  }

  getRoutes(){
    this._RS.getRoutes().subscribe((route:any)=>{
      if(route.success){
        this.routeList=route.sellerRoutes;
      }
      else{
        this.routeList=[]
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  deleteRoute(){
    this._RS.deleteRoute(this.routeId).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
        this.getRoutes()
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
    this.modalRef.hide()
  }

  inActivateRoute(routeId:any){
    this._RS.inActivateRoute(routeId).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  } 

  openModal(template:TemplateRef<any>,routeId){
    this.routeId=routeId
    this.modalRef=this.modalService.show(template,Object.assign({}))
  }

  hideModal(){
    this.modalRef.hide();
  }
}
