import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/_services';
import { OfferService } from 'src/app/_services/offer.service';

@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.less']
})
export class PromoCodeComponent implements OnInit {

  params :any={
    offerType:'offer'
  }
  promoCodes:any
  constructor(private _OS:OfferService,
    private _ALS:AlertService,) { }

  ngOnInit() {
    this.getPromoCode()
  }

  getPromoCode(){
    this._OS.getPromoCode(this.params).subscribe((res:any)=>{
      this.promoCodes=res.offer ? res.offer : []
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  inActivatePromoCode(promoCode){
    let payload:any={
      offerId:promoCode._id
    }
    if(promoCode.isActive){
      payload.status=false
    }
    else{
      payload.status=true
    }
    this._OS.inActivatePromoCode(payload).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
        this.getPromoCode()
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  setTab(type){
    if(type == 'promotion'){
      this.params.offerType='offer'
    }
    else{
      this.params.offerType='affiliate'
    }
    this.getPromoCode()
  }
}
