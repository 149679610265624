import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeliveryBoyService } from 'src/app/_services';

@Component({
  selector: 'app-delivery-boy-report',
  templateUrl: './delivery-boy-report.component.html',
  styleUrls: ['./delivery-boy-report.component.less']
})
export class DeliveryBoyReportComponent implements OnInit {
  deliverBoys;
  All;
  dateRange;
  Data={success:false};
  load=false;
  constructor(private _DS: DeliveryBoyService, private route: ActivatedRoute) { }
  ngOnInit() {
    this._DS.getDeliveryBoys().subscribe((result: any) => {
      console.log(result)
      this.deliverBoys = result.data;
      this.route.params.subscribe((result: any) => {
        this.All = result.id;
        this.search();
      }
      );
    })


  }
  search() {
    var temp;
    if (this.dateRange) {
      temp = {
        deliveryBoyId: String(this.All),
        to: this.dateRange[1],
        from: this.dateRange[0],
      }
    }
    else {
      temp = {
        deliveryBoyId: String(this.All),
        to: new Date(new Date().setDate(new Date().getDate() - 30)),
        from: new Date(),
      }
    }

    this.load=true;
    this._DS.getDeliveryBoysData(temp).subscribe((result: any) => {
     this.Data=result;
     this.load=false;
    
    })

  }
}

