import { Component, OnInit } from '@angular/core';
import { ProductService, AlertService, OrderService } from '../../_services';
import { environment } from '../../../environments/environment';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})

export class HomeComponent implements OnInit {

  sellerProductData: any = {};
  stockStatusData: any = {};
  dashData: any;
  mostbuyPro: any;
  deliveryData: any;
  orderData: any;
  productData: any;
  subscriptionData: any;
  balanceData: any;
  filter: any;
  dateRange: FormControl;
  multi: { name: string; series: any; }[];
  params: any = {}
  orderStatus: any = {};
  public bsConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

  constructor(public _PS: ProductService,
    public _ALS: AlertService,
    private _OS: OrderService) {

  }

  ngOnInit() {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', isAnimated: true, maxDate: new Date() })
    this.params.type = 'daily'
    this.dateRange = new FormControl('')
    this.getActiveProduct();
    this.dashBoard();
    this.getOrdersStatus(' ');
    this.getMostBuyProduct();
  }

  subscriptionIncrement(sellerProductList) {
    sellerProductList.subscriptionQuantity = sellerProductList.subscriptionQuantity + 1;
  }

  subscriptionDecrement(sellerProductList) {
    if (sellerProductList.subscriptionQuantity <= 0) {
      sellerProductList.subscriptionQuantity = 0;
    } else {
      sellerProductList.subscriptionQuantity = sellerProductList.subscriptionQuantity - 1;
      sellerProductList.isUpdate = true;
    }
  }

  orderIncrement(sellerProductList) {
    sellerProductList.quantity = sellerProductList.quantity + 1;
  }

  orderDecrement(sellerProductList) {
    if (sellerProductList.quantity <= 0) {
      sellerProductList.quantity = 0;
    } else {
      sellerProductList.quantity = sellerProductList.quantity - 1;
      sellerProductList.isUpdate = true;
    }
  }
  getActiveProduct() {
    const page = 0;
    const limits = environment.pagainationLimit;
    const value = { name: 1 };
    this.filter = {
      sort: value
    };
  }

  // productStockUpdate(sellerList: any) {
  //   this._PS.productsUpdateDetail.sellerProductId = sellerList.sellerProductId;
  //   this._PS.productsUpdateDetail.quantity = sellerList.quantity ? sellerList.quantity : 0;
  //   this._PS.productsUpdateDetail.subscriptionQuantity = sellerList.subscriptionQuantity ? sellerList.subscriptionQuantity : 0;
  //   this._PS.productStockUpdate(this._PS.productsUpdateDetail).subscribe(data => {
  //     this.stockStatusData = data;
  //     if (this.stockStatusData.success) {
  //      this.getActiveProduct();
  //       this._ALS.success(this.stockStatusData.message);
  //     } else {
  //       this._ALS.warn(this.stockStatusData.message);
  //     }
  //   });
  // }

  dashBoard() {
    let dashBoard: any;
    this._PS.getDashBoardData(this.params).subscribe(data => {
      dashBoard = data;
      if (dashBoard.success) {
        this.dashData = dashBoard;
        this.deliveryData = dashBoard.delivery;
        this.orderData = dashBoard.orders;
        this.productData = dashBoard.product;
        this.subscriptionData = dashBoard.subscription;
        this.balanceData = dashBoard.vendorData;
      } else {
        this._ALS.warn(dashBoard.message);
      }
    });
  }

  setParamType(value: any) {
    if (value) {
      this.params.type = value;
      this.getOrdersStatus(" ")
    }
    else if (this.dateRange.value) {
      this.params.from = moment(this.dateRange.value[0]).startOf('day').format("YYYY-MM-DD HH:mm:ss") // this.dateRange.value[0]
      this.params.to = moment(this.dateRange.value[1]).endOf('day').format("YYYY-MM-DD HH:mm:ss") //this.dateRange.value[1]
      this.getOrdersStatus(" ")

    }
  }

  setDaysParam(value) {
    this.dateRange.reset()
    if (value == 'today') {
      this.params.from = moment().format("YYYY-MM-DD HH:mm:ss");
      this.params.to = moment().format("YYYY-MM-DD HH:mm:ss");
    } else if (value == 'yesterday') {
      this.params.from = moment().add(-1, 'day').startOf('day').format("YYYY-MM-DD HH:mm:ss")
      this.params.to = moment().add(-1, 'day').endOf('day').format("YYYY-MM-DD HH:mm:ss");
    } else if (value == 'one week') {
      this.params.from = moment().add(-1, 'week').startOf('day').format("YYYY-MM-DD HH:mm:ss")
      this.params.to = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");
    }
    else if (value == 'two week') {
      this.params.from = moment().add(-2, 'week').startOf('day').format("YYYY-MM-DD HH:mm:ss")
      this.params.to = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");
    }
    else {
      this.params.from = moment().add(-1, 'month').startOf('day').format("YYYY-MM-DD HH:mm:ss")
      this.params.to = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");
    }
    this.getOrdersStatus(' ')
    this.dashBoard();
  }


  getMostBuyProduct() {
    // mostbuyPro
    this._PS.getMostBuy().subscribe(data => {
      console.log(data);
      if (data) {
        this.mostbuyPro = data;
      }
    });
  }

  getOrdersStatus(type: any) {
    this._OS.getOrderStatus(this.params).subscribe((response: any) => {
      this.orderStatus = response.data;
      if (type == 'payment') {
        this.orderStatus.type = 'payment'
        this.multi = [
          {
            name: "Amount",
            series: response.data.amount
          }
        ]
      }
      else {
        this.orderStatus.type = 'order'
        this.multi = [
          {
            name: "Order",
            series: response.data.orders
          }
        ];
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }


  changeDate(data: any) {

    // if (moment(data[0]).format("YYYY-MM-DD HH:mm:ss") === moment(data[1]).format("YYYY-MM-DD HH:mm:ss")) {
    //   console.log('match work');
    // }else{
    // if (data[1] == null) {
    //   data[1] = data[0]
    // } else {
    // }
    this.params.from = moment(data[0]).startOf('day').format("YYYY-MM-DD HH:mm:ss");
    this.params.to = moment(data[1]).endOf('day').format("YYYY-MM-DD HH:mm:ss");
    this.getOrdersStatus(' ')
    this.dashBoard();
  }

  // }
}
