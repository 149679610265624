import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BuyerService, AlertService } from 'src/app/_services';

@Component({
  selector: 'app-buyer-quote-history-detail',
  templateUrl: './buyer-quote-history-detail.component.html',
  styleUrls: ['./buyer-quote-history-detail.component.less']
})
export class BuyerQuoteHistoryDetailComponent implements OnInit {

  quotationDetail:any={};
  quoteProductDetails: any=[];

  constructor(private route:ActivatedRoute,
    private _BS:BuyerService,
    private _ALS:AlertService) { }

  ngOnInit() {
    this.route.params.subscribe(param=>{
      let quoteId=param.quoteId
      this.getQuotationDetail(quoteId);
    })
  }

  getQuotationDetail(quoteId){
    this._BS.getQuotationDetail(quoteId).subscribe((quote:any)=>{
      if(quote.success){
        this.quotationDetail=quote.quotDetails[0];
        this.quoteProductDetails=quote.quotDetails[0].products
        this._ALS.success(quote.message)
      }
      else{
        this._ALS.warn(quote.message)
      }
    },(error)=>{
      console.log(error.message);
    })
  }
}
