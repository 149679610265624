import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-inline-edit',
  templateUrl: './inline-edit.component.html',
  styleUrls: ['./inline-edit.component.less']
})
export class InlineEditComponent implements OnInit {

  @Input() data: number;
  @Input() isDate: boolean;
  @Output() focusOut: EventEmitter<number> = new EventEmitter<number>();

  currency = '$';
  editMode = false;
  newDate: any;
  constructor() { }

  ngOnInit() {
    this.formatDate(this.data);
  }

  onFocusOut() {
    if (this.isDate) {
      this.editMode = false;
      this.focusOut.emit(this.newDate);
    } else {
      this.editMode = false;
      this.focusOut.emit(this.data);
    }
  }

  formatDate(date) {
    if (this.isDate) {
      this.newDate = moment(new Date(date)).format('DDMMYYYY');
    }
  }

}
