export * from './components.component';
export * from './home/home.component';
export * from './user/login/login.component';
export * from './user/signup/signup.component';
export * from './user/user.component';
export * from './orders/order/orders.component';
export * from './user/forgot/forgot.component';
export * from './user/emailverification/emailverification.component';
export * from './user/seller-info/seller-info.component';
export * from './orders/order-detail/order-detail.component';
export * from './address/add-area/add-area.component';
export * from './catalogue/search-product/search-product.component';
export * from './dashboard/availablestock/availablestock.component';
export * from './user/transaction/transaction.component';
export * from './commonModule/image/image.component';
export * from './commonModule/stepper/stepper.component';
export * from './seller-verify/seller-verify.component';
export * from './pages/terms/terms.component';
export * from './pages/vendor-agreement/vendor-agreement.component';
export * from './pages/faq/faq.component';
export * from './orders/returnorderdetail/returnorderdetail.component';
export * from './user/notification/notification.component';
export * from './commonModule/inline-edit/inline-edit.component';
export * from './commonModule/pagination/pagination.component';
export * from '../components/orders/returnorderdetail/returnorderdetail.component';
export * from './WMS/buyer/buyer-list/buyer-list.component';
export * from './WMS/buyer/register-buyer/register-buyer.component';
export * from './WMS/purchase/purchase-request/purchase-request.component';
export * from './WMS/purchase/purchase-order/purchase-order.component';
export * from './WMS/purchase/purchase-order-detail/purchase-order-detail.component';
export * from './WMS/goods-return/goods-return-details/goods-return-details.component';
export * from './WMS/goods-return/goods-return-list/goods-return-list.component';
export * from './WMS/gooods-receive/goods-receive-details/goods-receive-details.component';
export * from './WMS/gooods-receive/goods-receive-list/goods-receive-list.component';
export * from './delivery-management/delivery-boy-list/delivery-boy-list.component';
export * from './commonModule/table/table.component';
export * from './order-v2/order-v2.component';
export * from './order-v2/order-detail-v2/order-detail-v2.component';
export * from './WMS/purchase/bag/bag.component';
export * from './WMS/buyer/buyer-list/buyer-purchaserequest-detail/buyer-purchaserequest-detail.component';
export * from './WMS/buyer/buyer-list/buyer-quote-history-detail/buyer-quote-history-detail.component';
export * from './WMS/buyer/buyer-list/puschase-history-detail/puschase-history-detail.component';
export * from './WMS/gooods-receive/goods-receive-history-detail/goods-receive-history-detail.component';
export * from './stock-report/stock-report.component';
export * from '../components/route/route.component';
export * from '../components/route/add-new-route/add-new-route.component';
export * from '../components/slot/slot.component';
export * from './slot/add-new-slot/add-new-slot.component';
export * from './route/route-report/route-report.component';
export * from './dashboard/damage-stock/damage-stock.component';
export * from './dashboard/damage-stock/damage-stock-detail/damage-stock-detail.component';
export * from './audit-report/audit-report.component';
export * from './catalogue/add-new-product/add-new-product.component';
export * from './audit-report/audit-history-detail/audit-history-detail.component';
export * from '../components/brand/brand.component';
export * from '../components/brand/sub-brand/sub-brand.component';
export * from './WMS/payouts/payouts.component';
export * from './promo-code/promo-code.component';
export * from './promo-code/add-new-promo-code/add-new-promo-code.component';
export * from './order-size/order-size.component';
export * from './category/category.component';
export * from './category/sub-category/sub-category.component';
export * from './category/leaf-category/leaf-category.component';
export * from './catalogue/seller-payout/seller-payout.component';
export * from './catalogue/view-product/view-product.component';
export * from './catalogue/view-product/new-product-view/new-product-view.component';
export * from './pos/pos/pos.component';
export * from './pos/customer/customer.component';
export * from './bulk-order/bulk-order.component';