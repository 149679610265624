import { Component, OnInit } from '@angular/core';
import { GoodsService, AlertService } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-goods-receive-details',
  templateUrl: './goods-receive-details.component.html',
  styleUrls: ['./goods-receive-details.component.less']
})
export class GoodsReceiveDetailsComponent implements OnInit {

  goodsReceiveDetail: any;
  params: any;
  constructor(private _GS: GoodsService,
    private _ALS: AlertService,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params;
      this.getGoodReceivedetail(this.params.id);
    });
  }

  getGoodReceivedetail(id) {
    let tempGoodsreceiveDetail;
    this._GS.getgoodsRecieveDetail(id).subscribe(data => {
      tempGoodsreceiveDetail = data;
      if (tempGoodsreceiveDetail.success) {
        this.goodsReceiveDetail = tempGoodsreceiveDetail.grnDetails;
      } else {
        this._ALS.warn(tempGoodsreceiveDetail.message);
      }
    }),
    error => {
      this._ALS.error(error.error && error.error.message ? error.error.message : error.message);
    };
  }

}
