import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { UserService, AlertService } from '../../../_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-seller-info',
  templateUrl: './seller-info.component.html',
  styleUrls: ['./seller-info.component.less']
})
export class SellerInfoComponent implements OnInit {

  sellerAddressModel: any = {};
  cropedImages: any = [];
  image: any = {};
  public imageResponse: any = [];
  public selectedFile: any = [];
  invoiceAddressModel: any = {};
  shippingAddressModel: any = {};
  returnAddressModel: any = {};
  taxInfoModel: any = {};
  bankDetailModel: any = {};
  getSellerData: any;
  option: any;
  response: any;
  @ViewChild('imageSrc') input: ElementRef;
  @ViewChild('lgModal') showModalOnClick: ModalDirective;
  cropper: any = [];
  currentUser: any;
  isEmailVerified: boolean;
  emailVerifyResponse: any;
  businessType: any = [
    { value: 'Newspaper', type: 'Newspaper' },
    { value: 'Milk', type: 'Milk' },
    { value: 'Grocery', type: 'Grocery' }
  ];
  data: any;
  signature: any = [];
  signatureData: any;
  stateData: any = {};

  isSubmitShippingAddress = false;
  isSubmitSellerAddress = false;
  isSubmitInvoiceAddress = false;
  isSubmitReturnAddress = false;
  isSubmitBankDetails = false;
  isSubmitSellerTaxSetting = false;


  constructor(private _US: UserService,
    private _AS: AlertService,
    private cd: ChangeDetectorRef,
    private router: Router) { }

  ngOnInit() {
    this.getSellerInfo();
    this.getState();
  }
  getState() {
    this._US.getState().subscribe(data => {
      this.stateData = data;
    });
  }
  submitShippingAddress() {
    const sellerInfo = { shippingAddress: this.shippingAddressModel };
    this._US.updateSellerInfo(sellerInfo).subscribe(data => {
      this.response = data;
      if (this.response.success) {
        this.isSubmitShippingAddress = true;
        this._AS.success(this.response.message);
      } else {
        this._AS.warn(this.response.message);
      }
    });
  }
  submitSellerAddress() {
    const sellerInfo = { sellerInformation: this.sellerAddressModel };
    this._US.updateSellerInfo(sellerInfo).subscribe(data => {
      this.response = data;
      if (this.response.success) {
        this.isSubmitSellerAddress = true;
        this._AS.success(this.response.message);
      } else {
        this._AS.warn(this.response.message);
      }
    });
  }
  submitInvoiceAddress() {
    const sellerInfo = { invoiceAddress: this.invoiceAddressModel };
    this._US.updateSellerInfo(sellerInfo).subscribe(data => {
      this.response = data;
      if (this.response.success) {
        this.isSubmitInvoiceAddress = true;
        this._AS.success(this.response.message);
      } else {
        this._AS.warn(this.response.message);
      }
    });
  }
  submitReturnAddress() {
    const sellerInfo = { returnAddress: this.returnAddressModel };
    this._US.updateSellerInfo(sellerInfo).subscribe(data => {
      this.response = data;
      if (this.response.success) {
        this.isSubmitReturnAddress = true;
        this._AS.success(this.response.message);
      } else {
        this._AS.warn(this.response.message);
      }
    });
  }
  submitBankDetails() {
    const sellerInfo = { bank: this.bankDetailModel };
    this._US.updateSellerInfo(sellerInfo).subscribe(data => {
      this.response = data;
      if (this.response.success) {
        this.isSubmitBankDetails = true;
        this._AS.success(this.response.message);
      } else {
        this._AS.warn(this.response.message);
      }
    });
  }
  submitSellerTaxSetting() {
    const sellerInfo = {
      signature: this.imageResponse,
      taxInformation: this.taxInfoModel
    };
    if (!(this.signatureData.length > 0) && this.imageResponse.length === 0) {
      this._AS.warn('Please upload signature, signature is mendatory');
    } else {
      this._US.updateSellerInfo(sellerInfo).subscribe(data => {
        this.response = data;
        if (this.response.success) {
          this.isSubmitSellerTaxSetting = true;
          this._AS.success(this.response.message);
        } else {
          this._AS.info(this.response.message);
        }
      });
    }
  }

  cropSelectedImage() {
    this.showModalOnClick.show();
    this.option = {
      aspectRatio: 1 / 1,
      modal: true,
      guides: false,
      highlight: true,
      background: true,
      autoCrop: true,
      autoCropArea: 0.9,
      responsive: true,
    };
    setTimeout(() => {
      this.cropper = new Cropper(this.input.nativeElement, this.option);
      this.cd.markForCheck();
    }, 1000);

  }

  onFileSelected(event: any) {
    this.selectedFile = [];
    this.selectedFile = <File>event.target.files;
    let file;
    if (this.selectedFile && this.selectedFile.length > 0) {
      file = this.selectedFile[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        this.done(reader.result);
      }.bind(this);
      reader.readAsDataURL(file);
    }
  }

  done(url) {
    this.image.src = url;
    this.cropSelectedImage();
  }

  updateSellerInfo() {
    const sellerInfo = {
      signature: this.imageResponse,
      sellerInformation: this.sellerAddressModel,
      invoiceAddress: this.invoiceAddressModel,
      shippingAddress: this.shippingAddressModel,
      returnAddress: this.returnAddressModel,
      bank: this.bankDetailModel,
      taxInformation: this.taxInfoModel
    };
    this._US.updateSellerInfo(sellerInfo).subscribe(data => {
      this.response = data;
      if (this.response.success) {
        this.router.navigate(['']);
        this._AS.success(this.response.message);
      } else {
        this._AS.warn(this.response.message);
      }
    });
  }
  getSellerInfo() {
    this._US.getSellerInfo().subscribe(data => {
      this.getSellerData = data;
      if (this.getSellerData.success) {
        if (this.getSellerData.seller[0] && this.getSellerData.seller[0].signature) {
          this.signatureData = this.getSellerData.seller[0].signature;
          this.signature = this.getSellerData.seller[0].signature[0];
        }
        if (this.getSellerData.seller[0].sellerInformation) {
          this.isSubmitSellerAddress = true;
          this.sellerAddressModel = this.getSellerData.seller[0].sellerInformation;
        }
        if (this.getSellerData.seller[0].invoiceAddress) {
          this.isSubmitInvoiceAddress = true;
          this.invoiceAddressModel = this.getSellerData.seller[0].invoiceAddress;
        }
        if (this.getSellerData.seller[0].shippingAddress) {
          this.isSubmitShippingAddress = true;
          this.shippingAddressModel = this.getSellerData.seller[0].shippingAddress;
        }
        if (this.getSellerData.seller[0].returnAddress) {
          this.isSubmitReturnAddress = true;
          this.returnAddressModel = this.getSellerData.seller[0].returnAddress;
        }
        if (this.getSellerData.seller[0].bank) {
          this.isSubmitBankDetails = true;
          this.bankDetailModel = this.getSellerData.seller[0].bank;
        }
        if (this.getSellerData.seller[0].taxInformation) {
          this.isSubmitSellerTaxSetting = true;
          this.taxInfoModel = this.getSellerData.seller[0].taxInformation;
        }
      } else {
        this._AS.warn(this.getSellerData.message);
      }
    });
  }

  cropImage() {
    this.showModalOnClick.hide();
    const canvas = this.cropper.getCroppedCanvas({
      width: 500,
      height: 500,
    });
    this.cropedImages.push({ src: canvas.toDataURL(), canvas: canvas, fileName: this.selectedFile[0].name });
    this.cropper.destroy();
    if (this.cropedImages.length >= 0) {
      this.cropedImages.map((_, i) => {
        _.canvas.toBlob(function (blob) {
          const formData = new FormData();
          formData.append('image', blob, _.fileName);
          this._US.saveSignature(formData).subscribe(data => {
            this.data = data;
            this.imageResponse.push(this.data.imagePath.path);
            if (this.data.success) {
              this._AS.success(this.data.message);
            } else {
              this._AS.success(this.data.message);
            }
          });
        }.bind(this));
      });
    }
  }
  clearCopper() {
    this.cropper.destroy();
    this.cropper = null;
    this.showModalOnClick.hide();
  }
  onKeydown(event: any) {
    if ((event.keyCode >= 219 && event.keyCode <= 222) || event.keyCode === 191 ||
      event.keyCode === 190 || event.keyCode === 188 || event.keyCode === 186 ||
      event.keyCode === 106 || event.keyCode === 110 || event.keyCode === 111 ||
      event.keyCode === 69 || event.keyCode === 187 || event.keyCode === 189 ||
      event.keyCode === 107 || event.keyCode === 109 || event.keyCode === 190 ||
      event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 192) {
      return false;
    } else {
      return true;
    }
  }
}
