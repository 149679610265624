import { Component, OnInit } from '@angular/core';
import { AlertService, ProductService } from 'src/app/_services';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.less']
})
export class BrandComponent implements OnInit {

  brands:any=[]
  constructor(private _PS:ProductService,
    private _ALS:AlertService) { }

  ngOnInit() {
    this.getBrands()
  }

  getBrands(){
    this._PS.getBrands().subscribe((res:any)=>{
      this.brands=res.brands ? res.brands : []
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

}
