import { Component, OnInit } from '@angular/core';
import { UserService, AlertService } from '../../_services';
@Component({
  selector: 'app-verifyemail',
  templateUrl: './verifyemail.component.html',
  styleUrls: ['./verifyemail.component.less']
})
export class VerifyemailComponent implements OnInit {
  currentUser: any;
  isEmailVerified: any;
  emailVerifyResponse: any;
  countEmail: any = 0;
  constructor(private _US: UserService,
    private _AS: AlertService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.isEmailVerified = this.currentUser.data.isEmailVerify; 
  }

  sendVerificationEmail() {
    this.countEmail++;
    if (this.countEmail < 5) {
      this._US.sendEmailForVerification().subscribe(data => {
        this.emailVerifyResponse = data;
        if (this.emailVerifyResponse.success) {
          this._AS.success(this.emailVerifyResponse.message);
        } else {
          this._AS.warn(this.emailVerifyResponse.message);
        }
      });
    } else {
      this._AS.info('Please try again after some time, too many requestes send');
    }
    setTimeout(() => {
      this.countEmail = 0;
    }, 900000);
  }
}
