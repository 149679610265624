import { Component, OnInit, ViewChild } from '@angular/core';
import { BuyerService, AlertService, PurchaseService } from 'src/app/_services';
import { PoInvoiceService } from 'src/app/_services/po-invoice.service';
import { TabsetComponent } from 'ngx-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-buyer-list',
  templateUrl: './buyer-list.component.html',
  styleUrls: ['./buyer-list.component.less']
})
export class BuyerListComponent implements OnInit {
  @ViewChild('staticTabs') staticTabs :TabsetComponent
  purchaseList: any=[];
  quotationList: any=[];
  purchaseOrder: any=[];

  constructor(private _BS: BuyerService,
    private _ALS: AlertService,
    private _PIS:PoInvoiceService,
    private router:Router,
    private route:ActivatedRoute
    ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params=>{
      let tabId=params.id;
      this.selectTab(tabId)
      if(tabId==1){
        this.getQuotations();
      }
      else if(tabId==2){
        this.getPurchaseOrderList();
      }
    })
    this.getBuyerList();
  }

  getBuyerList() {
    this._BS.getPurchaseList().subscribe((response:any) => {
      this.purchaseList = response.purchaseRequestList ? response.purchaseRequestList:[];
    }),
    error => {
      this._ALS.error(error.error && error.error.message ? error.error.message : error.message);
    };
  }

  getQuotations() {
    this._BS.getQuotationHistory().subscribe((response:any) => {
      this.quotationList = response.quotationlist ? response.quotationlist:[];
    }),
    error => {
      this._ALS.error(error.error && error.error.message ? error.error.message : error.message);
    };
  }

  getPurchaseOrderList(){
    this._BS.getPurchaseOrderList().subscribe((response:any)=>{
      this.purchaseOrder = response.data ? response.data:[];
    })
  }

  downloadOrderDetail(orderId:any){
    this._BS.getPurchaseOrderDetails(orderId).subscribe((res:any)=>{
      if(res.success){
        this._PIS.printOrderDetail(res.poDetails);
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  setSelectedTab(id){
    this.router.navigate(['/buyer/list'],{queryParams:{id:id}})
  }

  selectTab(tabId){
    this.staticTabs.tabs[tabId].active=true
  } 
}
