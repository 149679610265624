import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../_services/index';
import { Router } from '@angular/router';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.less']
})
export class NotificationComponent implements OnInit {
  userNotifications: any = [];
  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.userNotification();
  }


  userNotification() {
    this.userService.userNotification().subscribe(data => {
      this.userNotifications = data;
      this.userNotifications = this.userNotifications.list;
    });
  }
}
