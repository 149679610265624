import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AlertService } from 'src/app/_services';
import { BannerService } from 'src/app/_services/banner.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.less']
})
export class BannerComponent implements OnInit {

  bannerList: any = []
  addBannerForm: FormGroup
  bannerDetail: any = {}
  addUspBannerForm: FormGroup
  uspBannerImages: any = []
  uspBanner: any = {}

  constructor(private _BS: BannerService,
    private _ALS: AlertService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.addUspBannerForm = this.fb.group({
      name: [],
      searchKeywordApp: [],
      priority:[0]
    })

    this.addBannerForm = this.fb.group({
      banners: this.fb.array([])
    })
    this.initializeFormControl()
    this.getBannerList()
    this.getOfferBanners()
    this.getUspBanner()
  }

  initializeFormControl() {
    this.addBannerForm = this.fb.group({
      banners: this.fb.array([])
    })
  }

  formControl(name = null, searchKeywordApp = null, image = null, id = null, isActive = null,priority=null) {
    (<FormArray>this.addBannerForm.get('banners')).push(
      this.fb.group({
        name: [name],
        image: [image],
        priority:[priority],
        searchKeywordApp: [searchKeywordApp],
        isUspBanner: [false],
        _id: [id],
        isActive: [isActive],
        isShowImg: [false]
        // isShowWebImg:[],
        // isShowAppImg:[]
      }));
  }

  getBannerList() {
    this._BS.getBanner().subscribe((res: any) => {
      this.bannerList = res.banners ? res.banners : []
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  deleteBanner(id) {
    this._BS.deleteBanner(id).subscribe((res: any) => {
      if (res.success) {
        this._ALS.success(res.message)
        this.getBannerList()
        this.getUspBanner()
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  inActivateBanner(banner) {
    let payload: any = {}
    payload.bannerId = banner._id
    if (banner.isActive) {
      payload.value = false
    }
    else {
      payload.value = true
    }
    this._BS.inActivateBanner(payload).subscribe((res: any) => {
      if (res.success) {
        this._ALS.success(res.message)
        this.getBannerList()
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  uploadBannerImage(event, type, i) {
    let imageFile = event.target.files[0];
    let formData = new FormData()
    formData.append('image', imageFile)
    this._BS.uploadBannerImage(formData).subscribe((res: any) => {
      if (res.success) {
        this._ALS.success(res.message)
        if (type == "uspBanner") {
          this.uspBannerImages.push(res.file.filename)
        }
        else {
          this.addBannerForm.get('banners').get(i.toString()).get('isShowImg').setValue(true)
          this.addBannerForm.get('banners').get(i.toString()).get('image').setValue(res.file.filename)
        }
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  addOfferUspBanner(payload) {
    let data = {}
    if (payload) {
      data = payload
    } else {
      data = this.addBannerForm.value
    }
    this._BS.addOfferUspBanner(data).subscribe((res: any) => {
      if (res.success) {
        this.initializeFormControl()
        this._ALS.success(res.message)
        this.getOfferBanners()
        this.getUspBanner()
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (Err) => {
      this._ALS.error(Err.message)
    })
  }

  activateOfferBanner(banner) {
    let payload: any = {}
    if (banner == "uspBanner") {
      payload.bannerId = this.uspBanner._id
      payload.status = this.uspBanner.isActive ? false : true
    }
    else {
      payload.bannerId = banner.value['_id']
      if (banner.value['isActive']) {
        payload.status = false
      }
      else {
        payload.status = true
      }
    }

    this._BS.activateOfferBanner(payload).subscribe((res: any) => {
      if (res.success) {
        this._ALS.success(res.message)
        this.initializeFormControl()
        this.getOfferBanners()
        this.getUspBanner()
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (err) => {
      this._ALS.error(err)
    })
  }

  getOfferBanners() {
    this._BS.getOfferUspBanner().subscribe((res: any) => {
      if (res.success) {
        this.bannerDetail = res.data
        res.data.banners.map(_ => {
          this.formControl(_.name, _.searchKeywordApp, _.image[0], _._id, _.isActive,_.priority)
        })
      }else{
        this.bannerDetail ={}
      }
    },(err)=>{
      this._ALS.error(err.message)
    })
  }

  submitBanner() {
    this.addBannerForm.value.banners.map((_, i) => {
      let images = [];
      images.push(_.image)
      _.image = images
    })
    if (this.bannerDetail.id) {
      this.updateBanner()
    }
    else {
      this.addOfferUspBanner("")
    }
  }

  updateBanner() {
    let payload = this.addBannerForm.value
    delete payload['_id'],
      delete payload['isActive']
    delete payload['isShowImg']
    this._BS.updateOfferUspBanner(this.bannerDetail._id, payload).subscribe((res: any) => {
      if (res.success) {
        this.initializeFormControl()
        this.getOfferBanners()
        this._ALS.success(res.message)
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (err) => {
      this._ALS.error(err.message)
    })
  }

  deleteofferBanner(index, banner) {
    if (banner.value['_id'] != null) {
      this._BS.deleteOfferBanner(banner.value['_id']).subscribe((res: any) => {
        if (res.success) {
          this._ALS.success(res.message)
          this.initializeFormControl()
          this.getOfferBanners()
        }
        else {
          this._ALS.warn(res.message)
        }
      }, (err) => {
        this._ALS.error(err.message)
      })
    }
    (this.addBannerForm.get('banners') as FormArray).removeAt(index)
  }

  addUspBanner() {
    let payload = {
      isUspBanner: true,
      banners: [
        {
          name: this.addUspBannerForm.value['name'],
          searchKeywordApp: this.addUspBannerForm.value['searchKeywordApp'],
          image: this.uspBannerImages,
        }
      ]
    }
    this.addOfferUspBanner(payload)
  }

  deleteUspBanner() {
    this._BS.deleteOfferBanner(this.uspBanner._id).subscribe((res: any) => {
      if (res.success) {
        this._ALS.success(res.message)
        this.addUspBannerForm.reset()
        this.getUspBanner()
      } else {
        this._ALS.warn(res.message)
      }
    }, (err) => {
      this._ALS.error(err.message)
    })
  }

  getUspBanner() {
    this._BS.getUspBanner().subscribe((res: any) => {
      if (res.success) {
        this.uspBanner = res.data
        this.addUspBannerForm.patchValue(res.data)
      } else {
        this.uspBanner = {}
      }
    }, (err) => {
      this._ALS.error(err.message)
    })
  }
}
