import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-buyer',
  templateUrl: './register-buyer.component.html',
  styleUrls: ['./register-buyer.component.less']
})
export class RegisterBuyerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
