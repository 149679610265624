import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PurchaseService, AlertService } from 'src/app/_services';

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.less']
})
export class PurchaseOrderComponent implements OnInit {

  purchaseOrderData: any;
  selectedProducts: any = [];
  purchaseOrder: any;

  constructor(private activatedRoute: ActivatedRoute,
    private _PS: PurchaseService,
    private _ALS: AlertService,
    private router: Router) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      let tempParam;
      tempParam = params;
      this.getPurhaseOrder(tempParam.id);
    });
  }

  getPurhaseOrder(id) {
    let tempPurchaseOrder;
    this._PS.getPurchaseOrderById(id).subscribe(data => {
      tempPurchaseOrder = data;
      if (tempPurchaseOrder.success) {
        this.purchaseOrderData = tempPurchaseOrder;
        this.purchaseOrderData.list.map((_) => {
          _.products.map((_) => {
            _.isChecked = false;
          });
        });
      } else {
        this._ALS.warn(tempPurchaseOrder.message);
      }
    }),
    error => {
      this._ALS.error(error.error && error.error.message ? error.error.message : error.message);
    };
  }

  selectSupplierProduct(event, productId, supplierId, quantity, purchaseRequestId, quotationId, unitPrice) {
    this.purchaseOrderData.list.map((_) => {
      _.products.map((__) => {
        if (supplierId != _.supplierId) {
          if (productId == __.productId) {
            __.isChecked = false;
          }
        } else {
          if (productId == __.productId) {
            __.isChecked = true;
          }
        }
      });
    });
    if (event.target.checked) {
      this.selectedProducts.map((o, i) => {
        if (o.productId === productId) {
          this.selectedProducts.splice(i, 1);
        }
      });
      this.selectedProducts.push({ productId: productId, supplierId: supplierId, quantity: quantity, purchaseRequestId: purchaseRequestId, quotationId: quotationId, unitPrice: unitPrice });
    } else {
      this.selectedProducts.splice(this.selectedProducts.findIndex(function (i) {
        return i.productId === productId && i.supplierId === supplierId;
      }), 1);
    }
  }

  editQuantity(quantity, productId, supplierId) {
    this.selectedProducts.map((_, i) => {
      if (_.productId === productId && _.supplierId == supplierId) {
        _.quantity = quantity;
      }
    }
    );
  }

  addPurchaseOrder() {
    let tempPurchaseOrder;
    this._PS.submitPurchaseOrder(this.selectedProducts).subscribe(data => {
      tempPurchaseOrder = data;
      if (tempPurchaseOrder.success) {
        this.router.navigate(['purchase/order/list']);
      } else {
        this._ALS.warn(tempPurchaseOrder.message);
      }
    }),
    error => {
      this._ALS.error(error.error && error.error.message ? error.error.message : error.message);
    };
  }
}
