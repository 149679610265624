import { Component, OnInit } from '@angular/core';
import { UserService, AlertService } from '../../_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.less']
})
export class AccountInfoComponent implements OnInit {
  response: any;
  bankDetailModel: any = {};
  imageResponse: any;
  taxInfoModel: any = {};
  constructor(private _US: UserService,
    private _AS: AlertService,
    private router: Router) { }

  ngOnInit() { }

  submitBankDetails() {
    const sellerInfo = { bank: this.bankDetailModel, taxInformation: this.taxInfoModel };
    this._US.updateSellerInfo(sellerInfo).subscribe(data => {
      this.response = data;
      if (this.response.success) {
        this.router.navigate(['']);
        // this._AS.success(this.response.message);
      } else {
        this._AS.warn(this.response.message);
      }
    });
  }

  onKeydown(event: any) {
    if ((event.keyCode >= 219 && event.keyCode <= 222) || event.keyCode === 191 ||
      event.keyCode === 190 || event.keyCode === 188 || event.keyCode === 186 ||
      event.keyCode === 106 || event.keyCode === 110 || event.keyCode === 111 ||
      event.keyCode === 69 || event.keyCode === 187 || event.keyCode === 189 ||
      event.keyCode === 107 || event.keyCode === 109 || event.keyCode === 190 ||
      event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 192) {
      return false;
    } else {
      return true;
    }
  }
}
