import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ProductService, AlertService } from '../../../_services';
import { Router, Params, ActivatedRoute } from '@angular/router';
import Cropper from 'cropperjs';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.less']
})
export class ImageComponent implements OnInit {


  constructor(private cd: ChangeDetectorRef,
    public _PS: ProductService,
    public _AS: AlertService,
    public router: Router,
    private route: ActivatedRoute) { }

  @ViewChild('lgModal') public showModalOnClick: ModalDirective;
  @ViewChild('imageSrc') input: ElementRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: false
  };

  fileArray: any = [];
  fd: any = [];
  message: string;
  days = 0;
  public imagePath: any = [];
  public selectedFile: any = [];
  data: any = {};
  imgURI = 'assets/dist/img/user-empty.png';
  cropper: any = [];
  cropedImages: any = [];
  response: any = {};
  model: any = {images: []};
option: any;

  image: any = {};

  ngOnInit() {
  }
  cropSelectedImage() {
    this.showModalOnClick.show();
    this.option = {
      // viewMode: 3,
      aspectRatio: 1 / 1,
      dragMode: 'move',
      modal: true,
      guides: false,
      highlight: true,
      background: true,
      autoCrop: true,
      autoCropArea: 0.9,
      responsive: true,
      crop: (event: any) => {
      }
    };
    setTimeout(() => {
      this.cropper = new Cropper(this.input.nativeElement, this.option);
      this.cd.markForCheck();
    }, 1000);

  }
  onFileSelected(event: any) {
    this._PS.images = [];
    this.selectedFile = [];
    this.selectedFile = <File>event.target.files;
    let reader;
    let file;
    let url;

    if (this.selectedFile && this.selectedFile.length > 0) {
      file = this.selectedFile[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        this.done(reader.result);
      }.bind(this);
      reader.readAsDataURL(file);
    }
  }
  done(url) {
    this.image.src = url;
    this.cropSelectedImage();
  }
  cropImage() {
    this.showModalOnClick.hide();
    const canvas = this.cropper.getCroppedCanvas({
      width: 500,
      height: 500,
    });
    this.cropedImages.push({ src: canvas.toDataURL(), canvas: canvas, fileName: this.selectedFile[0].name });
    this.cropper.destroy();
    if (this.cropedImages.length >= 0) {
      this.cropedImages.map((_, i) => {
        _.canvas.toBlob(function (blob) {
          const formData = new FormData();
          formData.append('image', blob, _.fileName);
          this._PS.addCategoryImage(formData).subscribe(data => {
            this.data = data;
            if (this.data.success) {
              this._AS.success(this.data.message);
              this.imagePath.push(this.data.imagePath.path);
              this._PS.imageResponse = this.imagePath;
              this._PS.model.images = this.imagePath;
              this.show = true;
              this.clearCopper();
            }
          });

        }.bind(this));
      });
      this._PS.images = this.cropedImages;
    }
  }
  clearCopper() {
    this.cropper.destroy();
    this.cropper = [];
    this.showModalOnClick.hide();
  }
  deteteImage() {
    this.model.images = this.imagePath;
    this._PS.removedImage(this.model).subscribe(data => {
        this.response = data;
        if (this.response.success) {
          this.cropedImages = [];
          this._AS.success(this.response.message);
          this._PS.imageResponse = [];
          this._PS.images = [];
        }
    });
  }
}
