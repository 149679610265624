import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as jsPDF from 'jspdf';
import { GoodsService } from './goods.service';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  verticalOffSet: any=20;

  constructor(private http:HttpClient,
    private _GS:GoodsService) { }

  addRoute(routeData:any){
    return this.http.post(environment.apiURL+'sellerroute',routeData)
  }

  getRoutes(){
    return this.http.get(environment.apiURL+'sellerroute')
  }

  getRouteDetail(routeId:any){
    return this.http.get(environment.apiURL+`sellerroute/${routeId}`)
  }

  deleteRoute(routeId:any){
    return this.http.delete(environment.apiURL+`sellerroute/${routeId}`)
  }

  updateRoute(routeId,routeData){
    return this.http.put(environment.apiURL+`sellerroute/${routeId}`,routeData)
  }

  inActivateRoute(routeId:any){
    return this.http.get(environment.apiURL+`sellerroute/activate?sellerRouteId=${routeId}`)
  }

  generateRouteReport(filter){
    return this.http.get(environment.apiURL+'routeReport',{params:filter})
  }

  downLoadRouteReport(orders,filter){
    this.verticalOffSet=20;
    const doc=new jsPDF()
    doc.setLineWidth(0.7)
    doc.line(10,this.verticalOffSet,200,this.verticalOffSet)
    this.increaseVertical(7,doc)
    doc.setFontType("bold")
    // doc.text(40,this.verticalOffSet,"Slot: ")
    doc.setFontType("normal")
    doc.setFontSize(15)
    let date=new Date(filter.date)
    doc.text(75,this.verticalOffSet,'DATE :-')
    doc.text(100,this.verticalOffSet,date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear())
    // if(filter.slot){
    //     doc.text(85,this.verticalOffSet,`${filter.slot}`)
    // }
    // if(filter.group){
    //   doc.text(140,this.verticalOffSet,`${filter.group}`)
    // }
    this.increaseVertical(6,doc)
    doc.setLineWidth(0.7)
    doc.line(10,this.verticalOffSet,200,this.verticalOffSet)
    this.increaseVertical(5,doc)
    doc.setFontSize(13)
    doc.setFontType("bold")
    doc.text(15,this.verticalOffSet,"SI No.")
    doc.text(33,this.verticalOffSet,"ORDER ID")
    doc.text(60,this.verticalOffSet,"AREA")
    doc.text(90,this.verticalOffSet,"ADDRESS")
    doc.text(130,this.verticalOffSet,"ITEM")
    doc.text(150,this.verticalOffSet,"AMOUNT")
    doc.text(180,this.verticalOffSet,"SLOT")
    this.increaseVertical(5,doc)
    doc.line(10,this.verticalOffSet,200,this.verticalOffSet)
    this.increaseVertical(7,doc)
    doc.setFontType("normal")
    doc.setFontSize(10)
    orders.map((_,i)=>{
      doc.text(15,this.verticalOffSet,`${i+1}`)
      doc.text(33,this.verticalOffSet,_.id.toString())
      if(_.group){
        // let group=doc.splitTextToSize(_.group,25)
        doc.text(51,this.verticalOffSet,doc.splitTextToSize(`${_.group.toUpperCase()}`,25))
      }
      doc.setFontType("bold")
      // let name=this._GS.covertIntoTitleCase(_.address.name)
      doc.text(80,this.verticalOffSet,`${_.address.name.toUpperCase()},${_.address.mobileNo}`)
      doc.setFontType("normal")
      this.increaseVertical(5,doc)
      let address=`${_.address.line1} ${_.address.line2} ${_.address.fullAddress}`
      var dim = doc.getTextDimensions(address);
      doc.text(80,this.verticalOffSet,doc.splitTextToSize(`${address.toUpperCase()}`,45))
      doc.text(135,this.verticalOffSet,_.itemsCount.toString())
      doc.text(155,this.verticalOffSet,_.amount.toString())
      doc.text(170,this.verticalOffSet,_.deliveryTime.slot)
      this.increaseVertical((dim.h*8)+10,doc)
    })
    doc.setLineWidth(0.7)
    doc.line(10,this.verticalOffSet,200,this.verticalOffSet)
    doc.save('Route-report')
  }

  increaseVertical(value,doc){
    if(this.verticalOffSet+40 > doc.internal.pageSize.height){
      doc.addPage()
      this.verticalOffSet=20 
    }
    else{
      this.verticalOffSet+=value
    }
  }
}
