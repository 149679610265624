import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductService, AlertService } from '../../../_services';
import { environment } from '../../../../environments/environment';
import { debounceTime, delay, distinctUntilChanged, flatMap } from 'rxjs/operators';
import { of, Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from 'src/app/_services/category.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-availablestock',
  templateUrl: './availablestock.component.html',
  styleUrls: ['./availablestock.component.less']
})

export class AvailablestockComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  stockStatusData: any = {};
  currentTab: any;
  allProductStockData: any = {};
  sellerUpdatedProducts: any = {};
  sellerProductStatus: any = {};
  updateData: any = {};
  isAllUpdate: any;
  activeProductCount: any;
  filterData: any;
  filter: any = {};
  value: any;
  active: any;
  deactive: any;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  page = 0;
  limit = environment.pagainationLimit;
  activeProductList: any = [];
  deactiveProductList: any = [];
  isUpdateAll = false;
  productSearchValue: any;
  searchValue: any;
  public keyUp = new Subject<string>();
  isActiveDataEnd = false;
  isDeActiveAllEnd = false;
  isDeActiveDataEnd = false;
  rootCatList: any = []
  subCatList: any = [];
  leafCatList: any = [];
  brandList: any = []
  subBrandList: any = []
  sellerProductList: any = [];
  isShowLoader: boolean = false;
  productFilter: FormControl;
  rootCategoryId: any;
  leafCategoryId: any;
  subCategoryId: any;
  brandId: any;
  subBrandId: any;
  beforeDate:FormControl
  constructor(
    public _PS: ProductService,
    public _ALS: AlertService,
    private route:ActivatedRoute,
    private router:Router,
    private _CS:CategoryService) {
    this.subscription = this.keyUp.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      flatMap(search => of(search).pipe(delay(500)))).subscribe(data => {
        this.filter.keyword = data;
        this.page = 0
        if (data.length > 2) {
          this.getSellerProducts()
        }
      });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams=>{
      this.rootCategoryId=queryParams.rootCategoryId ? queryParams.rootCategoryId : ''
      this.subCategoryId=queryParams.subCategoryId ? queryParams.subCategoryId : ''
      this.leafCategoryId=queryParams.leafCategoryId ? queryParams.leafCategoryId : ''
      this.brandId=queryParams.brandId ? queryParams.brandId : ''
      this.subBrandId=queryParams.subBrandId ? queryParams.subBrandId : ''
      this.filter={...queryParams}
      this.getSellerProducts();
    })
    this.productFilter = new FormControl('Filter')
    this.sortFilter('{"sort":"sku","order":"inc"}')
    this.getBrands()
    this.getCategoryTree()
    this.beforeDate=new FormControl()
    this.getSellerProductsa();
  }

  getJsonData(event){
    if(event.target.files.length>0){
      const selectedFile = event.target.files[0]
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (e:any)=>{
        let binaryData = e.target.result;
        let workbook = XLSX.read(binaryData,{type:'binary'});
        workbook.SheetNames.map(sheet=>{
          const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
          console.log(data);
          event.target.value = null;
          this.updateProductByExcel(data);
        })
      }
    } else{
      event.target.value = null;
    }
  }

  updateProductByExcel(data){
    this._PS.updateProductByExcel({products:data}).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message);
        this.sellerProductList = []
        this.page = 0;
        this.limit = 10;
        this.getSellerProducts();
      } else{
        this._ALS.warn(res.message)
      }
    },(err)=>{
      this._ALS.error(err.message)
    })
  }

  downLoadProducts() {
    let data = this.sellerProductLists.map((_) => {
      return {
        name:_.name,
        price:_.price,
        minSellPrice:_.minSellPrice,
        perUserOrderQuantity:_.perUserOrderQuantity,
        quantity:_.quantity,
        sellPrice:_.sellPrice,
        storeMinQuantity:_.storeMinQuantity,
        sellerProductId:_.sellerProductId,
        sku:_.sku,
        barCode:_.barCode,
        recommendedAttribute:_.recommendedAttribute,
        description:_.description,
        images:_.images[0],
      }
    })
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = this.sellerProductLists.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, `SellerProducts.csv`);
  }
  sellerProductLists: any = [];
  getSellerProductsa() {
    this.active = true;
    this.isShowLoader = true
    this._PS.getSellerProductss().subscribe((product: any) => {
      this.sellerProductLists=product.products
    }, (error) => {
    this.isShowLoader = false
    this._ALS.error(error.message)
    });
  }

  getCategoryTree() {
    this._CS.getSellerActiveCatTree().subscribe((cat: any) => {
      this.rootCatList = cat.tree ? cat.tree : [];
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  getSubCategory(catId, value) {
    this.page = 0;
    if (value === 'subCat') {
      this.filter.rootCategoryId = catId;
      this.rootCatList.filter(_ => {
        if (_._id == catId) {
          this.subCatList = _.children;
          console.log(this.subCatList)
        }
      })
    }
    else {
      this.filter.subCategoryId = catId;
      this.subCatList.filter(_ => {
        if (_._id == catId) {
          this.leafCatList = _.children;
        }
      })
    }
  }

  getBrands() {
    this.page = 0
    this._PS.getBrands().subscribe((brand: any) => {
      this.brandList = brand.brands ? brand.brands : []
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  applyDateFilter(){
   if(this.beforeDate.value){
    this.filter.expireBefore=this.beforeDate.value
    this.getSellerProducts()
   }
}

  getSubBrands(brandId: any) {
    this.page = 0
    this.filter.brandId = brandId
    this._PS.getSubBrands(brandId).subscribe((subBrand: any) => {
      this.subBrandList = subBrand.subbrand ? subBrand.subbrand : []
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  sortFilter(sortBy) {
    this.page = 0;
    sortBy = JSON.parse(sortBy);
    this.filter = sortBy;
  }

  setLeafCatId(leafCatId: any) {
    this.page = 0
    this.filter.leafCategoryId = leafCatId;
  }

  setSubBrandId(subBrandId: any) {
    this.page = 0
    this.filter.subBrandId = subBrandId
  }

  removeFilter() {
    this.rootCatList = []
    this.subCatList = []
    this.subBrandList = []
    this.leafCatList = []
    this.brandList = []
    this.subBrandList = []
    this.searchValue = ''
    this.filter = {}
    this.page = 0;
    this.beforeDate.reset()
    this.productFilter.setValue('Filter')
    this.getCategoryTree()
    this.getBrands()
    this.router.navigate(['/availablestock'])
  }

  inActivateProduct(product: any,event:any) {
    let updateStatusData: any = {
      sellerProductId: product.sellerProductId
    }
    if (event.target.checked && product.quantity > 0) {
      updateStatusData.status = true;
      this.activateProduct(updateStatusData)
    }
    else if(product.quantity <=0 && !event.target.checked){
      updateStatusData.status = false;
      this.deactivateProduct(updateStatusData)
    }
    else{
      event.stopPropagation()
      event.preventDefault()
    }
  }

  activateProduct(updateStatusData) {
    this._PS.activeProduct(updateStatusData).subscribe(data => {
      this.sellerProductStatus = data;
      if (this.sellerProductStatus.success) {
        this._ALS.success(this.sellerProductStatus.message);

      } else {
        this._ALS.warn(this.sellerProductStatus.message);
      }
    }, (error) => {
      this._ALS.error(error.message)
    });
  }

  deactivateProduct(updateStatusData) {
    this._PS.deactiveProduct(updateStatusData).subscribe(data => {
      this.sellerProductStatus = data;
      if (this.sellerProductStatus.success) {
        this._ALS.success(this.sellerProductStatus.message);
      }
    }, (error) => {
      this._ALS.error(error.message)
    });
  }


  // for getting  product list
  getSellerProducts() {
    this.active = true;
    this.isShowLoader = true
    this._PS.getSellerProducts(this.filter, this.page, this.limit).subscribe((product: any) => {
      this.isShowLoader = false;
      if (product.success) {
        product.products.map(_=>{
          _.maxDiscount=`${((_.price - _.minSellPrice)*100 / _.price).toFixed(2)}`
          _.margin=`${((_.price - _.purchasePrice)*100 / _.price).toFixed(2)}`
          _.discount=`${((_.price - _.sellPrice)*100 / _.price).toFixed(2)}`
        })
        if (this.page === 0) {
          this.sellerProductList = product.products;
        } else {
          this.sellerProductList = [...this.sellerProductList, ...product.products]
        }
      } else {
        if (this.page === 0) {
          this._ALS.warn(product.message);
          this.sellerProductList = []
        } else {
          this.isActiveDataEnd = true;
        }
      }
    }, (error) => {
      this.isShowLoader = false
      this._ALS.error(error.message)
    });
  }

  setQuantity(sellerProduct, quantity, qtyStatus) {
    if (qtyStatus == 'sellerQty') {
      sellerProduct.quantity = quantity;
    }
    else if (qtyStatus === 'userQty') {
      sellerProduct.perUserOrderQuantity = quantity
    }
    sellerProduct.isUpdate = true;
  }

  setProductState(productState) {
    this.page = 0
    if (productState == "true") {
      this.filter.isActive = true;
    }
    else if (productState == "false") {
      this.filter.isActive = false
    }
    else {
      delete this.filter['isActive']
    }
  }

  updateValues(value, productData, type) {
    let data: any;
    let mfdDate: any;
    let expDate: any;
    switch (type) {
      case 'sku': {
        data = {
          sellerProductId: productData.sellerProductId,
          sku: value ? value.toString() : ''
        };
        this.updateProductValues(data);
        break;
      }
      case 'mfd': {
        mfdDate = moment(value.substr(0, 2) + '/' + value.substr(2, 2) + '/' + value.substr(4, 4), 'DD/MM/YYYY').format();
        if (mfdDate == 'Invalid date') {
          this._ALS.warn('Invalid date, please enter the product manufactured date again');
        } else {
          data = {
            sellerProductId: productData.sellerProductId,
            mfd: mfdDate
          };
          this.updateProductValues(data);
        }
        break;
      }
      case 'exp': {
        expDate = moment(value.substr(0, 2) + '/' + value.substr(2, 2) + '/' + value.substr(4, 4), 'DD/MM/YYYY').format();
        if (expDate == 'Invalid date') {
          this._ALS.warn('Invalid date, please enter the product expiry date again');
        } else {
          if (this.endAfterStart(productData.mfd, expDate)) {
            data = {
              sellerProductId: productData.sellerProductId,
              exp: expDate
            };
            this.updateProductValues(data);
          } else {
            this._ALS.warn('Expiry date should be greater than manufactured date');
          }
        }
        break;
      }
    }
  }

  // update sku, manufacture and expiary date
  updateProductValues(data) {
    this._PS.productStockUpdate(data).subscribe(data => {
      let tempStockStatusData;
      tempStockStatusData = data;
      if (tempStockStatusData.success) {
        this._ALS.success(tempStockStatusData.message);
        if (this.searchValue) {
          // this.searchDebounce(this.searchValue);
        } else {
          if (this.currentTab == 1) {
            this.getSellerProducts();
          }
        }
      } else {
        this._ALS.warn(tempStockStatusData.message);
      }
    });
  }

  // check the date is greater or not
  endAfterStart(start, end) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return endDate.getTime() >= startDate.getTime();
  }

  // for deccrement order - button
  orderDecrement(sellerList) {
    if (sellerList.quantity <= 0) {
      sellerList.quantity = 0;
    } else {
      sellerList.quantity = sellerList.quantity - 1;
      sellerList.isUpdate = true;
    }
  }

  // for increment order + button
  orderIncrement(sellerList) {
    sellerList.quantity = sellerList.quantity + 1;
    sellerList.isUpdate = true;
  }
  setDotd(sellerProduct, time, timeStatus) {
    //console.log(time);
    if (timeStatus == 'from_time') {
      sellerProduct.from_time = time
    }else if (timeStatus === 'to_time') {
      sellerProduct.to_time = time
    }
    sellerProduct.isUpdate = true;
  }

  // for save individual product
  productStockUpdate(product: any) {
    let productUpdateDetail={
      sellerProductId:product.sellerProductId,
      quantity:product.quantity ? product.quantity  : 0,
      perUserOrderQuantity:product.perUserOrderQuantity ? product.perUserOrderQuantity : 0,
      price:product.price ? product.price : 0,
      sellPrice:product.sellPrice ? product.sellPrice : 0,
      minSellPrice:product.minSellPrice ? product.minSellPrice : 0,
      storeMinQuantity: product.storeMinQuantity? product.storeMinQuantity:0,
      purchasePrice:product.purchasePrice ? product.purchasePrice : 0,
      dotd_from_time : product.from_time ? product.from_time : 0,
      dotd_to_time : product.to_time ? product.to_time : 0
    }
    this._PS.productStockUpdate(productUpdateDetail).subscribe(data => {
      this.stockStatusData = data;
      if (this.stockStatusData.success) {
        this._ALS.success(this.stockStatusData.message);
        product.isUpdate = false;
      } else {
        this._ALS.warn(this.stockStatusData.message);
      }
    });
  }

  onScrollDown() {
    this.page += 1;
    this.getSellerProducts();
  }

  updatePrice(product,price){
      product.price=parseInt(price);
      product.discount=`${((product.price - product.sellPrice)*100 / product.price).toFixed(2)}`
      product.maxDiscount=`${((product.price - product.minSellPrice)*100 / product.price).toFixed(2)}`
      product.minSellPrice=Math.ceil(product.price-(product.price * product.maxDiscount /100))
      if(!product.purchasePrice){
        product.purchasePrice= product.price
      }
      product.margin=`${((product.price - product.purchasePrice)*100 / product.price).toFixed(2)}`
      if(price >= product.purchasePrice){
       product.isUpdate=true
    }
    else{
      product.isUpdate=false
    }
  }

  calculateSellPrice(product,value,type){
   if(type =='discount'){
     product.sellPrice=Math.ceil(product.price-(product.price * value /100))
       if( product.sellPrice >=  product.minSellPrice){
       product.isUpdate=true
    }
     else{
      product.isUpdate=false
     }
   }
   else if(type == 'maxDiscount'){
     product.minSellPrice=Math.ceil(product.price-(product.price * value /100))
     if( product.sellPrice >=  product.minSellPrice){
        product.isUpdate=true
     }
     else{
      product.isUpdate=false
     }
  }
  else if(type == 'sellPrice'){
    product.discount=Math.ceil((1 - value / product.price) * 100)
    product.sellPrice=parseInt(value);
    product.isUpdate=true
  }
  else if(type == 'minSellPrice'){
    product.maxDiscount=Math.ceil((1 - value / product.price) * 100)
    product.minSellPrice=parseInt(value);
    product.isUpdate=true
  }
}

calculatePurchasePrice(product,margin){
  product.purchasePrice=Math.ceil(product.price-(product.price*margin/100))
  product.isUpdate=true
}
calculatePurchaseMargin(product,margin){
  product.margin=Math.ceil((1 - margin / product.price) * 100)
  product.purchasePrice=parseInt(margin);
  product.isUpdate=true
}

setMsq(product,msq){
  product.storeMinQuantity=msq
  product.isUpdate=true
}

applyFilter(){
  this.sellerProductList=[]
  this.page=0
  this.router.navigate(['/availablestock'],{queryParams:this.filter})
}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
