import { Component, OnInit,TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService, TransactionService } from 'src/app/_services';
@Component({
  selector: 'app-payouts',
  templateUrl: './payouts.component.html',
  styleUrls: ['./payouts.component.less']
})
export class PayoutsComponent implements OnInit {

  payOuts:any=[]
  settlePaymentForm:FormGroup
  modalRef: BsModalRef;
  payOutId:any;
  constructor(private modalService: BsModalService,
    private _TS:TransactionService,
    private _ALS:AlertService,
    private fb:FormBuilder) {}

  ngOnInit() {
    this.settlePaymentForm=this.fb.group({
      paymentRef:[],
      settledAmount:[],
      paymentDate:[]
    })
    this.getPayOuts() 
  }
 
 opensettleModal(template: TemplateRef<any>,payOutId) {
   this.payOutId=payOutId
    this.modalRef = this.modalService.show(template);
  }

  getPayOuts(){
    this._TS.getPayOuts().subscribe((res:any)=>{
      this.payOuts=res.data ? res.data : []
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  settlePayOut(){
    this._TS.settlePayment(this.payOutId,this.settlePaymentForm.value).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
        this.modalRef.hide()
        this.getPayOuts()
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }
}
