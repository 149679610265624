import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, SimpleChange, OnChanges } from '@angular/core';
import { OrderService, UserService, AlertService, InvoiceService, Invoice2Service } from '../../../_services';
import { TabsetComponent } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NewInvoiceService } from '../../../_services';
import * as QRCode from 'qrcodejs2';
import * as JsBarcode from 'jsbarcode';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.less']
})
export class OrdersComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('focus') public focus: ElementRef<any>;
  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  @ViewChild('searchInput') searchInput: ElementRef;
  pendingOrderData: any;
  allowEmptyString = false;
  qrdata = '';
  barCode: any;
  size = 128;
  usesvg = false;
  qrcode: any;
  invoiceData: any;
  paginationPendingOrderData: any = [];
  confirmedOrderData: any;
  paginationConfirmedOrderData: any;
  dispatchedOrderData: any;
  paginationDispatchedOrderData: any;
  deliveredOrderData: any;
  paginationDeliveredOrderData: any;
  cancelledOrderData: any;
  paginationCancelledOrderData: any;
  returnedOrderData: any;
  paginationReturnedOrderData: any;
  pendingOrderConfirmData: any;
  dispatchedOrderConfirmData: any;
  deliveredOrderConfirmData: any;
  orderModel: any = {};
  model: any = { otp: [] };
  resendOtpData: any = {};
  filter: any = { sort: -1 };
  active: any;
  currentTab: any;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  page = 0;
  limit = environment.pagainationLimit;
  isPendingDataEnd = false;
  isPendingAllEnd = false;
  isConfirmAllEnd = false;
  isConfirmDataEnd = false;
  isDispatchedAllEnd = false;
  isDispatchedDataEnd = false;
  isDeliveredAllEnd = false;
  isDeliveredDataEnd = false;
  isCancelledAllEnd = false;
  isCancelledDataEnd = false;
  isReturnedAllEnd = false;
  isReturnedDataEnd = false;

  constructor(private _OS: OrderService,
    private _IS: InvoiceService,
    private router: Router,
    private _ALS: AlertService,
    private route: ActivatedRoute,
    private _IS2: NewInvoiceService,
    private _newIS2: Invoice2Service,
    private userService: UserService,
    public el: ElementRef) { }

  ngOnInit() {
    this.orderIdFocus();
    this.sortByTime('-1');
    this.pendingOrders(1, 0);
    this.route.queryParams.subscribe(params => {
      if (params.id) {
        this.selectTab(params.id);
      }
    });
  }

  ngAfterViewInit() {
    this.orderIdFocus();
  }

  orderIdFocus() {
    this.focus.nativeElement.focus();
  }

  clearSearchInput() {
    this.searchInput.nativeElement.value = '';
  }

  pendingOrderConfirm(orderId) {
    const pendingOrderDetail: any = {
      orderId: orderId,
      state: 'active',
      status: 'confirmed'
    };
    this._OS.confirmPendingOrder(pendingOrderDetail).subscribe(data => {
      this.pendingOrderConfirmData = data;
      if (this.pendingOrderConfirmData.success) {
        this.pendingOrders(1, 0);
        this._ALS.success(this.pendingOrderConfirmData.message);
      } else {
        this._ALS.warn(this.pendingOrderConfirmData.message);
      }
    });
  }

  dispatchedOrderconfirm(orderId) {
    const dispatchDetail: any = {
      orderId: orderId,
      state: 'active',
      status: 'dispatched',
    };
    this._OS.confirmDispatchedOrder(dispatchDetail).subscribe(data => {
      this.dispatchedOrderConfirmData = data;
      if (this.dispatchedOrderConfirmData.success) {
        this.confirmedOrders(2, 0);
        this._ALS.success(this.dispatchedOrderConfirmData.message);
      } else {
        this._ALS.warn(this.dispatchedOrderConfirmData.message);
      }
    });
  }

  deliveredOrderConfirm(orderId, otp, indexValue) {
    const deliverDetail: any = {
      orderId: orderId,
      state: 'active',
      status: 'delivered',
      otp: otp
    };
    this._OS.confirmDeliveredOrder(deliverDetail).subscribe(data => {
      this.deliveredOrderConfirmData = data;
      if (this.deliveredOrderConfirmData.success) {
        this.dispatchedOrders(3, 0);
        this.model.otp[indexValue] = '';
        this._ALS.success(this.deliveredOrderConfirmData.message);
      } else {
        this._ALS.warn(this.deliveredOrderConfirmData.message);
      }
    });
  }

  // orderInvoice(orderId) {
  //   this._IS.getInvoice(orderId, "order");
  // }

  orderInvoice2(_id) {
    this._IS2.getInvoice(_id);
  }

  orderNewInvoice2(_id) {
    this._newIS2.getInvoice(_id).subscribe(data => {
      this.invoiceData = data;
      if (this.invoiceData.success) {
        if (this.invoiceData && this.invoiceData.invoice && this.invoiceData.invoice.seller &&
          this.invoiceData.invoice.seller.sellerInformation && this.invoiceData.invoice.seller.sellerInformation.name) {
          this.generateQrCode(this.invoiceData.invoice.id);
          this.generateBarCode(this.invoiceData.invoice.id);
        } else {
          this._ALS.warn('Seller Address not found, please fill your invoice address to download invoice');
        }
      }
    });
  }


  selectTab(tabId: number) {
    this.staticTabs.tabs[tabId].active = true;
  }

  sortByTime(value) {
    if (value === '1' || value === '') {
      value = { date: 1 };
    } else {
      value = { date: -1 };
    }
    this.filter = {
      sort: value
    };
    switch (this.currentTab) {
      case 1: {
        this.pendingOrders(1, 0);
        break;
      }
      case 2: {
        this.confirmedOrders(2, 0);
        break;
      }
      case 3: {
        this.dispatchedOrders(3, 0);
        break;
      }
      case 4: {
        this.deliveredOrders(4, 0);
        break;
      }
      case 5: {
        this.cancelledOrders(5, 0);
        break;
      }
      case 6: {
        this.returnedOrders(6, 0);
        break;
      }
    }
  }

  pendingOrders(tabNo, page) {
    this.orderIdFocus();
    if (page === 0) {
      this.page = 0;
    }
    this.currentTab = tabNo;
    const pendingData = {
      status: 'pending',
      state: 'new',
      sort: this.filter.sort
    };
    this._OS.getOrders(pendingData, page, this.limit).subscribe(data => {
      this.pendingOrderData = data;
      if (this.pendingOrderData.success) {
        if (page === 0) {
          this.paginationPendingOrderData = this.pendingOrderData.orders;
        } else {
          this.pendingOrderData.orders.map(_ => {
            this.paginationPendingOrderData.push(_);
          });
        }
      } else if (page === 0) {
        this.isPendingAllEnd = true;
      } else {
        this.isPendingDataEnd = true;
      }
    });
  }

  confirmedOrders(tabNo, page) {
    this.orderIdFocus();
    if (page === 0) {
      this.page = 0;
    }
    this.currentTab = tabNo;
    const confirmData = {
      status: 'confirmed',
      state: 'active',
      sort: this.filter.sort
    };
    this._OS.getOrders(confirmData, page, this.limit).subscribe(data => {
      this.confirmedOrderData = data;
      if (this.confirmedOrderData.success) {
        this.isConfirmAllEnd = this.isConfirmDataEnd = false;
        if (page === 0) {
          this.paginationConfirmedOrderData = this.confirmedOrderData.orders;
        } else {
          this.confirmedOrderData.orders.map(_ => {
            this.paginationConfirmedOrderData.push(_);
          });
        }
      } else {
        if (page === 0) {
          this.isConfirmAllEnd = true;
        } else {
          this.isConfirmDataEnd = true;
        }
      }
    });
  }

  dispatchedOrders(tabNo, page) {
    this.orderIdFocus();
    if (page === 0) {
      this.page = 0;
    }
    this.currentTab = tabNo;
    const dispatchedData = {
      status: 'dispatched',
      state: 'active',
      sort: this.filter.sort,
    };
    this._OS.getOrders(dispatchedData, page, this.limit).subscribe(data => {
      this.dispatchedOrderData = data;
      if (this.dispatchedOrderData.success) {
        this.isDispatchedAllEnd = this.isDispatchedDataEnd = false;
        if (page === 0) {
          this.paginationDispatchedOrderData = this.dispatchedOrderData.orders;
        } else {
          this.dispatchedOrderData.orders.map(_ => {
            this.paginationDispatchedOrderData.push(_);
          });
        }
      } else {
        if (page === 0) {
          this.isDispatchedAllEnd = true;
        } else {
          this.isDispatchedDataEnd = true;
        }
      }
    });
  }

  deliveredOrders(tabNo, page) {
    this.orderIdFocus();
    if (page === 0) {
      this.page = 0;
    }
    this.currentTab = tabNo;
    const deliveredData = {
      status: 'delivered',
      state: 'active',
      sort: this.filter.sort
    };
    this._OS.getOrders(deliveredData, page, this.limit).subscribe(data => {
      this.deliveredOrderData = data;
      if (this.deliveredOrderData.success) {
        this.isDeliveredAllEnd = this.isDeliveredDataEnd = false;
        if (page === 0) {
          this.paginationDeliveredOrderData = this.deliveredOrderData.orders;
        } else {
          this.deliveredOrderData.orders.map(_ => {
            this.paginationDeliveredOrderData.push(_);
          });
        }
      } else {
        if (page === 0) {
          this.isDeliveredAllEnd = true;
        } else {
          this.isDeliveredDataEnd = true;
        }
      }
    });
  }

  cancelledOrders(tabNo, page) {
    this.orderIdFocus();
    if (page === 0) {
      this.page = 0;
    }
    this.currentTab = tabNo;
    const cancelledData = {
      status: 'cancelled',
      state: 'active',
      sort: this.filter.sort
    };
    this._OS.getOrders(cancelledData,page, this.limit).subscribe(data => {
      this.cancelledOrderData = data;
      if (this.cancelledOrderData.success) {
        this.isCancelledAllEnd = this.isCancelledDataEnd = false;
        if (page === 0) {
          this.paginationCancelledOrderData = this.cancelledOrderData.orders;
        } else {
          this.cancelledOrderData.orders.map(_ => {
            this.paginationCancelledOrderData.push(_);
          });
        }
      } else if (page === 0) {
        this.isCancelledAllEnd = true;
      } else {
        this.isCancelledDataEnd = true;
      }
    });
  }

  returnedOrders(tabNo, page) {
    this.orderIdFocus();
    if (page === 0) {
      this.page = 0;
    }
    this.currentTab = tabNo;
    const returnedData = {
      status: 'returned',
      state: 'active',
      sort: this.filter.sort
    };
    this._OS.getOrders(returnedData, page, this.limit).subscribe(data => {
      this.returnedOrderData = data;
      if (this.returnedOrderData.success) {
        this.isReturnedAllEnd = this.isReturnedDataEnd = false;
        if (page === 0) {
          this.paginationReturnedOrderData = this.returnedOrderData.orders;
        } else {
          this.returnedOrderData.orders.map(_ => {
            this.paginationReturnedOrderData.push(_);
          });
        }
      } else if (page === 0) {
        this.isReturnedAllEnd = true;
      } else {
        this.isReturnedDataEnd = true;
      }
    });
  }

  resendOtp(orderId: any) {
    this._OS.reSendOtp(orderId).subscribe(data => {
      this.resendOtpData = data;
      if (this.resendOtpData.success) {
        this._ALS.success(this.resendOtpData.messsage);
      }
    });
  }

  onKeydown(event, otpValue, id, indexValue) {
    if (event.key === 'Enter') {
      if (otpValue && otpValue.length === 4) {
        this.deliveredOrderConfirm(id, otpValue, indexValue);
      } else {
        this._ALS.warn('Please enter valid otp.');
      }
    }
  }

  onScrollDown() {
    this.page += 1;
    switch (this.currentTab) {
      case 1: {
        this.pendingOrders(1, this.page);
        break;
      }
      case 2: {
        this.confirmedOrders(2, this.page);
        break;
      }
      case 3: {
        this.dispatchedOrders(3, this.page);
        break;
      }
      case 4: {
        this.deliveredOrders(4, this.page);
        break;
      }
      case 5: {
        this.cancelledOrders(5, this.page);
        break;
      }
      case 6: {
        this.returnedOrders(6, this.page);
        break;
      }
    }
  }

  cancelAccept(orderId) {
    let res;
    this._OS.accepCancelOrderd(orderId).subscribe(data => {
      res = data;
      if (res.success) {
        this.cancelledOrders(5, 0);
        this._ALS.success(res.message);
      } else {
        this._ALS.error(res.message);
      }
    });
  }

  searchByBarcode(orderId: any) {
    if (orderId) {
      this.router.navigate(['/orders/detail'], { queryParams: { id: orderId } });
    } else {
      this._ALS.warn('Please enter order id');
    }
  }

  generateQrCode(qrString) {
    this.qrdata = qrString;
    const qrcode = new QRCode(document.getElementById('qrcode'), {
      text: this.qrdata,
      width: 128,
      height: 128,
      colorDark: '#000000',
      colorLight: '#ffffff',
      correctLevel: QRCode.CorrectLevel.H
    });
    const canvas = document.getElementById('qrcode').querySelector('canvas');
    if (!this._newIS2.code.qrCode) {
      this._newIS2.code = {
        qrCode: '',
        barCode: ''
      };
    }
    this._newIS2.code.qrCode = canvas.toDataURL();
  }

  public ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    if (!this.qrcode) {
      return;
    }
    const qrData = changes['qrdata'];
    if (qrData && this.isValidQrCodeText(qrData.currentValue)) {
      this.qrcode.clear();
      this.qrcode.makeCode(qrData.currentValue);
    }
  }

  protected isValidQrCodeText = (data: string) => {
    if (this.allowEmptyString === false) {
      return !(typeof data === 'undefined' || data === '');
    }
    return !(typeof data === 'undefined');
  }

  generateBarCode(codeString) {
    const newCanvas = document.createElement('canvas');
    this.barCode = new JsBarcode(newCanvas, codeString, {
      format: 'CODE128'
    });
    this._newIS2.code.barCode = newCanvas.toDataURL('image/png');
    // this._IS2.printPickUpList(this.invoiceData);
    this.invoiceData.invoice.products.sort((product1,product2)=>{
      return product1.mbSku-product2.mbSku;
    })
    if(this.invoiceData.invoice.outOfStockProducts.length>0){
      this.invoiceData.invoice.products.map(_=>{
        this.invoiceData.invoice.outOfStockProducts.map(outOfStock=>{
          if(_.productId == outOfStock.productId){
            _.quantity-=outOfStock.quantity;
          }
        })
      })
    }
    if(this.invoiceData.invoice.returnProducts.length>0){
      this.invoiceData.invoice.products.map(_=>{
        this.invoiceData.invoice.returnProducts.map(returnProduct=>{
          if(_.productId == returnProduct.productId){
            _.quantity-=returnProduct.quantity;
          }
        })
      })
    }
    this._newIS2.printInvoice(this.invoiceData);
  }

}
