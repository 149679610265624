import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.less']
})
export class SubCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
