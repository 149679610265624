import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService, AlertService } from '../../../_services/index';
@Component({
  selector: 'app-returnorderdetail',
  templateUrl: './returnorderdetail.component.html',
  styleUrls: ['./returnorderdetail.component.less']
})
export class ReturnorderdetailComponent implements OnInit, AfterViewInit {
  @ViewChild('focus') public focus: ElementRef<any>;
  orderDetailModel: any;
  orderDetail: any = {};
  productLength: number;
  orderAddress: any = {};
  orderProducts: any = [];
  isAcceptCancelButton = false;
  isShowBarcode = false;
  orderProductLength = 0;
  orderProductTrue: any = [];
  quantity = 0;
  scanProduct: any = [];
  barCodeCheck: any = [];
  isProdctCountMax = false;
  order: any;
  queryParams: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _ALS: AlertService,
    private _OS: OrderService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params;
      if (this.queryParams.id) {
        this.getOrderDetail(this.queryParams);
      }
    });
  }

  ngAfterViewInit() {
    // this.focus.nativeElement.focus();
  }

  getOrderDetail(orderData) {
    let orderDetail: any;
    this._OS.cancelOrderDetail(orderData.id).subscribe(data => {
      orderDetail = data;
      if (orderDetail.success) {
        this.orderDetail = orderDetail;
        this.orderAddress = this.orderDetail.order;
        if (this.orderAddress.status === 'cancelinitiate') {
          this.isShowBarcode = true;
          this.isAcceptCancelButton = true;
          setTimeout(() => {
            this.focus.nativeElement.focus();
          }, 100);
        }
        this.productLength = this.orderDetail.order.length;
        this.orderProducts = this.orderAddress.products;
        this.orderProductLength = this.orderProducts.length;
        this.orderProducts = this.orderProducts.map(_ => {
          _.isReturn = false;
          _.isProdctCountMax = false;
          _.isProductNotAvailable = false;
          _.scanProductQuantity = 0;
          return _;
        });
      }
    });
  }

  confirmProduct(ProductBarCode: any) {
    let isFindBarcode = false;
    this.orderProducts.every(_ => {
      if (_.barCode === ProductBarCode) {
        isFindBarcode = true;
        return false;
      } else {
        return true;
      }
    });
    if (isFindBarcode && this.scanProduct.length > 0) {
      this.scanProduct.every(_ => {
        if (ProductBarCode === _.barCode) {
          _.quantity++;
          return false;
        } else {
          return true;
        }
      });
      if (!isFindBarcode) {
        this.scanProduct.push({
          barCode: ProductBarCode,
          quantity: 1
        });
      } else {
        const found = this.scanProduct.find((_) => {
          return ProductBarCode === _.barCode;
        });
        if (!found) {
          this.scanProduct.push({
            barCode: ProductBarCode,
            quantity: 1,
          });
        }
      }
    } else if (isFindBarcode) {
      this.scanProduct.push({
        barCode: ProductBarCode,
        quantity: 1
      });
    } else {
      this._ALS.error('Product do not match with order list.');
    }
    this.orderProducts.every(_ => {
      this.scanProduct.every(__ => {
        if (_.barCode === __.barCode && _.quantity === __.quantity) {
          _.isReturn = true;
          _.scanProductQuantity = __.quantity;
          return false;
        }
        if (_.barCode === __.barCode && __.quantity < _.quantity) {
          _.scanProductQuantity = __.quantity;
        } else {
          if (_.barCode === __.barCode && __.quantity > _.quantity) {
            _.isProdctCountMax = true;
            _.scanProductQuantity = __.quantity;
            this._ALS.warn('Product quantity is greater than order quantity');
          }
          _.isReturn = false;
          return true;
        }
      });
      return true;
    });
    this.orderDetailModel = '';
    this.returnOrderAccept();
  }

  decreaseProductQuatnity(ProductBarCode) {
    this.orderProducts.map((_) => {
      if (ProductBarCode === _.barCode) {
        this.scanProduct.map((__) => {
          if (__.quantity > _.quantity && ProductBarCode === __.barCode) {
            __.quantity -= 1;
            _.scanProductQuantity = __.quantity;

            if (__.quantity === _.quantity) {
              _.isProdctCountMax = false;
              _.isReturn = true;
            }
          }
        });
      }
    });
    this.returnOrderAccept();
  }

  cancelAccept() {
    let res;
    this._OS.accepCancelOrderd(this.orderAddress._id).subscribe(data => {
      res = data;
      if (res.success) {
        this.router.navigate(['/orders'], { queryParams: { id: 4 } });
        this._ALS.success(res.message);
      } else {
        this._ALS.error(res.message);
      }
    });
  }

  returnOrderAccept() {
    this.orderProductTrue = this.orderProducts.filter(_ => {
      if (_.isReturn === true) {
        return _;
      }
    });
    if (this.orderProductLength === this.orderProductTrue.length) {
      this.isAcceptCancelButton = true;
    } else {
      this.isAcceptCancelButton = false;
    }
  }
}
