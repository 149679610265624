import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutofocusDirective } from './_directives';
import { DataTablesModule } from 'angular-datatables';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TagInputModule } from 'ngx-chips';
import { TreeModule } from 'angular-tree-component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MDBBootstrapModule, InputsModule, WavesModule, ButtonsModule, IconsModule } from 'angular-bootstrap-md';
import { NgxMaskModule } from 'ngx-mask';
import { AuthGuard, CheckSellerdetailGuard, CheckSellerVerifyGuard } from './_guards';
import { HeaderComponent, FooterComponent } from './template';
import { AccordionModule, TabsModule, TypeaheadModule, TooltipModule } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { JwtInterceptor, TokenInterceptor } from './_interceptor';
import { AlertComponent } from './_directives/alert.component';
import { HomethemeComponent, LoginthemeComponent, VerifyemailComponent } from './themes/index';
import { SearchPipe, ImagePipe, TransactionPipe, ImageSizePipe} from './_pipe/index';
import { TimeAgoPipe } from 'time-ago-pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccountInfoComponent } from './starter/index.starter';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { TimepickerModule } from 'ngx-bootstrap';
import {
  ComponentsComponent, UserComponent, LoginComponent,
  SignupComponent, HomeComponent, OrdersComponent,OrderV2Component,
  ForgotComponent, ImageComponent, StepperComponent,
  EmailverificationComponent, SellerInfoComponent,
  OrderDetailComponent, NotificationComponent,
  AddAreaComponent, SearchProductComponent,
  AvailablestockComponent, TransactionComponent,
  SellerVerifyComponent,
  TermsComponent, VendorAgreementComponent,
  FaqComponent, ReturnorderdetailComponent,
  InlineEditComponent, PaginationComponent,
  BuyerListComponent, RegisterBuyerComponent,
  PurchaseRequestComponent, PurchaseOrderComponent,
  PurchaseOrderDetailComponent, GoodsReceiveListComponent,
  GoodsReceiveDetailsComponent, GoodsReturnDetailsComponent,
  GoodsReturnListComponent,DeliveryBoyListComponent,TableComponent,
  OrderDetailV2Component,BagComponent,BuyerPurchaserequestDetailComponent,BuyerQuoteHistoryDetailComponent,PuschaseHistoryDetailComponent,GoodsReceiveHistoryDetailComponent,StockReportComponent,RouteComponent,AddNewRouteComponent,SlotComponent,AddNewSlotComponent,RouteReportComponent,DamageStockComponent,DamageStockDetailComponent,AuditReportComponent,AddNewProductComponent,AuditHistoryDetailComponent,BrandComponent,SubBrandComponent,PayoutsComponent,AddNewPromoCodeComponent,OrderSizeComponent,PromoCodeComponent,CategoryComponent,SubCategoryComponent,LeafCategoryComponent,SellerPayoutComponent,ViewProductComponent,NewProductViewComponent,PosComponent,CustomerComponent
} from './components';
import { StockSlotReportComponent } from './stock-slot-report/stock-slot-report.component';
import { GraphsComponent } from './components/graphs/graphs.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BannerComponent } from './components/banner/banner.component';
import { AddBannerComponent } from './components/banner/add-banner/add-banner.component';
import { SetPasswordComponent } from './components/user/set-password/set-password.component';
import { PhoneVerifyComponent } from './components/user/phone-verify/phone-verify.component';
import { CreditorsComponent } from './components/pos/creditors/creditors.component';
import { CreditorInfoComponent } from './components/pos/creditors/creditor-info/creditor-info.component';
import { SlotNewComponent } from './components/slot-new/slot-new.component';
import { AddSlotComponent } from './components/slot-new/add-slot/add-slot.component';
import { BulkOrderComponent } from './components/bulk-order/bulk-order.component';
import { DeliveryBoyReportComponent } from './components/delivery-management/delivery-boy-report/delivery-boy-report.component';
import { DeliveryBoyNamePipe } from './_pipe/delivery-boy-name.pipe';






@NgModule({
  declarations: [
    AppComponent,
    ComponentsComponent,
    UserComponent,
    LoginComponent,
    SignupComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    OrdersComponent,
    SellerInfoComponent,
    AlertComponent,
    OrderDetailComponent,
    ForgotComponent,
    EmailverificationComponent,
    HomethemeComponent,
    LoginthemeComponent,
    AddAreaComponent,
    SearchProductComponent,
    SearchPipe,
    ImagePipe,
    AvailablestockComponent,
    TransactionComponent,
    TransactionPipe,
    VerifyemailComponent,
    TimeAgoPipe,
    AccountInfoComponent,
    ImageComponent,
    StepperComponent,
    SellerVerifyComponent,
    TermsComponent,
    VendorAgreementComponent,
    PaginationComponent,
    FaqComponent,
    AutofocusDirective,
    ReturnorderdetailComponent,
    NotificationComponent,
    InlineEditComponent,
    BuyerListComponent,
    RegisterBuyerComponent,
    PurchaseRequestComponent,
    PurchaseOrderComponent,
    PurchaseOrderDetailComponent,
    GoodsReceiveListComponent,
    GoodsReceiveDetailsComponent,
    GoodsReturnListComponent,
    GoodsReturnDetailsComponent,
    DeliveryBoyListComponent,
    TableComponent,
    OrderV2Component,
    OrderDetailV2Component,
    BagComponent,
    BuyerPurchaserequestDetailComponent,
    BuyerQuoteHistoryDetailComponent,
    BuyerQuoteHistoryDetailComponent,
    PuschaseHistoryDetailComponent,
    GoodsReceiveHistoryDetailComponent,
    ImageSizePipe,
    StockReportComponent,
    StockSlotReportComponent,
    RouteComponent,
    AddNewRouteComponent,
    SlotComponent,
    AddNewSlotComponent,
    RouteReportComponent,
    DamageStockComponent,
    DamageStockDetailComponent,
    GraphsComponent,
    AuditReportComponent,
    AddNewProductComponent,
    AuditHistoryDetailComponent,
    BrandComponent,
    SubBrandComponent,
    PayoutsComponent,
    BannerComponent,
    AddBannerComponent,
    PromoCodeComponent,
    OrderSizeComponent,
    AddNewPromoCodeComponent,
    SetPasswordComponent,
    PhoneVerifyComponent,
    CategoryComponent,
    SubCategoryComponent,
    LeafCategoryComponent,
    SellerPayoutComponent,
    SlotNewComponent,
    AddSlotComponent,
    ViewProductComponent,
    NewProductViewComponent,
    PosComponent,
    CustomerComponent,
    CreditorsComponent,
    CreditorInfoComponent,
    BulkOrderComponent,
    DeliveryBoyReportComponent,
    DeliveryBoyNamePipe,
  ],
  imports: [
    BrowserModule,
    DataTablesModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NgxChartsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MDBBootstrapModule.forRoot(),
    InputsModule,
    WavesModule,
    ButtonsModule,
    IconsModule,
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    TreeModule.forRoot(),
    TooltipModule,
    NgxMaskModule.forRoot(),
    CKEditorModule,
    NgbModule,
    TagInputModule,
    InfiniteScrollModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    CKEditorModule 
  ],
  providers: [
    CheckSellerdetailGuard,
    AuthGuard,
    CheckSellerVerifyGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
