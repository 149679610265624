import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../_services/index';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.less']
})
export class ForgotComponent implements OnInit {

  email:FormControl

  constructor(private _US: UserService,
    private router: Router,
    private _AS: AlertService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.email=new FormControl('',Validators.required)
  }

  forgotPassword(){
   if(this.email.valid){
    this._US.forgotPassword({email:this.email.value}).subscribe((res:any)=>{
      if(res.success){
        this._AS.success(res.message)
      }
      else{
        this._AS.warn(res.message)
      }
    },(error)=>{
      this._AS.error(error.message)
    })
   }
   else{
     this.email.markAsTouched()
   }
  }
}
