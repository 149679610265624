import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, flatMap, delay } from 'rxjs/operators';
import { UserService, AlertService } from 'src/app/_services';
@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.less']
})
export class CustomerComponent implements OnInit {
  modalRef: BsModalRef;
  debounceSubscription: Subscription
  keyUp = new Subject<string>();
  constructor(private modalService: BsModalService, private _US: UserService, private _ALS: AlertService) { }
  users: any = [];
  ngOnInit() {
    this.debounceSubscription = this.keyUp.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      flatMap(search => of(search).pipe(delay(500)))).subscribe(value => {
        this.searchUser(value)
      });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  searchUser(value) {
    this._US.getUser(value).subscribe((data: any) => {
      this.users = data.users ? data.users : [];
    }, (error) => {
      this._ALS.error(error.message);
    })
  }

}
