import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http:HttpClient) { }

  getCategoryTree(){
    return this.http.get(environment.apiURL+`category`)
  }

  inActivateCat(id){
    return this.http.put(environment.apiURL+`category`,{categoryId:id})
  }

  deleteCat(id){
    return this.http.request('DELETE',environment.apiURL+'category',{body: { categoryId:id }})
  }

  getSellerActiveCatTree(){
    return this.http.get(environment.apiURL+`active/category`)
  }
} 
