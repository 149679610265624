import { Component, OnInit } from '@angular/core';
import { AlertService, UserService } from '../../_services/index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-seller-verify',
  templateUrl: './seller-verify.component.html',
  styleUrls: ['./seller-verify.component.less']
})
export class SellerVerifyComponent implements OnInit {

  constructor(
    private _AS: AlertService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  profileVerify() {
    let responseData: any;
    this.userService.isSellerVerify().subscribe(response => {
      responseData = response;
      if (responseData.success) {
        this.router.navigate(['']);
      } else {
        this._AS.warn(responseData.message);
      }
    },
      error => {
        this._AS.error(error.message);
      }
    );
  }
}
