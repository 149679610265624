import { Component, OnInit } from '@angular/core';
import { RouteService } from 'src/app/_services/route.service';
import { SlotService } from 'src/app/_services/slot.service';
import { AlertService } from 'src/app/_services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import jsPDF from 'jspdf'
import 'jspdf-autotable'

@Component({
  selector: 'app-route-report',
  templateUrl: './route-report.component.html',
  styleUrls: ['./route-report.component.less']
})
export class RouteReportComponent implements OnInit {

  slotList:any=[]
  routeList: any=[];
  orderList:any=[];
  isShowProgressBar:boolean=false
  routeReportForm:FormGroup
  filter:any={}
  constructor(private fb:FormBuilder,
    private _SS:SlotService,
    private _RS:RouteService,
    private _ALS:AlertService) { }

  ngOnInit() {
    this.routeReportForm=this.fb.group({
      date:[new Date(),Validators.required],
      slot:['Select Slot'],
      area:['Select Area'],
      order:['Select Order']
    })
    this.getRoutes();
    this.getSlots()
  }

  getRoutes(){
    this._RS.getRoutes().subscribe((res:any)=>{
      this.routeList=res.sellerRoutes ? res.sellerRoutes : []
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  getSlots(){
    this._SS.getTimeSlot().subscribe((res:any)=>{
      this.slotList=res.timeSlot ? res.timeSlot : []
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  setFilter(type:any){
    if(type=="date"){
      this.filter.date=this.routeReportForm.value["date"]
    }
    else if(type=="order" && this.routeReportForm.value["order"] != 'Select Order'){
      this.filter.status=this.routeReportForm.value["order"]
    }
    else if(type=="slot" && this.routeReportForm.value["slot"] != 'Select Slot'){
      this.filter.slot=this.routeReportForm.value["slot"]
    }
    else if(type=="area" && this.routeReportForm.value["area"] != 'Select Area'){
        this.filter.group=this.routeReportForm.value["area"].group
        this.filter.name=this.routeReportForm.value["area"].name
    }
  }

  generateRouteReport(){
    let date=new Date(this.routeReportForm.value["date"])
    this.filter.date=date.getFullYear()+'/'+(date.getMonth()+1)+'/'+date.getDate()
    if(this.routeReportForm.valid){
      this.isShowProgressBar=true
      this._RS.generateRouteReport(this.filter).subscribe((res:any)=>{
      this.isShowProgressBar=false
      if(res.success){
        this.orderList=res.orders
      }
      else{
        this._ALS.warn(res.message)
        this.orderList=[]
      }
    },(error)=>{
      this.isShowProgressBar=false
      this._ALS.error(error.message)
    })
  }
    else{
      this.routeReportForm.get("date").markAsTouched()
    }
  }

  removeFilter(){
    this.routeReportForm.get("slot").setValue("Select Slot")
    this.routeReportForm.get("area").setValue("Select Area")
    this.routeReportForm.get("order").setValue("Select Order")
    this.routeReportForm.get("date").reset();
    this.filter={}
  }

  downLoadReport(){
    let date=new Date(this.filter.date)
    const doc = new jsPDF()
    doc.text(75,15,`Date:- ${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`);
    doc.autoTable({html: '#report-table',
    theme:'grid',
    startY:20,
    headStyles:{ cellWidth:'wrap'},
    margin: {left:10,right:10},
   })
   doc.save('route-report.pdf')
    // this._RS.downLoadRouteReport(this.orderList,this.filter);
  }
  }
