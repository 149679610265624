import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoodsService, AlertService } from 'src/app/_services';

@Component({
  selector: 'app-goods-receive-history-detail',
  templateUrl: './goods-receive-history-detail.component.html',
  styleUrls: ['./goods-receive-history-detail.component.less']
})
export class GoodsReceiveHistoryDetailComponent implements OnInit {

  grnDetail:any={};

  constructor(private route :ActivatedRoute,
    private _GS:GoodsService,
    private _AlS:AlertService) { }

  ngOnInit() {
    this.route.params.subscribe(params=>{
      let grnId=params.grnId;
      this.getGrnDetail(grnId)
    })
  }

  getGrnDetail(grnId:any){
    this._GS.getgoodsRecieveDetail(grnId).subscribe((grnDetail:any)=>{
      if(grnDetail.success){
        this.grnDetail=grnDetail.grnDetails;
      }
      else{
        this._AlS.warn(grnDetail.message)
      }
    },(error)=>{
      this._AlS.error(error.message);
    })
  }
}
