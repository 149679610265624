import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SlotService } from 'src/app/_services/slot.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/_services';

@Component({
  selector: 'app-add-new-slot',
  templateUrl: './add-new-slot.component.html',
  styleUrls: ['./add-new-slot.component.less']
})
export class AddNewSlotComponent implements OnInit {

  addSlotForm: FormGroup
  slotId: any;
  map: any;
  polygonPath: any = []

  constructor(private fb: FormBuilder,
    private _SS: SlotService,
    private route: ActivatedRoute,
    private router: Router,
    private _ALS: AlertService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(param => {
      this.slotId = param.slotId;
      if (this.slotId) {
        this.getSlotDetail();
      }
    })
    this.addSlotForm = this.fb.group({
      start: [],
      end: [],
      clossing: [],
      priority: [],
      orderCount: []
    })
  }

  createSlotPayload() {
    let sHours = this.addSlotForm.value['start'].getHours()
    let eHours = this.addSlotForm.value['end'].getHours()
    let startHours = this.changeDigit((this.addSlotForm.value['start'].getHours()) % 12 || 12)
    let startMinutes = this.changeDigit(this.addSlotForm.value['start'].getMinutes())
    let endMinutes = this.changeDigit(this.addSlotForm.value['end'].getMinutes())
    let endHours = this.changeDigit((this.addSlotForm.value['end'].getHours()) % 12 || 12)
    let startSlot = sHours >= 12 ? `${startHours}:${startMinutes}PM` : `${startHours}:${startMinutes}AM`
    let endSlot = eHours >= 12 ? `${endHours}:${endMinutes}PM` : `${endHours}:${endMinutes}AM`
    let finalSlot = `${startSlot} - ${endSlot}`;
    let slotData = {
      slot: finalSlot,
      value: {
        hr: sHours,
        min: startMinutes,
        minutes: (this.addSlotForm.value['clossing'].getHours() * 60) + (this.addSlotForm.value['clossing'].getMinutes())
      },
      priority: this.addSlotForm.value['priority'],
      orderCount: this.addSlotForm.value['orderCount']
    }
    if (this.slotId) {
      this.updateSlot(slotData)
    }
    else {
      this.addSlot(slotData)
    }
  }
  addSlot(slotData) {
    this._SS.addSlot({ timeSlot: slotData }).subscribe((res: any) => {
      if (res.success) {
        this.router.navigate(['/slot'])
        this._ALS.success(res.message)
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  updateSlot(slotData) {
    this._SS.updateSlot(this.slotId, { timeSlot: slotData }).subscribe((res: any) => {
      if (res.success) {
        this.router.navigate(['/slot'])
        this._ALS.success(res.message)
      }
      else {
        this._ALS.warn(res.message)
      }
    }, (error) => {
      this._ALS.error(error.message)
    })
  }

  getSlotDetail() {
    this._SS.getSlotDetail(this.slotId).subscribe((res: any) => {
      if (res.success) {
        let endDate = new Date()
        if (res.timeSlot.slot.split('-')[1].split(':')[1].substr(2, 3) == 'PM') {
          if (parseInt(res.timeSlot.slot.split('-')[1].split(':')[0]) == 12) {
            endDate.setHours(12)
          }
          else {
            endDate.setHours(parseInt(res.timeSlot.slot.split('-')[1].split(':')[0]) + 12)
          }
        }
        else {
          if (res.timeSlot.slot.split('-')[1].split(':')[0] == 12) {
            endDate.setHours(parseInt(res.timeSlot.slot.split('-')[1].split(':')[0]) + 12)
          }
          else {
            endDate.setHours(res.timeSlot.slot.split('-')[1].split(':')[0])
          }
        }
        endDate.setMinutes(res.timeSlot.slot.split('-')[1].split(':')[1].substring(0, 2))
        let startdate = new Date()
        startdate.setHours(res.timeSlot.value.hr)
        startdate.setMinutes(res.timeSlot.value.min)
        let closingDate = new Date()
        closingDate.setHours(res.timeSlot.value.minutes / 60)
        closingDate.setMinutes(res.timeSlot.value.minutes % 60)
        this.addSlotForm.patchValue({
          start: startdate,
          end: endDate,
          priority: res.timeSlot.priority,
          clossing: closingDate,
          orderCount: res.timeSlot.orderCount
        })
      }
    })
  }

  changeDigit(time) {
    return (time < 10 ? '0' : '') + time
  }


  // initializeDrawingTool() {
  //   const drawingManager = new google.maps.drawing.DrawingManager({
  //     drawingMode: google.maps.drawing.OverlayType.MARKER,
  //     drawingControl: true,
  //     drawingControlOptions: {
  //       position: google.maps.ControlPosition.TOP_CENTER,
  //       drawingModes: [google.maps.drawing.OverlayType.POLYGON],
  //     },
  //     markerOptions: {
  //       icon:
  //         "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
  //     },
  //   });
  //   let polygoncompleteFunc = (polygon: any) => {
  //     let polygonPath = polygon.getPath().getArray().map((_, i) => {
  //       return [_.lng(), _.lat()]
  //     });
  //     this.polygonPath.push([polygonPath])
  //     console.log(this.polygonPath)
  //   }
  //   google.maps.event.addListener(drawingManager, 'polygoncomplete', polygoncompleteFunc.bind(this));
  //   drawingManager.setMap(this.map);
  // }

}

