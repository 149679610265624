import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService, AlertService } from 'src/app/_services';
import { FormControl } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-order-detail-v2',
  templateUrl: './order-detail-v2.component.html',
  styleUrls: ['./order-detail-v2.component.less']
})
export class OrderDetailV2Component implements OnInit {
  barCode:any;
  order:any={};
  isShowDispatch:any=false;
  modalRef: BsModalRef;
  product:any={};
  orderId:any='';
  outOfStockQuantity:any;
  outOfStockProducts:any={};
  transitProducts:any={}

  constructor(
    private route:ActivatedRoute,
    private router: Router,
    private _OS: OrderService,
    private _ALS: AlertService,
    private modalService: BsModalService
  ) {
    this.barCode = new FormControl('');
    this.outOfStockQuantity = new FormControl('');
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.orderId=params.id;
      if (this.orderId) {
        this.getOrderDetail(this.orderId);
      }
    })
  }

  getOrderDetail(orderId){
    this._OS.orderDetail(orderId).subscribe((orderResponse:any)=>{
      if(orderResponse.success){
        this.order = orderResponse.order;
        if(this.order.inTransitProducts.length>0){
          this.order.inTransitProducts.map(_=>{
            this.transitProducts[_.barCode]=_;
            this.transitProducts[_.barCode].scanQty=0
          })
        }
        if(this.order.isOutOfStock){
          this.order.outOfStockProducts.map(_=>{
            this.outOfStockProducts[_._id]=_;
          })
        }
        this.isShowDispatch = true;
        this.order.products.forEach(_=>{
          let outOfStockQuantity = this.outOfStockProducts[_._id]? this.outOfStockProducts[_._id].quantity:0;
          if(!_.scanProductQuantity){
            _.scanProductQuantity = outOfStockQuantity==0 ? 0 : outOfStockQuantity;
          }
          if(_.quantity!=_.scanProductQuantity){
            this.isShowDispatch=false;
          }
        });
      }else{
        console.log(orderResponse.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  confirmProduct(){
    if(this.order.inTransitProducts.length>0){
      this.scanBarcode()
    }
    else{
      this.isShowDispatch = true;
      this.order.products.forEach(_=>{
        if(_.barCode == this.barCode.value && _.quantity>_.scanProductQuantity ){
          _.scanProductQuantity+=1;
        }
        if(_.quantity!=_.scanProductQuantity){
          this.isShowDispatch=false;
        }
      });
      this.barCode.setValue('');
    }
  }

  dispatchedOrderconfirm () {
    let dispatchDetail = {
      orderId: this.order._id,
      state: 'active',
      status: 'dispatched'
    }
    this._OS.confirmDispatchedOrder(dispatchDetail).subscribe((orderResponse:any) => {
      if (orderResponse.success) {
        this.router.navigate(['/orders-v2'], { queryParams: { id: 1 } })
        this._ALS.success(orderResponse.message)
      } else {
        this._ALS.warn(orderResponse.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  cancelledOrder(order){
    if(confirm("Are you sure to Cancle Order # "+order.id)) {
      const pendingOrderDetail: any = {
        orderId: order._id,
        state: 'refund',
        status: 'cancelled'
      };
      this._OS.canceledOrder(pendingOrderDetail).subscribe((orderResponse:any) => {
        if (orderResponse.success) {
          this._ALS.success(orderResponse.message);
          this.getOrderDetail(this.orderId);
        } else {
          this._ALS.warn(orderResponse.message);
        }
      });
    }
  }
  pendingOrdersUpa(order){
    if(confirm("Are you sure to ReStore Order # "+order.id)) {
        const pendingOrderDetail: any = {
          orderId: order._id,
          state: 'new',
          status: 'pending'
        };
        this._OS.restoreOrder(pendingOrderDetail).subscribe((orderResponse:any) => {
          if (orderResponse.success) {
            this._ALS.success(orderResponse.message);
            this.getOrderDetail(this.orderId);
          } else {
            this._ALS.warn(orderResponse.message);
          }
        });
      }
  }
  openOutOfStockModal(outofstock: TemplateRef<any>,product) {
    this.product = product;
    this.barCode.setValue('');
    this.modalRef = this.modalService.show(outofstock,Object.assign({}, { class: 'modal-lg' }));
  }

  createOutOfStock(){
    let data={
      productId:this.product._id,
      orderId:this.order._id,
      quantity:this.outOfStockQuantity.value,
    }
    if(this.barCode.value!=this.product.barCode){
      this._ALS.error("bar code mismatch");
    }else if(this.outOfStockQuantity.value>this.product.quantity-(this.product.scanProductQuantity ?this.product.scanProductQuantity:0)){
      this._ALS.error("Excess quantity is entered");
    }else{
      this._OS.outOfStocks(data).subscribe((response:any)=>{
        if(response.success){
          this.getOrderDetail(this.orderId);
        }else{
          this._ALS.warn(response.message);
        }
      },(error)=>{
        this._ALS.error(error.message)
      })
    }
  }

  productReturn(product){
    this._OS.returnIntransitProduct({productId:product._id,orderId:this.order._id}).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
      }
      else{
        this._ALS.warn(res.message)
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  scanBarcode(){
    if(this.transitProducts[this.barCode.value]!=null){
      if(this.transitProducts[this.barCode.value].scanQty < this.transitProducts[this.barCode.value].quantity){
        this.transitProducts[this.barCode.value].scanQty+=1;
        this.barCode.reset()
        console.log(this.transitProducts)
      }
    }
  }
}
