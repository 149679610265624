import { Component, OnInit } from '@angular/core';
import { UserService, AlertService } from '../../../_services/index';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.less']
})
export class SignupComponent implements OnInit {
  model: any = {};
  registerForm:FormGroup
  signupResponse: any;
  showOtpModel = false;
  otpVerifyData: any;
  resendOtpData: any;
  constructor(private _US: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private _AS: AlertService,
    private fb:FormBuilder) { }

  ngOnInit() {
    this.registerForm=this.fb.group({
      email:['',Validators.required],
      phoneNo:[]
    })
    this.route.queryParams.subscribe(params => {
      this.model.userId = params.userId;
      this.showOtpModel = params.getOtp;
    });
  }

  registerSeller(){
   if(this.registerForm.valid){
    this._US.register(this.registerForm.value).subscribe((res:any)=>{
      if(res.success){
        this._AS.success(res.message)
      }
      else{
        this._AS.warn(res.message)
        this.router.navigate(['/login'])
      }
    },(error)=>{
      this._AS.error(error.message)
    })
   }
   else{
     this.registerForm.controls["email"].markAsTouched()
   }
  }
}
