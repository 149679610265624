import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, UserService } from 'src/app/_services';

@Component({
  selector: 'app-phone-verify',
  templateUrl: './phone-verify.component.html',
  styleUrls: ['./phone-verify.component.less']
})
export class PhoneVerifyComponent implements OnInit {

  mobile:FormControl;
  otp:FormControl;
  isShowMobile:boolean=true

  constructor(private _US:UserService,
    private _ALS:AlertService,
    private router:Router) { }

  ngOnInit() {
    this.mobile=new FormControl('',Validators.required)
    this.otp=new FormControl('',Validators.required)
  }

  requestOtp(){
    this._US.reqOtp({phoneNo:this.mobile.value,email:this._US.user.data.email}).subscribe((res:any)=>{
      if(res.success){
        this._ALS.success(res.message)
        this.isShowMobile=false
      }
      else{
        this._ALS.warn(res.message)  
      }
    },(error)=>{
      this._ALS.error(error.message)
    })
  }

  verifyOtp(){
    this._US.verifyOtp({phoneNo:this.mobile.value,otp:this.otp.value}).subscribe((res:any)=>{
      if(res.success){
        this.router.navigate(['/login'])
        this._ALS.success(res.message)
      }
      else{
        this._ALS.warn(res.message)
      }
    })
  }
}
